import { Button, Grid, MenuItem, TextField } from "@material-ui/core";
import PrintIcon from "@material-ui/icons/Print";
import React from "react";
import ButtonFormato from "../../../../../components/ait-reusable/Button/ButtonFormato";

export default function Options({
  etiquetasize,
  setEtiquetaSize,
  formatoHoja,
  setFormatoHoja,
  formato_etiqueta,
  handleGetPDF,
  disabled,
}) {
  return (
    <Grid container spacing={2} className="d-flex my-3" alignItems="center">
      <Grid item xs={12} sm={3}>
        <TextField
          padding={0}
          select
          fullWidth
          size={"small"}
          value={etiquetasize}
          variant="outlined"
          label="Tamaño de etiquetas"
          onChange={(e) => setEtiquetaSize(e.target.value)}
          SelectProps={{
            MenuProps: {
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            },
          }}
        >
          {formato_etiqueta &&
            formato_etiqueta
              .filter((item) => item.es_visible)
              .map((item, index) => (
                <MenuItem key={index} value={index}>
                  {`${item.ancho}mm x ${item.alto}mm`}
                </MenuItem>
              ))}
        </TextField>
      </Grid>

      <Grid
        container
        item
        xs={12}
        sm={8}
        spacing={2}
        style={{ justifyContent: "flex-start", alignItems: "center" }}
      >
        <Grid item>
          <ButtonFormato
            tipo="Ticket"
            message="Ticket"
            icon="receipt"
            click={() => setFormatoHoja("Ticket")}
            format={formatoHoja}
          />
        </Grid>
        <Grid item>
          <ButtonFormato
            tipo="A4"
            message="A4"
            icon="print"
            click={() => setFormatoHoja("A4")}
            format={formatoHoja}
          />
        </Grid>
        <Grid item>
          <ButtonFormato
            tipo="Carta"
            message="Carta"
            icon="print"
            click={() => setFormatoHoja("Carta")}
            format={formatoHoja}
          />
        </Grid>
        <Grid item>
          <Button
            onClick={handleGetPDF}
            style={{ fontWeight: "bold" }}
            variant="contained"
            size="small"
            color="primary"
            startIcon={<PrintIcon />}
            fullWidth
            disabled={disabled}
          >
            IMPRIMIR
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
