import React, { useState } from "react";
import { Grid, TextField, InputAdornment, IconButton } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import AddNew from "../../components/ait-reusable/AddNew";
import { tienePermisos } from "../../utils/tienePermisos";
import { PERMISOS } from "../../constantes/permisos";
import { useSelector } from "react-redux";
import { downloadExcelAgendaClientes, downloadExcelDeuda } from "./services";
import BotonDescargar from "../../components/ait-reusable/Button/BotonDescargar";

export default function HeaderCliente({
  search,
  handleSearch,
  debouncedSearchTerm,
  filters,
  props,
  empleado,
}) {
  const [loadingExcelAgenda, setLoadingExcelAgenda] = useState(false);
  const [loadingExcelDeuda, setLoadingExcelDeuda] = useState(false);
  const tienePermisosCliente = tienePermisos(empleado, PERMISOS.CLIENTES);

  const { perfil } = useSelector((state) => state.loginReducer);
  const isVendedor =
    perfil === "Empleado" || perfil === "Vendedor" ? true : false;

  return (
    <>
      <Grid
        container
        spacing={2}
        className={"mb-2"}
        style={{ alignItems: "center" }}
      >
        <Grid
          item
          sm={4}
          xs={12}
          md={tienePermisosCliente ? 5 : 8}
          lg={tienePermisosCliente ? 5 : 8}
        >
          <TextField
            type="text"
            fullWidth
            size="small"
            variant="outlined"
            label="Buscador cliente"
            placeholder={"Buscar por CUIT, Razón Social"}
            value={search}
            onChange={(e) => handleSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: search && (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={() => handleSearch("")}>
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        {tienePermisosCliente && (
          <Grid item xs={12} sm={3} md={3} lg={3}>
            <BotonDescargar
              click={() => downloadExcelDeuda(setLoadingExcelDeuda)}
              loading={loadingExcelDeuda}
            >
              DESCARGAR DEUDAS TOTALES
            </BotonDescargar>
          </Grid>
        )}

        {!isVendedor && (
          <Grid item xs={6} sm={2} md={2}>
            <BotonDescargar
              click={() =>
                downloadExcelAgendaClientes(
                  debouncedSearchTerm,
                  filters,
                  setLoadingExcelAgenda,
                )
              }
              loading={loadingExcelAgenda}
            >
              DESCARGAR AGENDA
            </BotonDescargar>
          </Grid>
        )}

        <Grid
          item
          sm={3}
          xs={6}
          md={2}
          className={"d-flex justify-content-end"}
        >
          <AddNew
            title="NUEVO CLIENTE"
            pathname={"/clientes/cargar-cliente/"}
            history={props.history}
            name="agregar_cliente"
          />
        </Grid>
      </Grid>
    </>
  );
}
