import { Card, CardContent, Container, Grid } from "@material-ui/core";
import * as isNumber from "is-number";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CircularBackdrop from "../../../components/ait-reusable/CircularBackdrop";
import ModalReutilizable from "../../../components/ait-reusable/Modal";
import NoResults from "../../../components/ait-reusable/NoResults";
import PageHeader from "../../../components/ait-reusable/PageHeader";
import {
  errorNotification,
  successNotification,
} from "../../../components/Notifications";
import { updateCliente } from "../../../Redux/Actions/presupuestoActions";
import request from "../../../requests/request";
import {
  createClienteSucursal,
  deleteClient,
  updateClienteSucursal,
} from "../../../requests/urls";
import FormCliente from "./FormCliente";
import { formDefaultValues } from "./FormDefault";
import ActionModal from "./ModalClienteInactivo/ActionModal";
import ContentModal from "./ModalClienteInactivo/ContentModal";
import {
  convertDeudaMax,
  getCliente,
  getDataRecargos,
  updateDataClienteRedux,
} from "./utils";

export default function FormularioCliente(props) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [arrayObservaciones, setArrayObservaciones] = useState([]);
  const [clienteInactivo, setClienteInactivo] = useState(null);
  const [openModalCliInactivo, setOpenCliInactivo] = useState(false);
  const dispatch = useDispatch();
  const loginReducer = useSelector((state) => state.loginReducer);
  const { perfil, impuestos } = loginReducer;
  const { presupuesto } = useSelector((state) => state);
  const [cliente, setCliente] = useState(
    formDefaultValues({ impuestos_boxer: impuestos }),
  );

  const isVendedor =
    perfil === "Empleado" || perfil === "Vendedor" ? true : false;

  let { idCliente } = useParams();

  useEffect(() => {
    if (idCliente && isNumber(idCliente)) {
      getCliente(idCliente).then((response) => {
        if (response.status === 200) {
          const { data } = response.data;

          setCliente({
            // Setea el Model cliente completo
            ...data.idCliente,
            // Agrega los datos de el modelo Cliente de Sucursal
            comision: data.comision,
            es_comision: data.es_comision,
            id_descuento:
              data.descuento_prefijado !== null
                ? data.descuento_prefijado.id_descuento
                : " ",
            recargo: data.recargo,
            cuenta_corriente: data.cuenta_corriente,
            esta_grabado_iibb: data.idCliente.esta_grabado_iibb,
            dias_deuda: data.dias_deuda,
            deudaMaxima: data.idCliente.deudaMaxima
              ? data.idCliente.deudaMaxima.toLocaleString("es-AR")
              : "",
            id_medio_pago: data.id_medio_pago
              ? data.id_medio_pago.idMedioPago
              : null,
            envio_automatico_comprobante_venta:
              data.idCliente.envio_automatico_comprobante_venta,
            envio_automatico_recibo_pagos_billetera:
              data.idCliente.envio_automatico_recibo_pagos_billetera,
            impuestos: data.impuestos,
          });
        }
      });
    }
  }, []);

  const requestCliente = async (values, recargoSelection, updateRec) => {
    if (clienteInactivo) {
      editarClienteInactivo(values, recargoSelection, updateRec);
    } else {
      setIsSubmitting(true);
      let dataRecargos = await getDataRecargos(
        recargoSelection,
        cliente,
        updateRec,
        idCliente,
      );
      try {
        let dataCliente = {
          dni: values.numeroDocumento,
          domicilio: values.domicilio,
          razon_social: values.razonSocial,
          telefono: values.telefonoContacto,
          correo: values.correoElectronico,
          estado_tributario: values.idEstadoTributario,
          provincia: values.provincia,
          cuit: values.CUIT,
          ingresos: null,
          deuda_maxima:
            values.deudaMaxima === "" ? 0 : convertDeudaMax(values.deudaMaxima),
          is_meli: values.cliente_meli,
          es_comision: values.es_comision,
          comision: values.es_comision ? values.comision : 0,
          id_descuento:
            values.id_descuento !== " " ? values.id_descuento : null,
          recargo: dataRecargos,
          cuenta_corriente: Number(values.cuenta_corriente),
          responsable_cuenta: values.responsable_cuenta,
          dias_deuda: values.dias_deuda === "" ? null : values.dias_deuda,
          is_cliente_inactivo: false,
          id_medio_pago: values.id_medio_pago,
          tipo_cliente: values.tipo_cliente,
          comprobante_por_defecto: values.comprobantePorDefecto,
          envio_automatico_comprobante_venta:
            values.envio_automatico_comprobante_venta,
          envio_automatico_recibo_pagos_billetera:
            values.envio_automatico_recibo_pagos_billetera,
          impuestos: values.impuestos,
          forzar_medio_pago_contado_pdf: values.forzar_medio_pago_contado_pdf,
        };

        // Se agregan obervaciones solo si esta creando un cliente
        const dataClienteCreate = {
          ...dataCliente,
          observacion: arrayObservaciones,
        };

        const response = await request({
          method: idCliente ? "PUT" : "POST",
          url: idCliente
            ? updateClienteSucursal(idCliente)
            : createClienteSucursal,
          data: idCliente ? dataCliente : dataClienteCreate,
        });
        handleResponseCliente(response);
      } catch (error) {
        if (error.message.includes("409")) {
          const clienteActivo = error.response.data.data.activo;

          if (!clienteActivo && !idCliente) {
            setClienteInactivo(error.response.data.data);
            setOpenCliInactivo(true);
          } else {
            errorNotification(
              "Ya existe un cliente registrado con esa información. " +
                "Por favor, revisar el DNI o CUIT",
            );
          }
          setIsSubmitting(false);
        } else {
          errorNotification(
            "Ocurrio un error durante el registro. Verifique la información ingresada.",
          );
          setIsSubmitting(false);
        }
      }
    }
  };

  // Funcion encargada de actualizar el estado tributario del cliente seleccionado en ventas
  const updateEstadoTributarioCliente = (data) => {
    let newCliente = {
      ...presupuesto.cliente,
      estadoTributario: data.estadoTributario,
      cliente: data.cliente,
    };
    dispatch(updateCliente(newCliente));
  };
  const updateClienteVenta = async (response) => {
    if (
      presupuesto.cliente &&
      presupuesto.cliente.idClienteDeSucursal === Number(idCliente)
    ) {
      updateEstadoTributarioCliente(response.data.data);
    }
  };

  const handleResponseCliente = async (response) => {
    if (response.status === 201) {
      idCliente &&
        updateDataClienteRedux(
          response.data.data,
          presupuesto.cliente,
          dispatch,
        );
      !idCliente && setCliente({});
      await updateClienteVenta(response);
      successNotification(
        (!idCliente && "Cliente registrado exitosamente.") ||
          "Los datos fueron modificados con éxito.",
      );
      setTimeout(() => {
        props.history.push("/clientes/ver-clientes/");
      }, 2000);
    } else {
      errorNotification(
        "Ocurrio un error durante el registro. Verifique la información ingresada.",
      );
      setIsSubmitting(false);
    }
  };

  const handleCloseClienteInactivo = () => {
    setClienteInactivo(null);
    setOpenCliInactivo(false);
  };

  const handleEditClienteInactivo = async (edit) => {
    if (edit) {
      setOpenCliInactivo(false);
    } else {
      setIsSubmitting(true);
      try {
        const response = await request({
          method: "DELETE",
          url: deleteClient(clienteInactivo.idClienteDeSucursal),
          params: {
            activo: 1,
          },
        });
        if (response.status === 200) {
          setIsSubmitting(false);
          successNotification("Cliente dado de alta exitosamente.");
          setClienteInactivo(null);
          setOpenCliInactivo(false);
          props.history.goBack();
        }
      } catch (error) {
        console.error(error);
        setIsSubmitting(false);
        errorNotification("Ocurrió un error al activar el cliente.");
      }
    }
  };

  const editarClienteInactivo = async (values, recargoSelection, updateRec) => {
    setIsSubmitting(true);
    let dataRecargos = await getDataRecargos(
      recargoSelection,
      cliente,
      updateRec,
      idCliente,
    );
    try {
      const dataCliente = {
        dni: values.numeroDocumento,
        domicilio: values.domicilio,
        razon_social: values.razonSocial,
        telefono: values.telefonoContacto,
        correo: values.correoElectronico,
        estado_tributario: values.idEstadoTributario,
        provincia: values.provincia,
        cuit: values.CUIT,
        ingresos: null,
        deuda_maxima:
          values.deudaMaxima === "" ? 0 : convertDeudaMax(values.deudaMaxima),
        is_meli: values.cliente_meli,
        es_comision: values.es_comision,
        comision: values.es_comision ? values.comision : 0,
        id_descuento: values.id_descuento !== " " ? values.id_descuento : null,
        recargo: dataRecargos,
        cuenta_corriente: isVendedor ? 0 : Number(values.cuenta_corriente),
        responsable_cuenta: values.responsable_cuenta,
        esta_grabado_iibb: values.esta_grabado_iibb,
        dias_deuda: values.dias_deuda === "" ? null : values.dias_deuda,
        is_cliente_inactivo: true,
        id_medio_pago: values.id_medio_pago,
        tipo_cliente: values.tipo_cliente,
        envio_automatico_comprobante_venta:
          values.envio_automatico_comprobante_venta,
        envio_automatico_recibo_pagos_billetera:
          values.envio_automatico_recibo_pagos_billetera,
      };
      const response = await request({
        method: "PUT",
        url: updateClienteSucursal(clienteInactivo.idClienteDeSucursal),
        data: dataCliente,
      });
      handleResponseClienteInactivo(response);
    } catch (error) {
      errorNotification(
        "Ocurrio un error durante la modificación, por favor reintente.",
      );
      setIsSubmitting(false);
    }
  };

  const handleResponseClienteInactivo = (response) => {
    if (response.status === 201) {
      setCliente({});
      successNotification("Los datos fueron modificados con éxito.");
      setTimeout(() => {
        props.history.push("/clientes/ver-clientes/");
      }, 2000);
    } else {
      errorNotification(
        "Ocurrió un error durante la modificación de datos. Verifique la información ingresada.",
      );
      setIsSubmitting(false);
    }
  };

  return (
    <Container fluid className="main-content-container px-4">
      <PageHeader
        title={"CLIENTE"}
        subtitle={idCliente ? "Modificar CLIENTE" : "Registrar CLIENTE"}
        history={props.history}
        onChangeInputs
      />
      <Grid container className={"d-flex justify-content-center"}>
        <Grid item>
          <Card>
            <CardContent>
              {!idCliente ||
              (idCliente && Object.values(cliente).length !== 0) ? (
                <FormCliente
                  cliente={cliente}
                  isEdicion={!!idCliente}
                  isSubmittingForm={isSubmitting}
                  handleSubmit={requestCliente}
                  isVendedor={isVendedor}
                  setArrayObservaciones={setArrayObservaciones}
                  arrayObservaciones={arrayObservaciones}
                  clienteInactivo={clienteInactivo}
                  openModalCliInactivo={openModalCliInactivo}
                />
              ) : (
                <NoResults />
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <CircularBackdrop openBackdrop={isSubmitting} />

      {openModalCliInactivo && clienteInactivo && (
        <ModalReutilizable
          open={openModalCliInactivo}
          title="Cliente inactivo"
          handleClose={handleCloseClienteInactivo}
          content={<ContentModal clienteInactivo={clienteInactivo} />}
          actions={
            <ActionModal
              handleCloseClienteInactivo={handleCloseClienteInactivo}
              handleEditClienteInactivo={handleEditClienteInactivo}
            />
          }
        />
      )}
    </Container>
  );
}
