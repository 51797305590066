import React, { useState, useEffect } from "react";

import { Button, Grid } from "@material-ui/core";
import Period from "../../../components/ait-reusable/PeriodFilter/Period";
import { validateFilters } from "../../../components/ait-reusable/Filtros/utils";
import { getFormatedNow, lastMonth } from "../../../utils/dates";
import { downloadFile } from "./utils";

export default function LibrosMunicipales() {
  const [dates, setDates] = useState({
    fromDate: lastMonth(),
    toDate: getFormatedNow(),
  });
  const [period, setPeriod] = useState("thisMonth");
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const optionsPeriod = [
    { value: "thisMonth", name: "Corriente mes" },
    { value: "lastMonth", name: "Mes anterior" },
    { value: "thisYear", name: "Corriente año" },
    { value: "custom", name: "Personalizado" },
  ];

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      downloadFile("libro-muni", dates);
    }
  }, [errors]);

  const validateDates = () => {
    setErrors(validateFilters(dates));
    setIsSubmitting(true);
  };

  return (
    <Grid item sm={4} xs={4}>
      <Period
        period={period}
        setPeriod={setPeriod}
        dates={dates}
        setDates={setDates}
        clear={() => {}}
        searchOnLoad={false}
        optionsPeriod={optionsPeriod}
        errors={errors}
        setErrors={setErrors}
        sizeGrid={12}
        noButton={true}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ paddingBottom: 15 }}
        >
          <Button
            onClick={validateDates}
            variant="contained"
            color="primary"
            fullWidth
          >
            CONSULTAR
          </Button>
        </Grid>
      </Period>
    </Grid>
  );
}
