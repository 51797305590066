import React from "react";
import { Document, Page, Text } from "@react-pdf/renderer";
import BodyFacturaA4 from "./Components/bodyFacturaA4";
import { styles } from "../assets/css/styleA4";

export default function A4Factura({ data, tipo, config, remito, isFCEMA }) {
  const getHeightStyle = () => {
    if (tipo === "ventaA4") {
      if (data.factura && data.factura.cliente_terceros) {
        if (
          data.receipt.entries.length <= 16 ||
          data.receipt.entries.length > 18
        ) {
          return 140;
        } else if (data.receipt.entries.length === 17) {
          return 137;
        } else {
          return 150;
        }
      } else {
        if (data.receipt.entries.length <= 16) {
          return 135;
        } else {
          return 155;
        }
      }
    } else {
      let medios = data.pagos_cliente.filter((p) => p.medio_pago);
      if (data.factura && data.factura.cliente_terceros) {
        if (medios.length >= 3) {
          if (data.receipt.entries.length <= 19) {
            return 20;
          } else if (
            data.receipt.entries.length === 20 ||
            data.receipt.entries.length === 21 ||
            data.receipt.entries.length === 24
          ) {
            return 50;
          } else if (data.receipt.entries.length === 23) {
            return 60;
          } else {
            return 30;
          }
        } else {
          if (data.receipt.entries.length <= 20) {
            return 20;
          } else if (
            data.receipt.entries.length === 23 ||
            data.receipt.entries.length === 24
          ) {
            return 70;
          } else {
            return 45;
          }
        }
      } else {
        if (medios.length >= 3) {
          if (data.receipt.entries.length <= 23) {
            return 25;
          } else if (
            data.receipt.entries.length === 21 ||
            data.receipt.entries.length === 22
          ) {
            return 52;
          } else if (
            data.receipt.entries.length === 24 ||
            data.receipt.entries.length === 25
          ) {
            return 60;
          } else {
            return 30;
          }
        } else {
          if (data.receipt.entries.length <= 21) {
            return 30;
          } else if (
            data.receipt.entries.length === 22 ||
            data.receipt.entries.length === 23
          ) {
            return 62;
          } else if (
            data.receipt.entries.length === 25 ||
            data.receipt.entries.length === 26
          ) {
            return 70;
          } else {
            return 40;
          }
        }
      }
    }
  };

  return (
    <Document
      title={
        remito ? "Remito" : isFCEMA ? "Factura A MiPymes (FCE)" : "Factura"
      }
    >
      <Page
        style={{
          paddingBottom: getHeightStyle(),
          paddingTop: 20,
          paddingLeft: 20,
          paddingRight: 20,
        }}
        size="A4"
      >
        {tipo === "ventaA4" ? (
          <BodyFacturaA4
            data={data}
            tipo={tipo}
            config={config}
            remito={remito}
            isFCEMA={isFCEMA}
          />
        ) : !config.es_duplicado ? (
          <BodyFacturaA4
            data={data}
            tipo={tipo}
            config={config}
            remito={remito}
            isFCEMA={isFCEMA}
          />
        ) : (
          [0, 1].map(() => (
            <BodyFacturaA4
              data={data}
              tipo={tipo}
              config={config}
              remito={remito}
              isFCEMA={isFCEMA}
            />
          ))
        )}

        <Text
          style={
            tipo === "ventaA4"
              ? styles.pageNumber
              : {
                  fontSize: 10,
                  textAlign: "center",
                  color: "grey",
                }
          }
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
}
