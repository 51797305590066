import React from "react";
import { View, Text, Image } from "@react-pdf/renderer";
import { styles } from "../../assets/css/styleA4";
import { ValidoNotaDebito } from "./ValidoNotaDebito";
import { parseDate } from "../../../../../utils/parsers";

export default function HeaderA4({ notaDebito }) {
  return (
    <View
      style={{
        flexDirection: "row",
      }}
      fixed={false}
    >
      <View
        style={{
          flexDirection: "column",
          flex: 2,
        }}
      >
        <Text
          style={{
            flex: 1,
            textAlign: "right",
            fontSize: 17,
            marginBottom: 5,
            color: "#1B71BA",
          }}
        >
          {`${notaDebito.receipt.receipt_type} ${notaDebito.receipt.formatted_number}`}
        </Text>
        <View
          style={{
            flexDirection: "row",
            alignItems: "flex-start",
          }}
        >
          <View
            style={{
              flexDirection: "column",
              flex: 2,
              paddingBottom: 20,
            }}
          >
            <View
              style={{
                flexDirection: "column",
                flex: 2,
                paddingBottom: 20,
              }}
            >
              <Text
                style={{
                  fontWeight: 600,
                  width: 200,
                  fontSize: 10,
                  marginBottom: 5,
                  marginLeft: 10,
                  color: "#1B71BA",
                  textTransform: "uppercase",
                }}
              >
                {notaDebito.sucursal.razonSocial}
              </Text>
              <Text
                style={{
                  fontWeight: 600,
                  width: 200,
                  fontSize: 10,
                  marginBottom: 0,
                  marginLeft: 10,
                  color: "#1B71BA",
                }}
              >
                {`${notaDebito.receipt.receipt_type} ${notaDebito.receipt.formatted_number}`}
              </Text>

              <View
                style={{
                  flexDirection: "row",
                }}
              >
                <View
                  style={{
                    flexDirection: "column",
                    flex: 1,
                  }}
                >
                  <Text style={styles.boldText}>Asociada a:</Text>
                  <Text style={styles.newText}>Fecha: </Text>

                  <Text style={styles.newText}>CUIT: </Text>
                  <Text style={styles.newText}>Ing. Brutos: </Text>
                  <Text style={styles.newText}>Inicio Act.: </Text>
                </View>
                <View
                  style={{
                    flexDirection: "column",
                    flex: 2,
                  }}
                >
                  <Text style={styles.boldText}>
                    {notaDebito.factura_receipt.formatted_number +
                      " - " +
                      notaDebito.factura_receipt.issued_date}
                  </Text>
                  <Text style={styles.newText}>
                    {parseDate(
                      notaDebito.factura_receipt.validation_date,
                      "DD/MM/YY HH:mm",
                    )}
                  </Text>

                  <Text style={styles.newText}>{notaDebito.sucursal.CUIT}</Text>
                  <Text style={styles.newText}>{`${
                    notaDebito.sucursal.ingresosBrutos || "N/A"
                  }`}</Text>
                  <Text style={styles.newText}>{`${
                    notaDebito.tax_payer.active_since
                  }`}</Text>
                </View>
              </View>
            </View>
          </View>
          <View
            style={{
              flexDirection: "column",
              flex: 2,
            }}
          >
            <ValidoNotaDebito notaDebito={notaDebito} />
          </View>
        </View>
      </View>
      <View
        style={{
          flexDirection: "column",
          alignItems: "center",
          flex: 1,
        }}
      >
        <Image
          cache={false}
          style={{ width: 152, height: 115, paddingBottom: 3 }}
          allowDangerousPaths={true}
          src={
            notaDebito.logo_base64 &&
            `data:image/png/jpg;base64,${notaDebito.logo_base64}`
          }
        />
      </View>
    </View>
  );
}
