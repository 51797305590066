import React from "react";
import { Text, View } from "@react-pdf/renderer";
import { styles } from "../../assets/css/styleA4";
import { LineA4Table, LineA4TableWhite } from "../raya";

export default function BodyTable({
  descripcion,
  cant,
  precio,
  subtotal,
  noLine,
  remito,
  listaPrecio = null,
  config,
}) {
  return (
    <View style={{ paddingTop: 5 }}>
      <View style={styles.containerRowBodyTable}>
        {listaPrecio && config.incluir_lista_precios_venta && (
          <View style={(styles.containerColumn, { width: 50 })}>
            <Text style={styles.textLeftTable}>{listaPrecio}</Text>
          </View>
        )}
        <View style={(styles.containerColumn, { width: 335 })}>
          <Text style={styles.textLeftTable}>{descripcion}</Text>
        </View>
        <View style={(styles.containerColumn, { width: 25 })}>
          <Text style={styles.textCenterTable}>{cant}</Text>
        </View>

        <View style={(styles.containerColumn, { width: 50 })}>
          <Text style={styles.textCenterTable}>
            {!remito && precio.length ? `$ ${precio}` : ""}
          </Text>
        </View>

        <View style={(styles.containerColumn2, { width: 50 })}>
          <Text style={styles.textRightTable}>
            {!remito && precio.length ? `$ ${subtotal}` : ""}
          </Text>
        </View>
      </View>
      <View style={{ marginTop: -15 }}>
        {noLine ? <LineA4TableWhite /> : <LineA4Table />}
      </View>
    </View>
  );
}
