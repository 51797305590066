import React from "react";
import { Document, Page, Text, View } from "@react-pdf/renderer";
import { styles } from "../assets/css/styleA4";
import HeaderTable from "../Components/TableReport/HeaderTable";
import HeaderDataCliente from "./Components/HeaderDataCliente";
import HeaderResumen from "./Components/HeaderResumen";
import BodyTable from "../Components/TableReport/BodyTable";
import {
  columnsCtacte,
  columnsFacturasAdeudadas,
} from "../Components/TableReport/utils";
import FirmaBoxer from "../Components/firmaBoxer";

export default function MyReport({ data, tipo, logo }) {
  const handleGetTipo = (venta) => {
    if (!venta.debe) {
      return "PAGO";
    } else if (venta && !venta.tipo_factura) {
      return "CI";
    } else if (
      venta.debe &&
      venta.tipo_factura &&
      venta.tipo_comprobante === "FCEM A"
    ) {
      return "FCEM A";
    } else if (
      venta.debe &&
      venta.tipo_factura &&
      venta.tipo_comprobante === "Factura"
    ) {
      return venta.tipo_factura;
    }
  };

  return (
    <Document title={"Reporte de Cuenta Corriente y Ventas Adeudadas"}>
      <Page style={styles.page} size="A4">
        {/**---------CUENTA CORRIENTE--------------- */}
        <View>
          {/**-------------------Header Datos Cliente------------------------------ */}
          <HeaderDataCliente
            razon_social={data.ctacte && data.ctacte.cliente.razon_social}
            cuit={data.ctacte && data.ctacte.cliente.CUIT}
          />
          {/**-----------------Header empresa y montos totales--------------------- */}
          {data.vtas && data.vtas.ventas.length > 0 ? (
            <HeaderResumen
              title={"CUENTA"}
              subtitle={" CORRIENTE CLIENTE"}
              tipoValue1={"Total Factura"}
              tipoValue2={"Total Deuda"}
              value1={data.ctacte && data.ctacte.cliente.total_facturas}
              value2={data.ctacte && data.ctacte.cliente.deuda}
              logoempresa={logo}
              sucursal={data.ctate && data.ctate.sucursal.razon_social}
            />
          ) : (
            <Text style={styles.text2Center}>No hay registros que mostrar</Text>
          )}
          <View style={{ marginBottom: 20 }} fixed></View>
          {/**--------------------Table Header----------------------------------- */}
          <View fixed>
            <HeaderTable columns={columnsCtacte} />
          </View>
          {/**--------------------Table Body----------------------------------- */}
          {data.ctacte.ventas.map((x, index) => (
            <BodyTable
              key={index}
              fecha={x.fechaHoraVenta}
              tipo={handleGetTipo(x)}
              numeroComprobante={x.numero_comprobante || x.idVenta}
              monto_l={
                x.debe ? Number(x.montoTotal).toLocaleString("es-AR") : "---"
              }
              monto_r={
                !x.debe ? Number(x.montoTotal).toLocaleString("es-AR") : "---"
              }
            />
          ))}
        </View>
        <View
          style={{
            alignItems: "flex-end",
            position: "absolute",
            fontSize: 10,
            bottom: 50,
            left: 0,
            right: 0,
            width: "100%",
          }}
          fixed
        >
          <FirmaBoxer />
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
      <Page style={styles.page} size="A4">
        {/**---------VTAS ADEUDADAS--------------- */}
        <View>
          {/**-------------------Header Datos Cliente------------------------------ */}
          <HeaderDataCliente
            razon_social={data.vtas && data.vtas.cliente.razon_social}
            cuit={data.vtas && data.vtas.cliente.CUIT}
          />
          {/**-----------------Header empresa y montos totales--------------------- */}
          {data.vtas && data.vtas.ventas.length > 0 ? (
            <HeaderResumen
              title={"VENTAS"}
              subtitle={"ADEUDADAS"}
              tipoValue1={"Saldo"}
              tipoValue2={"Deuda Máxima"}
              value1={data.vtas && data.vtas.cliente.deuda}
              value2={data.vtas && data.vtas.cliente.deuda_maxima}
              logoempresa={logo}
              sucursal={data.vtas && data.vtas.sucursal.razon_social}
            />
          ) : (
            <Text style={styles.text2Center}>No hay registros que mostrar</Text>
          )}
          <View style={{ marginBottom: 20 }} fixed></View>
          {/**--------------------Table Header----------------------------------- */}
          <View fixed>
            <HeaderTable columns={columnsFacturasAdeudadas} />
          </View>
          {/**--------------------Table Body----------------------------------- */}
          {data.vtas &&
            data.vtas.ventas.length > 0 &&
            data.vtas.ventas.map((x, index) => (
              <BodyTable
                key={index}
                fecha={x.fechaHoraVenta}
                tipo={x.tipo_factura !== null ? x.tipo_factura.nombre : "CI"}
                numeroComprobante={x.numero_comprobante || x.idVenta}
                monto_l={Number(x.debe).toLocaleString("es-AR")}
                monto_r={Number(x.haber).toLocaleString("es-AR")}
              />
            ))}
        </View>
        <View
          style={{
            alignItems: "flex-end",
            position: "absolute",
            fontSize: 10,
            bottom: 50,
            left: 0,
            right: 0,
            width: "100%",
          }}
          fixed
        >
          <FirmaBoxer />
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
}
