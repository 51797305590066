import React from "react";
import { Document, Page, View, Text } from "@react-pdf/renderer";
import { styles } from "../assets/css/styleTicket";
import HeaderTicket from "./Components/HeaderTicket";
// import { parseCurrency } from "../../../../utils/parsers";
import { formatPagaCon } from "./Components/utils";

export default function FileTicket({ data }) {
  return (
    <Document title={"Comprobante"}>
      <Page style={styles.page} size={{ width: 226.77, minHeight: 400 }}>
        <View>
          <HeaderTicket logoEmpresa={data.logo} data={data} />
          <View style={styles.containerRow}>
            <View style={styles.containerColumn}>
              <Text style={styles.text}>{`${
                data.recibo.includes("Recibí") ? "Recibí de: " : "Entregué a:"
              } ${data.cliente.razonSocial}`}</Text>
            </View>
          </View>
          <View style={styles.containerRow}>
            <View style={styles.containerColumn}>
              <Text style={styles.text}>{`La suma de: ${
                data.recibo.includes("Recibí")
                  ? data.monto_letters
                  : data.monto_letters.replace("menos", "")
              }`}</Text>
            </View>
          </View>

          <Text
            style={{ marginHorizontal: 10, marginTop: -15, paddingBottom: 5 }}
          >
            ___________________
          </Text>

          <View style={styles.containerRow}>
            <View style={styles.containerColumn}>
              <Text style={styles.text}>{`En concepto de: ${
                data.concepto ? data.concepto : data.leyenda
              }`}</Text>
            </View>
          </View>

          <Text
            style={{ marginHorizontal: 10, marginTop: -15, paddingBottom: 5 }}
          >
            ___________________
          </Text>

          {data.detalles && (
            <View style={styles.containerRow}>
              <View style={styles.containerColumn}>
                {/* <View> */}

                <Text style={styles.text}>Pago con:</Text>
                {data.detalles.map((d, i) => (
                  <Text key={i} style={styles.text}>
                    {/* {d.medio_pago.nombre} : {parseCurrency(Math.abs(d.monto))} */}
                    {formatPagaCon(d)}
                  </Text>
                ))}
              </View>
            </View>
          )}
          <View style={styles.containerRow}>
            <View style={styles.containerColumn}>
              {!data.detalles && (
                <Text
                  style={styles.text}
                >{`Pago con: ${data.forma_pago}`}</Text>
              )}
            </View>
          </View>
          <Text
            style={{ marginHorizontal: 10, marginTop: -15, paddingBottom: 5 }}
          >
            ___________________
          </Text>

          {data.responsable_pago ? (
            <View style={styles.containerRow}>
              <View style={styles.containerColumn}>
                <Text
                  style={styles.text}
                >{`Responsable: ${data.responsable_pago.nombre} ${data.responsable_pago.apellido}`}</Text>
              </View>
            </View>
          ) : null}

          <View style={styles.containerRow}>
            <View style={styles.containerColumn}>
              <Text style={styles.text}>{`Observaciones: ${
                data.observacion || "Ninguna"
              }`}</Text>
            </View>
          </View>

          <Text
            style={{ marginHorizontal: 10, marginTop: -15, paddingBottom: 5 }}
          >
            ___________________
          </Text>
          <View style={styles.containerRow}>
            <View style={styles.containerColumn}>
              <Text
                style={styles.text}
              >{`Recibí Conforme: __________________ `}</Text>
            </View>
          </View>
          <View style={styles.containerRow}>
            <View style={styles.containerColumn}>
              <Text
                style={styles.text}
              >{`Entregué Conforme: ________________ `}</Text>
            </View>
          </View>
          <View style={styles.containerRowBilletera}>
            <View style={styles.containerColumn}>
              <Text style={styles.text}>{`Total: $ ${
                data.recibo.includes("Recibí")
                  ? Number(parseFloat(data.monto).toFixed(2)).toLocaleString(
                      "es-AR",
                    )
                  : Number(
                      parseFloat(Math.abs(data.monto)).toFixed(2),
                    ).toLocaleString("es-AR")
              }`}</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
}
