import React from "react";
import { View, Text } from "@react-pdf/renderer";
import { styles } from "../../assets/css/styleA4";
import { parseCurrency } from "../../../../../utils/parsers";
import { LineA4Color } from "../../Components/raya";
import FooterA4 from "../../Components/FooterA4";

export default function ResumenNCCIA4({ data, tipo, config, remito }) {
  return (
    <>
      {!remito && (
        <View
          style={
            tipo !== "ncA4Ahorro"
              ? {
                  position: "absolute",
                  left: 0,
                  right: 0,
                  bottom: 118,
                  paddingRight: 20,
                }
              : { paddingTop: 5 }
          }
        >
          <View
            style={{
              color: "#1B71BA",
              fontWeight: "bolder",
            }}
          >
            <View style={styles.containerRowTableEnd}>
              <View style={(styles.containerColumn, { width: 70 })}>
                <Text style={styles.textTotalesFactura}>{`TOTAL`}</Text>
              </View>
              <View style={(styles.containerColumn, { width: 70 })}>
                <Text style={styles.textTotalesFactura}>
                  {parseCurrency(Number(data.nota_credito.monto))}
                </Text>
              </View>
            </View>
          </View>
        </View>
      )}

      {!config.ocultar_datos_ci && (
        <View
          style={
            tipo !== "ncA4Ahorro"
              ? {
                  position: "absolute",
                  bottom: 100,
                  paddingLeft: 20,
                  paddingRight: 20,
                  left: 0,
                  right: 0,
                }
              : { paddingTop: 5 }
          }
          fixed
        >
          <LineA4Color />
        </View>
      )}
      {!config.ocultar_datos_ci && (
        <FooterA4
          fixed={tipo !== "ncA4Ahorro" ? true : false}
          sucursal={data.empresa}
          venta={data.nota_credito}
        />
      )}
    </>
  );
}
