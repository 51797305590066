import React from "react";
import { Document, Page, View, Text } from "@react-pdf/renderer";
import { styles } from "../assets/css/styleA4";
import { LineA4Color } from "../Components/raya";
import FooterA4 from "../Components/FooterA4";
import BodyA4 from "./Components/BodyA4";

export function MyDocumentA4({ data, tipo, config }) {
  return (
    <Document title={"Comprobante"}>
      <Page
        wrap
        style={{
          paddingBottom: tipo !== "A4Ahorro" ? 150 : 0,
          paddingTop: 20,
          paddingLeft: 20,
          paddingRight: 20,
        }}
        size={"A4"}
      >
        {data.map((data, index) => (
          <View key={index}>
            {tipo !== "A4Ahorro" ? (
              <BodyA4 data={data} tipo={tipo} config={config} />
            ) : !config.es_duplicado ? (
              <BodyA4 data={data} tipo={tipo} config={config} />
            ) : (
              [0, 1].map(() => (
                <BodyA4 data={data} tipo={tipo} config={config} />
              ))
            )}
          </View>
        ))}
        <View
          style={
            tipo !== "A4Ahorro"
              ? {
                  position: "absolute",
                  bottom: 100,
                  paddingLeft: 20,
                  paddingRight: 20,
                  left: 0,
                  right: 0,
                }
              : { paddingTop: 7 }
          }
          fixed
        >
          <LineA4Color />
        </View>
        <FooterA4
          fixed={tipo !== "A4Ahorro" ? true : false}
          sucursal={data[0].empresa}
        />
        <Text
          style={
            tipo !== "A4Ahorro"
              ? styles.pageNumber
              : {
                  fontSize: 10,
                  textAlign: "center",
                  color: "grey",
                }
          }
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
}
