import React from "react";
import { View } from "@react-pdf/renderer";
import HeaderA4 from "./HeaderA4Factura";
import { LineA4Color, LineA4Cut } from "../../Components/raya";
import DataClienteA4 from "./DataClienteA4";
import HeaderTable from "../../Components/TableGeneric/HeaderTable";
import BodyTableFactura from "../../Components/TableGeneric/BodyTableFactura";
import BodyTable from "../../Components/TableGeneric/BodyTable";
import {
  columnsVenta,
  columnsVentaConIva,
} from "../../Components/TableReport/utils";
import ResumenFacturaA4 from "./resumenFacturaA4";

export default function BodyFacturaA4({ data, tipo, config, remito, isFCEMA }) {
  const getFixedHeaderTable = () => {
    if (tipo === "ventaA4") {
      if (data.receipt.entries.length > 19) {
        return true;
      } else {
        return false;
      }
    } else {
      if (config.es_duplicado) {
        return false;
      } else {
        let medios = data.pagos_cliente.filter((p) => p.medio_pago);
        if (data.factura && data.factura.cliente_terceros) {
          //Aca no hay que hacer distinción de medios ya que para los 2 es lo mismo.
          return data.receipt.entries.length > 25 ? true : false;
        } else {
          if (medios.length >= 3) {
            return data.receipt.entries.length > 26 ? true : false;
          } else {
            return data.receipt.entries.length > 27 ? true : false;
          }
        }
      }
    }
  };

  // const getDescripcion = (name) => {
  //   return name.substr(0, 65).trim() + (name.length > 65 ? "" : "");
  // };

  const description = (config, x, data, index) => {
    if (!data.nota_credito) return x.description;
    let descripcionProducto = `${data.nota_credito.descripcion_ticket[index].codigo} ${data.nota_credito.descripcion_ticket[index].descripcion}`;

    return config.ocultar_codigo_pdf ? "---" : descripcionProducto;
  };

  // const getSubtotalConIva = (x) => {
  //   const precioTotalSinIva = Number(x.unit_price) * x.quantity;
  //   const ivaDelTotal = Number(Number(precioTotalSinIva).toFixed(2)) * 0.21;
  //   const totalConIva = Number(precioTotalSinIva) + Number(ivaDelTotal);
  //   return Number(totalConIva).toFixed(2);
  // };

  return (
    <>
      <HeaderA4
        fixed={tipo === "ventaA4" ? true : false}
        title={remito ? " REMITO DE VENTA" : " FACTURA DE VENTA"}
        sucursal={data.sucursal}
        logoEmpresa={data.logo}
        validation={data.validation}
        taxpayer={data.taxpayer}
        receipt={data.receipt}
        factura={data.factura}
        nro={data.factura.nroFactura}
        afip={data.receipt}
        isFCEMA={isFCEMA}
        titleNro={
          remito ? "REMITO" : isFCEMA ? "Factura A MiPymes (FCE)" : "FACTURA"
        }
        config={config}
      />
      <View fixed style={{ marginTop: -8 }}>
        <LineA4Color />
      </View>
      <DataClienteA4
        fixed={tipo === "ventaA4" ? true : false}
        factura={data.factura}
        venta={data.venta}
        pagos={data.pagos_cliente}
        tipo={tipo}
        remito={remito}
      />

      <HeaderTable
        tipo={tipo}
        columns={
          data.factura.idTipoFactura.nombre === "A" ||
          data.factura.idTipoFactura.nombre === "M"
            ? columnsVentaConIva
            : columnsVenta
        }
        fixed={getFixedHeaderTable()}
      />

      {data.factura.idTipoFactura.nombre === "A" ||
      data.factura.idTipoFactura.nombre === "M"
        ? data.receipt.entries.map((x, i) => (
            <BodyTableFactura
              key={i}
              descripcion={description(config, x, data, i)}
              cant={x.quantity}
              precio={x.unit_price}
              iva={x.unit_price !== "" ? Number(x.unit_price) * 0.21 : ""}
              // subtotal={
              //   x.unit_price !== ""
              //     ? Number(x.unit_price) * x.quantity
              //     : ""
              // }
              subtotal={Number(x.priceTotal).toFixed(2)}
              noLine={x.noLine}
              remito={remito}
              data={data}
              x={x}
            />
          ))
        : data.receipt.entries.map((x, i) => (
            <BodyTable
              key={i}
              descripcion={description(config, x, data, i)}
              cant={x.quantity}
              precio={
                x.unit_price !== ""
                  ? Number(x.unit_price).toFixed(2).toLocaleString("es-AR") //La diferencia entre factura B y C se realiza en useArticlesToPDF
                  : ""
              }
              subtotal={
                x.unit_price !== ""
                  ? Number(x.unit_price * x.quantity)
                      .toFixed(2)
                      .toLocaleString("es-AR")
                  : ""
              }
              noLine={x.noLine}
              remito={remito}
            />
          ))}

      <View
        style={tipo !== "ventaA4" ? { paddingTop: 0 } : { paddingTop: 25 }}
      />
      {/** Resumen factura contiene el resumen de los montos y los datos que aparecen en el pie de pagina del pdf */}
      <ResumenFacturaA4
        data={data}
        tipo={tipo}
        remito={remito}
        config={config}
      />
      {tipo !== "ventaA4" && (
        <View>
          <LineA4Cut />
        </View>
      )}
    </>
  );
}
