import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Divider,
  CircularProgress,
} from "@material-ui/core";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { makeStyles } from "@material-ui/styles";
import request from "../../requests/request";
import { getMontoDeudaClientesPeriod } from "../../requests/urls";
import { getFormatedNow, get7DaysAgo, get30DaysAgo } from "../../utils/dates";
import TooltipMoreInfoMaterial from "../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";

const useStyleBoxer = makeStyles(() => ({
  root: {
    position: "relative",
  },
  bottom: {
    color: "rgb(0, 123, 255)",
  },
  top: {
    color: "rgb(0, 123, 255)",
    animationDuration: "550ms",
    position: "absolute",
    left: 0,
  },
  circle: {
    strokeLinecap: "round",
  },
}));

export default function DeudasTotales() {
  const [deudas, setDeudas] = useState([
    {
      id: 2,
      name: "historical",
      title: "SALDO PENDIENTE HISTORICO",
      value: 0,
      loading: false,
      message:
        "Este saldo corresponde a aquellos clientes que hayan realizado ventas hasta la fecha actual.",
    },
    {
      id: 0,
      name: "month",
      title: "SALDO PENDIENTE ÚLTIMOS 30 DÍAS",
      value: 0,
      loading: false,
      message:
        "Este saldo corresponde a aquellos clientes que hayan realizado ventas en los últimos 30 días.",
    },
    {
      id: 1,
      name: "week",
      title: "SALDO PENDIENTE ÚLTIMOS 7 DÍAS",
      value: 0,
      loading: false,
      message:
        "Este saldo corresponde a aquellos clientes que hayan realizado ventas en los últimos 7 días.",
    },
  ]);
  const classes = useStyleBoxer();

  useEffect(() => {
    let dateToday = getFormatedNow();
    let date7DaysAgo = get7DaysAgo();
    let date30DaysAgo = get30DaysAgo();
    let copyDeudas = deudas.slice();
    copyDeudas.map((deuda) => (deuda.loading = true));

    setDeudas(copyDeudas);

    //Obtenemos los montos de deuda de los ultimos 7 dias
    getDeudasPeriodo(date7DaysAgo, dateToday, 0, "week");

    //Obtenemos los montos de deuda de los ultimos 30 dias
    getDeudasPeriodo(date30DaysAgo, dateToday, 0, "month");

    //Obtenemos los montos de deuda historica
    getDeudasPeriodo("", "", 1, "historical");
  }, []);

  /**
   * Funcion que obtiene los montos de deuda de los clientes en un periodo de tiempo
   * @param {String} desde Fecha inicio. Si es historico se envia vacio
   * @param {String} hasta Fecha fin. Si es historico se envia vacio
   * @param {Boolean} is_historico Booleano que indica si es historico o no
   * @param {String} type Tipo de periodo. Puede ser week, month o historical
   */
  const getDeudasPeriodo = async (desde, hasta, is_historico, type) => {
    try {
      const response = await request({
        method: "GET",
        url: getMontoDeudaClientesPeriod,
        params: {
          desde: desde,
          hasta: hasta,
          is_historico: is_historico,
        },
      });
      const { status, data } = response;
      if (status === 200) {
        let tempDeudas = deudas.slice();
        let index = tempDeudas.findIndex((deuda) => deuda.name === type);
        tempDeudas[index].value = data.total;
        tempDeudas[index].loading = false;
        setDeudas(tempDeudas);
      }
    } catch (error) {
      console.error(error);
      let tempDeudas = deudas.slice();
      let index = tempDeudas.findIndex((deuda) => deuda.name === type);
      tempDeudas[index].value = 0;
      tempDeudas[index].loading = false;
      setDeudas(tempDeudas);
    }
  };

  return (
    <>
      {deudas.map((deuda) => (
        <Card style={{ marginBottom: 10 }}>
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                color="textSecondary"
                style={{
                  fontSize: 12,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                {deuda.title}
              </Typography>
              <div style={{ paddingLeft: 2, paddingBottom: 4 }}>
                <TooltipMoreInfoMaterial
                  position={"top"}
                  titleTooltip={
                    <h6 style={{ fontSize: 15, marginBottom: 0, marginTop: 0 }}>
                      {deuda.message}
                    </h6>
                  }
                  color={"black"}
                />
              </div>
            </div>
          </div>
          <Divider />
          <CardContent style={{ textAlign: "center", padding: 10 }}>
            {deuda.loading ? (
              <CircularProgress className={classes.bottom} />
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  height: "35px",
                  justifyContent: "center",
                }}
              >
                <ArrowDownwardIcon style={{ color: "red" }} />
                <Typography
                  variant="h5"
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    paddingLeft: 3,
                  }}
                >
                  {`$ ${Number(deuda.value).toLocaleString("ES-ar")}`}
                </Typography>
              </div>
            )}
          </CardContent>
        </Card>
      ))}
    </>
  );
}
