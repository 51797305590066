import React from "react";
import { Document, Page } from "@react-pdf/renderer";
import { LineA4ColorMinMargin } from "../../Components/raya";
import DataClienteA4FacturaRotulo from "./DataClienteA4FacturaRotulo";
import DataClienteA4CIRotulo from "./DataClienteA4CIRotulo";

export default function RotuloEnvioVentaA4({ data, config }) {
  return (
    <Document title={`Rótulo de envío venta Nº ${data.venta.idVenta}`}>
      <Page
        style={{
          paddingBottom: 20,
          paddingTop: 20,
          paddingLeft: 20,
          paddingRight: 20,
        }}
        size={"A4"}
      >
        {data.factura ? (
          <DataClienteA4FacturaRotulo
            factura={data.factura}
            venta={data.venta}
            data={data}
          />
        ) : (
          <DataClienteA4CIRotulo data={data} />
        )}

        <LineA4ColorMinMargin />
      </Page>
    </Document>
  );
}
