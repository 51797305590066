import React from "react";
import { styles } from "../../assets/css/styleA4";
import { Text, View } from "@react-pdf/renderer";
import { LineA4Table } from "../../Components/raya";
import { parseCurrency, parseDate } from "../../../../../utils/parsers";

export const RowVentaCuentaCorriente = ({ x }) => {
  const tipoComprobante = (x) => {
    if (x.tipo_comprobante === "Comprobante interno") {
      return `CI N° ${x.numero_comprobante}`;
    } else if (x.tipo_comprobante === "FCEM A") {
      return `FCEM ${x.tipo_factura ? x.tipo_factura : ""} - ${
        x.numero_comprobate ? x.numero_comprobante : ""
      }`;
    } else {
      return `F${x.tipo_factura ? x.tipo_factura : ""} - ${
        x.numero_comprobante ? x.numero_comprobante : ""
      }`;
    }
  };

  return (
    <View style={{ paddingTop: 6 }}>
      <View style={styles.containerRowBodyTable}>
        <View style={(styles.containerColumn, { width: 50 })}>
          <Text style={styles.text4}>{parseDate(x.fecha)}</Text>
        </View>
        <View style={(styles.containerColumn, { width: 100 })}>
          <Text style={styles.text4}>{tipoComprobante(x)}</Text>
        </View>
        <View style={(styles.containerColumn, { width: 120 })}>
          <Text style={styles.text4}>
            {x.observacion &&
              `${
                x.observacion
                  ? x.observacion.substr(0, 30).trim() +
                    (x.observacion.length > 30 ? "..." : "")
                  : "---"
              }`}
          </Text>
        </View>
        <View style={(styles.containerColumn, { width: 80 })}>
          {x.debe !== 0 && (
            <Text style={styles.text4Verde}>+ {parseCurrency(x.debe)}</Text>
          )}
        </View>
        <View style={(styles.containerColumn, { width: 80 })}>
          {x.haber !== 0 && (
            <Text style={styles.text4Rojo}>- {parseCurrency(x.haber)}</Text>
          )}
        </View>
        <View style={(styles.containerColumn2, { width: 80 })}>
          <Text style={styles.text4}>{parseCurrency(x.saldo_actual)}</Text>
        </View>
      </View>
      <View style={{ marginTop: -5 }}>
        <LineA4Table />
      </View>
    </View>
  );
};
