import moment from "moment";

/**
 * Analiza una fecha dada y la convierte en una cadena de texto formateada.
 * @param {string|Date} fecha - La fecha a ser formateada.
 * @param {string} [formatString="DD/MM/YYYY"] - El formato de la fecha, por defecto es "DD/MM/YYYY".
 * @param {string} [fallback="-"] - El valor a devolver si la fecha es inválida, por defecto es "-"
 * @returns {string} - La cadena de texto de la fecha formateada o el valor predeterminado si la fecha es inválida.
 */
export const parseDate = (
  date,
  formatString = "DD/MM/YYYY",
  fallback = "-",
) => {
  if (!date) {
    console.warn("Fecha inválida:", date);
    return fallback;
  }

  const momentDate = moment(date);

  if (!momentDate.isValid()) {
    console.warn("Fecha inválida:", date);
    return fallback;
  }

  return momentDate.format(formatString);
};

export function parseCurrency(num) {
  let valueNumber = Number(num);

  return valueNumber.toLocaleString("es-AR", {
    style: "currency",
    currency: "ARS",
  });
}

/** Formato para estilo de monea
 * En caso de no se pase un tipo de moneda se utiliza por defecto
 * el formato argentino */
export const formatoMoneda = ({ value, currency = null }) => {
  const valueNumber = Number(value);
  let currencyValue = "ARS";

  if (currency) {
    currencyValue = currency;
  }

  return new Intl.NumberFormat("Es-ar", {
    style: "currency",
    currency: currencyValue,
  }).format(valueNumber);
};

/**
 * Formatea un número como una moneda en un string
 * @param {number} number - El número a formatear
 * @param {number} [decimals=2] - La cantidad de decimales a mostrar, por defecto es 2
 * @param {string} [currency="ARS"] - El código de la moneda a utilizar, por defecto es ARS
 * @param {string} [locale="es-AR"] - El lenguaje y región a utilizar, por defecto es es-AR
 * @returns {string} El número formateado como una moneda
 */
export const formatCurrency = (
  number,
  decimals = 2,
  currency = "ARS",
  locale = "es-AR",
) => {
  if (isNaN(number) || !isFinite(number)) {
    return new Intl.NumberFormat(locale, {
      style: "currency",
      currency,
    }).format(0);
  }

  const formatter = new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });

  return formatter.format(number);
};

/**
 * Redondea hacia abajo un número decimal con 2 decimales
 *
 * // Ejemplos
 *     console.log(roundHalfDown(1.455)); // 1.45
 *     console.log(roundHalfDown(1.456)); // 1.45
 *     console.log(roundHalfDown(1.465)); // 1.46
 *     console.log(roundHalfDown(1.475)); // 1.47
 */
export function roundHalfDown(num) {
  let valueNumber = Number(num);
  const factor = 100; // Para redondear con 2 decimales
  const scaledNum = valueNumber * factor;
  const fraction = scaledNum - Math.floor(scaledNum);

  if (fraction === 0.5) {
    // Redondear hacia abajo si la fracción es exactamente 0.5
    return Math.floor(scaledNum) / factor;
  }
  // Si no es 0.5, usar el redondeo normal
  return Math.round(scaledNum) / factor;
}
