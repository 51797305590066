import React from "react";
import { Image } from "@react-pdf/renderer";
import marca from "../assets/images/marcaBoxer.png";

export default function FirmaBoxer() {
  return (
    <Image
      src={marca}
      style={{
        width: 100,
      }}
    />
  );
}
