import React from "react";
// import { styles } from "../../assets/css/styleA4";
import { View } from "@react-pdf/renderer";
import { LineA4Color, LineA4Cut } from "../../Components/raya";
import BodyTable from "../../Components/TableGeneric/BodyTable";
import HeaderTable from "../../Components/TableGeneric/HeaderTable";
import { columnsPresupuesto } from "../../Components/TableReport/utils";
import HeaderA4CI from "./HeaderA4CI";
import ResumenCIA4 from "./resumenCIA4";
import DataClienteA4CI from "./DataClienteA4CI";

export default function BodyCIA4({ data, tipo, config, remito }) {
  const getFixedHeaderTable = () => {
    if (tipo === "ventaA4") {
      return data.venta.detalle_ventas.length > 22 ? true : false;
    } else {
      if (config.es_duplicado) {
        return false;
      } else {
        return data.venta.detalle_ventas.length > 28 ? true : false;
      }
    }
  };

  // const getDescripcion = (name) => {
  //   return name.substr(0, 65).trim() + (name.length > 65 ? "..." : "");
  // };
  return (
    <>
      <HeaderA4CI
        fixed={tipo === "ventaA4" ? true : false}
        logoEmpresa={data.logo}
        sucursal={data.sucursal}
        venta={data.venta}
        config={config}
        titleNro={remito ? "REMITO" : "COMPROBANTE"}
        nro={data.venta.idVenta}
      />

      <LineA4Color />

      <DataClienteA4CI tipo={tipo} data={data} remito={remito} />

      <HeaderTable
        fixed={getFixedHeaderTable()}
        columns={columnsPresupuesto(config)}
      />

      {data.venta.detalle_ventas.map((x, index) => (
        <BodyTable
          key={index}
          config={config}
          descripcion={x.newDescripcion}
          cant={x.cantidad !== "" ? parseFloat(x.cantidad) : ""}
          precio={
            x.subtotal !== ""
              ? Number(
                  (Number(x.subtotal) / x.cantidad).toFixed(2),
                ).toLocaleString("es-AR")
              : ""
          }
          subtotal={
            x.subtotal !== ""
              ? Number(Number(x.subtotal).toFixed(2)).toLocaleString("es-AR")
              : ""
          }
          noLine={x.noLine}
          remito={remito}
          listaPrecio={x.nombre_lista_precios ? x.nombre_lista_precios : "---"}
        />
      ))}

      {/** Resumen CI contiene el resumen de los montos y los datos que aparecen en el pie de pagina del pdf */}

      <ResumenCIA4 data={data} tipo={tipo} config={config} remito={remito} />

      {tipo !== "ventaA4" && (
        <View>
          <LineA4Cut />
        </View>
      )}
    </>
  );
}
