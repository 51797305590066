import axios from "axios";

export function HeaderToken() {
  let token = localStorage.getItem("token");
  axios.defaults.headers = {
    "Content-Type": "application/json",
    Authorization: `Token ${token}`,
  };
}

export const getTokenHeader = () => {
  const token = localStorage.getItem("token");
  const tokenHeader = { Authorization: `Token ${token}` };
  return tokenHeader;
};
