import React, { useState } from "react";
import { Avatar, Grid, Card, CardContent, Typography } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import Provincia from "./Provincia";
import FormCliente from "./FormCliente";
import DetalleCliente from "../../../Presupuesto/Consumidor/DetalleCliente";
import ModalCargaRapidaCliente from "../../../Presupuesto/Consumidor/CargaRapidaCliente/ModalCargaRapidaCliente";
import ModalObservacion from "../../../../components/ait-reusable/ModalObservacion";
import {
  updateCliente,
  updateProvincia,
} from "../../../../Redux/Actions/remitoActions";
import { Alert } from "@material-ui/lab";
import ModalInfoErrorCargaRapidaCliente from "../../../../components/ait-reusable/ModalInfoErrorCargaRapidaCliente";

export default function Cliente({ configCCC }) {
  const [arrayObservaciones, setArrayObservaciones] = useState([]);
  const [provinciaDefault, setProvinciaDefault] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openModalNuevoCliente, setOpenModalNuevoCliente] = useState(false);
  const [openModalObservaciones, setOpenModalObservaciones] = useState(false);
  const { cliente, provincia } = useSelector((state) => state.remitoReducer);
  const dispatch = useDispatch();
  const [openModalInfoFiscal, setOpenModalInfoFiscal] = useState(false);
  const [dataInfoFiscal, setDataInfoFiscal] = useState(null);

  const handleChangeModal = () => {
    setOpenModalNuevoCliente(!openModalNuevoCliente);
  };

  const handleCloseModal = () => {
    setOpenModalNuevoCliente(false);
    setArrayObservaciones([]);
  };

  const handleCloseModalObservacion = () => {
    setOpenModalObservaciones(!openModalObservaciones);
  };

  const handleChangeCliente = async (cliente) => {
    dispatch(updateCliente(cliente));
    dispatch(
      updateProvincia(
        cliente && cliente.cliente.provincia
          ? cliente.cliente.provincia
          : provinciaDefault
            ? provinciaDefault.id
            : null,
      ),
    );
    openModalNuevoCliente && setOpenModalNuevoCliente(!openModalNuevoCliente);
  };

  return (
    <Card className="card-gray">
      <CardContent className="p-0">
        <Grid
          container
          spacing={1}
          style={{ textAlign: "center", borderBottom: "1px solid lightgray" }}
        >
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography variant="h5" style={{ fontSize: 19 }}>
              CLIENTE
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={1} style={{ padding: 15, paddingBottom: 0 }}>
          {configCCC &&
            configCCC.dataConfig.mensaje !== "" &&
            configCCC.dataConfig.bloquearCtaCte && (
              <Alert
                style={{ paddingBottom: 0, paddingTop: 0, marginBottom: 5 }}
                severity={"error"}
              >
                <strong>{configCCC.dataConfig.mensaje}</strong>
              </Alert>
            )}
          <FormCliente
            loading={loading}
            cliente={cliente}
            openModalNuevoCliente={openModalNuevoCliente}
            setOpenModalNuevoCliente={setOpenModalNuevoCliente}
            provinciaDefault={provinciaDefault}
            handleChangeCliente={(cliente) => handleChangeCliente(cliente)}
          />

          <Provincia
            loading={loading}
            provincia={provincia}
            cliente={cliente}
            provinciaDefault={provinciaDefault}
            setProvinciaDefault={setProvinciaDefault}
          />

          <Grid item xs={2}>
            <Avatar
              variant="rounded"
              title="Agregar nuevo cliente"
              style={{
                backgroundColor: "rgb(0, 123, 255)",
                cursor: "pointer",
                width: "100%",
                height: "100%",
                fontSize: 25,
                fontWeight: "bold",
              }}
              onClick={handleChangeModal}
            >
              +
            </Avatar>
          </Grid>
        </Grid>

        <Grid container spacing={1} style={{ padding: 15, paddingTop: 0 }}>
          <DetalleCliente
            cliente={cliente}
            isVenta={false}
            setOpenModalObservaciones={setOpenModalObservaciones}
          />
        </Grid>
      </CardContent>
      {openModalNuevoCliente && (
        <ModalCargaRapidaCliente
          open={openModalNuevoCliente}
          closeModal={() => handleCloseModal()}
          agregarCliente={(cliente) => handleChangeCliente(cliente)}
          arrayObservaciones={arrayObservaciones}
          setOpenModalObservacciones={setOpenModalObservaciones}
          setArrayObservaciones={setArrayObservaciones}
          setOpenModalInfoFiscal={setOpenModalInfoFiscal}
          setDataInfoFiscal={setDataInfoFiscal}
        />
      )}
      {openModalObservaciones && (
        <ModalObservacion
          open={openModalObservaciones}
          handleClose={handleCloseModalObservacion}
          clienteSelected={cliente}
          arrayObservaciones={arrayObservaciones}
          setArrayObservaciones={setArrayObservaciones}
          updateCliente={(value) => dispatch(updateCliente(value))}
          cliente={cliente}
        />
      )}

      {openModalInfoFiscal && dataInfoFiscal && (
        <ModalInfoErrorCargaRapidaCliente
          open={openModalInfoFiscal}
          data={dataInfoFiscal}
          setOpenModalInfoFiscal={setOpenModalInfoFiscal}
          setDataInfoFiscal={setDataInfoFiscal}
        />
      )}
    </Card>
  );
}
