import create from "zustand";

const initialFiltersClient = {
  orderBy: "sin_orden", // ordenFiltro
  ctaCte: "todos",
  tipoCliente: "todos",
  tipoDeCliente: "todos",
  activos: 1,
  transporte: null,
};

export const useClientFilterStore = create((set) => ({
  openFilters: false,

  setOpenFilters: (open) => set({ openFilters: open }),

  filters: initialFiltersClient,

  setFilter: (key, value) =>
    set((state) => ({ filters: { ...state.filters, [key]: value } })),

  reset: (key) => {
    set((state) => {
      const filters = key
        ? { ...state.filters, [key]: initialFiltersClient[key] }
        : initialFiltersClient;
      return { filters };
    });
  },
}));
