import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import AutocompleteTransporteFiltro from "../../../../components/ait-reusable/AutocompleteTransporteFiltro";

export default function FilterTransport({ filter, setFilter }) {
  const [transporteSelected, setTransporteSelected] = useState(null);

  useEffect(() => {
    setFilter("transporte", transporteSelected);
  }, [transporteSelected]);

  return (
    <Grid item xs={12} sm={12} md={4} lg={4}>
      <AutocompleteTransporteFiltro
        transporteSelected={filter}
        setTransporteSelected={setTransporteSelected}
      />
    </Grid>
  );
}
