import React from "react";
import { Grid, Typography, Divider } from "@material-ui/core";
import { AlertReusable } from "../Alerts/AlertReusable";

export default function ContentInfo({ data }) {
  const { data: infoData } = data.details;
  const { general_info } = infoData;

  const errorMapping = [
    {
      key: "data_error",
      title: "Error en los datos",
      divider: [
        "error_constancia_info",
        "error_monotributo_info",
        "error_regimen_general_info",
      ],
    },
    {
      key: "error_constancia_info",
      title: "Error en la constancia",
      divider: ["error_monotributo_info", "error_regimen_general_info"],
    },
    {
      key: "error_monotributo_info",
      title: "Error en monotributo",
      divider: ["error_regimen_general_info"],
    },
    {
      key: "error_regimen_general_info",
      title: "Error en el régimen general",
      divider: [],
    },
  ];

  const getDatosCliente = () => {
    if (general_info) {
      let tipoClave = general_info.tipoClave || "";
      let razonSocial = general_info.razonSocial
        ? general_info.razonSocial
        : general_info.nombre + " " + general_info.apellido;
      let numero = general_info.idPersona || "";

      return `Se encontraron los siguientes errores en AFIP al consultar el ${tipoClave} N° ${numero} para la persona ${razonSocial}.`;
    } else {
      return `Se encontraron los siguientes errores en AFIP.`;
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <AlertReusable severity={"error"} text={getDatosCliente()} />
        </Grid>
      </Grid>

      {errorMapping.map((error) => {
        const errorData = infoData[error.key];
        if (!errorData) return null;

        // Verificar si mostrar el Divider según las claves en `divider`.
        const shouldShowDivider = error.divider.some((key) => infoData[key]);
        return (
          <React.Fragment key={error.key}>
            <Grid container spacing={2} style={{ paddingTop: 5 }}>
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  style={{ fontWeight: "bold", fontSize: 16 }}
                >
                  {errorData.message}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <ul>
                  {errorData.error.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </Grid>
            </Grid>
            {shouldShowDivider && <Divider />}
          </React.Fragment>
        );
      })}
    </>
  );
}
