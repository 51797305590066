import React from "react";
import ModalReutilizable from "../Modal";
import ContentInfo from "./ContentInfo";
import ButtonAceptar from "../Button/ButtonAceptar";

export default function ModalInfoErrorCargaRapidaCliente({
  open,
  data,
  setOpenModalInfoFiscal,
  setDataInfoFiscal,
}) {
  const { data: infoData } = data.details;
  const handleClose = () => {
    setOpenModalInfoFiscal(false);
    setDataInfoFiscal(null);
  };

  const getErrorTitulo = () => {
    const errores = [
      infoData.error_constancia_info,
      infoData.error_monotributo_info,
      infoData.error_regimen_general_info,
      infoData.data_error,
    ];

    const primerError = errores.find((error) => error !== null);

    return `Error de AFIP ${primerError && primerError.message ? "- " + primerError.message : ""}`;
  };
  return (
    <ModalReutilizable
      title={getErrorTitulo()}
      open={open}
      handleClose={handleClose}
      content={<ContentInfo data={data} />}
      actions={<ButtonAceptar click={handleClose} message={"ACEPTAR"} />}
    />
  );
}
