import React from "react";
import { View } from "@react-pdf/renderer";
import HeaderA4 from "../../DeVentas/Components/HeaderA4Factura";
import { LineA4Color, LineA4Cut } from "../../Components/raya";
import HeaderTable from "../../Components/TableGeneric/HeaderTable";
import BodyTableFactura from "../../Components/TableGeneric/BodyTableFactura";
import {
  // columnsPresupuesto,
  columnsVentaConIva,
  columnsNCMontosConIva,
  columnsNCMontos,
  columnsVenta,
} from "../../Components/TableReport/utils";
import BodyTable from "../../Components/TableGeneric/BodyTable";
import BodyTableNCMontos from "./BodyTableNCMontos";
import ResumenNCFacturaA4 from "./ResumenNCFacturaA4";
import DataClientA4 from "./DataClientA4";

// const description = (config, x, data, index) => {
//   let descripcionProducto = `${
//     !config.ocultar_codigo_pdf
//       ? data.nota_credito.descripcion_ticket[index].codigo + " - "
//       : ""
//   } ${data.nota_credito.descripcion_ticket[index].descripcion}`;

//   return descripcionProducto;
// };

export default function BodyNCFacturaA4({
  data,
  tipo,
  config,
  remito,
  isFCEMA,
}) {
  const description = (config, x, data, index) => {
    const articulos = data.detalles_venta;

    if (articulos.length) {
      const articulo = articulos.find(
        (articulo) => articulo.descripcion_original === x.descripcion_original,
      );

      if (articulo && articulo.descripcion_personalizada) {
        return articulo.descripcion_personalizada;
      }
    }

    return `${
      !config.ocultar_codigo_pdf
        ? data.nota_credito.descripcion_ticket[index].codigo + " - "
        : ""
    } ${data.nota_credito.descripcion_ticket[index].descripcion}`;
  };

  return (
    <>
      <HeaderA4
        fixed={tipo !== "ncA4Ahorro" ? true : false}
        sucursal={data.empresa}
        title={remito ? "REMITO" : "NOTA DE CRÉDITO"}
        logoEmpresa={data.logo}
        receipt={data.nota_credito.datos_afip.receipt_type}
        factura={data.nota_credito}
        tipo={tipo}
        nro={data.nota_credito.nroNotaCredito}
        titleNro={
          remito
            ? "Remito"
            : isFCEMA
              ? "NOTA DE CRÉDITO A MiPymes (FCE)"
              : "NOTA DE CRÉDITO"
        }
        data={data}
        afip={data.nota_credito.datos_afip}
        taxpayer={data.nota_credito.datos_afip.tax_payer}
        isFCEMA={isFCEMA}
        config={config}
      />
      <LineA4Color />
      <DataClientA4
        cliente={data.cliente}
        clienteTerceros={data.cliente_terceros}
        observacion={data.nota_credito.observacion}
      />
      <HeaderTable
        fixed={tipo !== "ncA4Ahorro" ? true : false}
        columns={
          !data.nota_credito.por_montos
            ? data.factura.idTipoFactura.nombre === "A" ||
              data.factura.idTipoFactura.nombre === "M"
              ? columnsVentaConIva
              : columnsVenta
            : data.factura.idTipoFactura.nombre === "A" ||
                data.factura.idTipoFactura.nombre === "M"
              ? columnsNCMontosConIva
              : columnsNCMontos
        }
      />
      {data.factura.idTipoFactura.nombre === "A" ||
      data.factura.idTipoFactura.nombre === "M" ? (
        !data.nota_credito.por_montos ? (
          data.nota_credito.detalle.map((x, index) => (
            <View key={index}>
              <BodyTableFactura
                descripcion={description(config, x, data, index)}
                cant={x.quantity}
                precio={Number((Number(x.unit_price) * (1 / 1.21)).toFixed(2))}
                iva={Number(
                  (
                    Number(x.unit_price) - Number(x.unit_price * (1 / 1.21))
                  ).toFixed(2),
                )}
                subtotal={Number(
                  Number(x.unit_price * (1 / 1.21) * x.quantity).toFixed(2),
                )}
                remito={remito}
              />
            </View>
          ))
        ) : (
          <View>
            <BodyTableNCMontos
              precio={Number(
                (Number(data.nota_credito.monto) * (1 / 1.21)).toFixed(2),
              )}
              iva={Number(
                (
                  Number(data.nota_credito.monto) -
                  Number(data.nota_credito.monto * (1 / 1.21))
                ).toFixed(2),
              )}
              subtotal={Number(
                Number(data.nota_credito.monto * (1 / 1.21)).toFixed(2),
              )}
              isNC={true}
              remito={remito}
            />
          </View>
        )
      ) : !data.nota_credito.por_montos ? (
        data.nota_credito.detalle.map((x, index) => (
          <View>
            <BodyTable
              // descripcion={description(config, x, data, index)}
              descripcion={description(config, x, data, index)}
              cant={x.quantity}
              precio={Number(Number(x.unit_price).toFixed(2)).toLocaleString(
                "es-AR",
              )}
              subtotal={Number(
                (Number(x.unit_price) * x.quantity).toFixed(2),
              ).toLocaleString("es-AR")}
              remito={remito}
            />
          </View>
        ))
      ) : (
        <View>
          <View>
            <BodyTableNCMontos
              precio={Number(Number(data.nota_credito.monto).toFixed(2))}
              iva={null}
              subtotal={Number(Number(data.nota_credito.monto).toFixed(2))}
              isNC={true}
              remito={remito}
            />
          </View>
        </View>
      )}
      <ResumenNCFacturaA4 data={data} tipo={tipo} remito={remito} />
      {tipo === "ncA4Ahorro" && (
        <View>
          <LineA4Cut />
        </View>
      )}
    </>
  );
}
