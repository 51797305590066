import React from "react";
import { View, Text } from "@react-pdf/renderer";
import { styles } from "../../assets/css/styleTicket";
import { parseCurrency } from "../../../../../utils/parsers";

export default function TotalesCtaCte({
  plazos,
  montoTotal,
  fechaVenta,
  tieneLeyenda = false,
}) {
  const getAmountTotal = (porcentaje) => {
    let newMonto = Number(
      Number(montoTotal) / (1 + Number(plazos[plazos.length - 1].porcentaje)),
    ).toFixed(2);
    let preTotal = Number(newMonto) * (1 + Number(porcentaje));
    let total = ~~(preTotal * 100) / 100;
    return total;
  };

  const getNewDate = (dias) => {
    let newFecha = new Date(fechaVenta);

    newFecha.setDate(newFecha.getDate() + Number(dias));

    let newDay =
      newFecha.getDate() < 10 ? `0${newFecha.getDate()}` : newFecha.getDate();
    let newMonth =
      newFecha.getMonth() + 1 < 10
        ? `0${newFecha.getMonth() + 1}`
        : newFecha.getMonth() + 1;

    return `${newDay}/${newMonth}`;
  };

  return (
    <View
      style={{
        marginBottom: tieneLeyenda ? 50 : 0,
      }}
    >
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          paddingHorizontal: 10,
        }}
      >
        <View style={styles.containerColumn}>
          <Text
            style={{ paddingBottom: 2, fontSize: 10, fontWeight: "bolder" }}
          >
            {"CONDICIONES DE PAGO"}
          </Text>
        </View>
      </View>

      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          paddingHorizontal: 10,
        }}
      >
        <View style={styles.containerColumn}>
          <Text style={styles.textbold}>{`Pago hasta el ${getNewDate(
            Number(plazos[0].dias) - 1,
          )}: ${parseCurrency(Number(getAmountTotal(0)))}`}</Text>
        </View>
      </View>
      {plazos.map((item, index) => {
        return (
          <View
            key={index}
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              paddingHorizontal: 10,
            }}
          >
            <View style={styles.containerColumn}>
              <Text style={styles.textbold}>{`Pago ${
                index + 1 !== plazos.length ? "hasta" : "desde"
              } el ${getNewDate(
                index + 1 !== plazos.length
                  ? Number(plazos[index + 1].dias) - 1
                  : item.dias,
              )}: ${parseCurrency(
                Number(getAmountTotal(item.porcentaje)),
              )}`}</Text>
            </View>
          </View>
        );
      })}
    </View>
  );
}
