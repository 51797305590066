import React from "react";
import { styles } from "../../assets/css/styleA4";
import { View, Text } from "@react-pdf/renderer";

export default function DataClientA4({ notaDebito }) {
  return (
    <View>
      <View style={styles.containerRow}>
        <View style={(styles.containerColumn, { width: 300 })}>
          <View style={{ flexDirection: "row", paddingTop: 5 }}>
            <View style={(styles.containerColumn, { width: 50 })}>
              <Text style={styles.text2Title}>{`Sr.(es):`}</Text>
              <Text style={styles.text2Title}>Domicilio:</Text>
              {notaDebito.referente ? (
                <Text style={styles.text2Title}>Referente:</Text>
              ) : null}
            </View>
            <View style={(styles.containerColumn, { width: 250 })}>
              <Text style={styles.text2}>
                {notaDebito.cliente.razon_social}
              </Text>
              <Text style={styles.text2}>{notaDebito.cliente.domicilio}</Text>
              {notaDebito.referente ? (
                <Text style={styles.text2}>{notaDebito.referente}</Text>
              ) : null}
            </View>
          </View>
        </View>
        <View style={(styles.containerColumn, { width: 200 })}>
          <View
            style={{
              flexDirection: "row",
              paddingTop: 5,
            }}
          >
            <View style={(styles.containerColumn, { width: 80 })}>
              <Text style={styles.text2Title}>DNI/CUIT:</Text>
              <Text style={styles.text2Title}>Condición de IVA:</Text>
            </View>
            <View style={(styles.containerColumn, { width: 120 })}>
              <Text style={styles.text2}>{notaDebito.cliente.cuit_dni}</Text>
              <Text style={styles.text2}>
                {notaDebito.cliente.estado_tributario}
              </Text>
            </View>
          </View>
        </View>
      </View>
      <View style={styles.containerRow}>
        <View style={(styles.containerColumn, { width: 500 })}>
          <View style={{ flexDirection: "row" }}>
            <View style={(styles.containerColumn, { width: 70 })}>
              <Text style={styles.text2Title}>Observación:</Text>
            </View>
            <View style={(styles.containerColumn, { width: 430 })}>
              <Text style={styles.text2}>
                {notaDebito.observaciones || "---"}
              </Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
}
