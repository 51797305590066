import React, { Fragment } from "react";
import { View, Text } from "@react-pdf/renderer";
import { styles } from "../../assets/css/styleTicket";
import { parseCurrency } from "../../../../../utils/parsers";
import { getPriceNetoGravado, textAbbreviation } from "./utils";

function getSubtotal(vat) {
  return vat.reduce((total, iva) => Number(iva.base_amount) + total, 0);
}

export default function TotalesTicketFactura({
  factura,
  vat,
  receipt,
  dcto,
  recargo,
  data,
}) {
  const { nombre } = factura.idTipoFactura;

  const amountSubtotal =
    nombre === "A" || nombre === "M"
      ? Number(getSubtotal(vat) + Number(dcto) - Number(recargo))
      : Number(receipt.total_amount) + Number(dcto) - Number(recargo);

  const porcDescuento = factura.is_agrupada
    ? ""
    : data.porcentaje_descuento
      ? "(" + Number(data.porcentaje_descuento) * 100 + "%)"
      : "";

  const isGreaterThanOrEqualToOneMillion =
    Number(receipt.total_amount) > 1000000;

  const addTextEmpty = (text) => {
    let value = false;
    let newText = textAbbreviation(text);

    if (
      (isGreaterThanOrEqualToOneMillion && newText.length > 19) ||
      (!isGreaterThanOrEqualToOneMillion && newText.length > 22)
    ) {
      value = true;
    }
    return value;
  };

  return (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "space-between",
        paddingHorizontal: 10,
      }}
    >
      <View
        style={[
          styles.containerColumn,
          { width: isGreaterThanOrEqualToOneMillion ? "75%" : "82%" },
        ]}
      >
        <View>
          <Text style={styles.textTotalesFactura}>{"SUBTOTAL"}</Text>
          <Text style={styles.textTotalesFactura}>
            {"DESCUENTO" + porcDescuento}
          </Text>
          <Text style={styles.textTotalesFactura}>{`RECARGO`}</Text>
          {(nombre === "A" || nombre === "M") && (
            <Text style={styles.textTotalesFactura}>{`NETO GRAVADO`}</Text>
          )}
          {(nombre === "A" || nombre === "M") &&
            receipt.taxes.map((tax, index) => (
              <Text key={index} style={styles.textTotalesFacturaAlignLeft}>
                {`${textAbbreviation(
                  tax.tax_type.description,
                ).toUpperCase()} - ${Number(tax.aliquot)}%`}
              </Text>
            ))}
          <Text style={styles.textTotalesFactura}>
            {(nombre === "A" || nombre === "M") &&
              `IVA ${vat[0].vat_type.description || ""}`}
          </Text>
        </View>

        <Text style={styles.textTotalesFactura}>TOTAL</Text>
      </View>
      <View style={styles.containerColumn2}>
        <View>
          <Text style={styles.textTotalesFactura}>
            {factura.is_agrupada
              ? parseCurrency(
                  nombre === "A" || nombre === "M"
                    ? getPriceNetoGravado(amountSubtotal, dcto, recargo)
                    : Number(receipt.total_amount),
                )
              : parseCurrency(amountSubtotal)}
          </Text>
          <Text style={styles.textTotalesFactura}>
            {parseCurrency(Number(factura.is_agrupada ? 0 : dcto))}
          </Text>
          <Text style={styles.textTotalesFactura}>
            {parseCurrency(Number(factura.is_agrupada ? 0 : recargo))}
          </Text>
          {(nombre === "A" || nombre === "M") && (
            <Text style={styles.textTotalesFactura}>
              {parseCurrency(
                getPriceNetoGravado(amountSubtotal, dcto, recargo),
              )}
            </Text>
          )}
          {(nombre === "A" || nombre === "M") &&
            receipt.taxes.map((tax, index) => (
              <Fragment key={index}>
                <Text style={styles.textTotalesFactura}>
                  {parseCurrency(Number(tax.amount))}
                </Text>
                {addTextEmpty(tax.tax_type.description) ? (
                  <Text
                    style={[styles.textTotalesFactura, { paddingTop: 6 }]}
                  ></Text>
                ) : null}
              </Fragment>
            ))}
          <Text style={styles.textTotalesFactura}>
            {(nombre === "A" || nombre === "M") &&
              `${parseCurrency(Number(vat[0].amount))}`}
          </Text>
        </View>

        <Text style={styles.textTotalesFactura}>
          {parseCurrency(Number(receipt.total_amount))}
        </Text>
      </View>
    </View>
  );
}
