import React from "react";
import { styles } from "../../assets/css/styleA4";
import { View, Text } from "@react-pdf/renderer";

export default function DataClientA4({
  cliente,
  clienteTerceros,
  observacion,
}) {
  const obtenerNombre = () => {
    if (clienteTerceros) return clienteTerceros.razon_social;
    if (cliente) return cliente.razonSocial;
    if (!cliente) return "Consumidor final";
  };

  const obtenerDomicilio = () => {
    if (clienteTerceros) return clienteTerceros.domicilio;
    if (cliente) return cliente.domicilio;
    if (!cliente) return "-";
  };

  const obtenerCUIT = () => {
    if (clienteTerceros) return clienteTerceros.cuit;
    if (cliente) return cliente.CUIT;
    if (!cliente) return "-";
  };

  const obtenerEstadoTributario = () => {
    if (clienteTerceros) return clienteTerceros.estado_tributario.descripcion;
    if (cliente) return cliente.estadoTributario.descripcion;
    if (!cliente) return "---";
  };

  /**
   * La factura a siempre tiene un cliente
   * en caso de ser a un cliente de terceros,
   * El cliente seria el "mecanico o referente"
   * @returns string
   */
  const mecanicoCliente = () => {
    if (clienteTerceros) {
      return cliente ? cliente.razonSocial : "---";
    } else {
      return "---";
    }
  };

  return (
    <View>
      <View style={styles.containerRow}>
        <View style={(styles.containerColumn, { width: 300 })}>
          <View style={{ flexDirection: "row", paddingTop: 5 }}>
            <View style={(styles.containerColumn, { width: 50 })}>
              <Text style={styles.text2Title}>{`Sr.(es):`}</Text>
              <Text style={styles.text2Title}>Domicilio:</Text>
              {clienteTerceros ? (
                <Text style={styles.text2Title}>Referente:</Text>
              ) : null}
            </View>
            <View style={(styles.containerColumn, { width: 250 })}>
              <Text style={styles.text2}>{obtenerNombre()}</Text>
              <Text style={styles.text2}>{obtenerDomicilio()}</Text>
              {clienteTerceros ? (
                <Text style={styles.text2}>{mecanicoCliente()}</Text>
              ) : null}
            </View>
          </View>
        </View>
        <View style={(styles.containerColumn, { width: 200 })}>
          <View
            style={{
              flexDirection: "row",
              paddingTop: 5,
            }}
          >
            <View style={(styles.containerColumn, { width: 80 })}>
              <Text style={styles.text2Title}>DNI:</Text>
              <Text style={styles.text2Title}>Condición de IVA:</Text>
            </View>
            <View style={(styles.containerColumn, { width: 120 })}>
              <Text style={styles.text2}>{obtenerCUIT()}</Text>
              <Text style={styles.text2}>{obtenerEstadoTributario()}</Text>
            </View>
          </View>
        </View>
      </View>
      <View style={styles.containerRow}>
        <View style={(styles.containerColumn, { width: 500 })}>
          <View style={{ flexDirection: "row" }}>
            <View style={(styles.containerColumn, { width: 70 })}>
              <Text style={styles.text2Title}>Observación:</Text>
            </View>
            <View style={(styles.containerColumn, { width: 430 })}>
              <Text style={styles.text2}>{observacion || "---"}</Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
}
