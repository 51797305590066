import React from "react";
import { View } from "@react-pdf/renderer";
import TotalesFA4 from "./TotalesFA4";
import { LineA4Color } from "../../Components/raya";
import FooterFacturaA4 from "../../DeVentas/Components/FooterFacturaA4";

export default function ResumenNCFacturaA4({ data, tipo, remito }) {
  return (
    <>
      {!remito && (
        <TotalesFA4
          fixed={tipo !== "ncA4Ahorro" ? true : false}
          factura={data.factura}
          vat={data.nota_credito.datos_afip.iva_resumen}
          receipt={data.nota_credito}
        />
      )}

      <View
        style={
          tipo !== "ncA4Ahorro"
            ? {
                position: "absolute",
                bottom: 100,
                paddingLeft: 20,
                paddingRight: 20,
              }
            : { paddingTop: 5 }
        }
        fixed
      >
        {data.nota_credito.datos_afip.validation &&
          data.nota_credito.datos_afip.validation.cae && <LineA4Color />}
      </View>
      {data.nota_credito.datos_afip.validation &&
        data.nota_credito.datos_afip.validation.cae && (
          <FooterFacturaA4
            fixed={tipo !== "ncA4Ahorro" ? true : false}
            factura={data.empresa}
            validation={data.nota_credito.datos_afip.validation}
            qr={data.codigo_QR}
          />
        )}
    </>
  );
}
