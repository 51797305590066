import React from "react";
import { View, Text } from "@react-pdf/renderer";
import { styles } from "../../assets/css/styleA4";
import { parseCurrency } from "../../../../../utils/parsers";
import { getTotalImpactado } from "./util";

export default function TotalesPagos({ pagos, dataRetencion, data }) {
  const montoDeuda = getTotalImpactado(data);
  const montoRetencion = dataRetencion
    ? Number(dataRetencion[0].monto_retencion)
    : 0;
  const montoTotalAPagar = Number((montoDeuda - montoRetencion).toFixed(2));
  const montoSobrante = data.sobrante ? Number(data.sobrante.toFixed(2)) : 0;

  // const handleCalcularTotalPagos = () => {
  //   let totalPagos = 0;

  //   pagos.forEach((pago) => {
  //     totalPagos = totalPagos + Number(pago.monto_medio_pago);
  //   });
  //   let totalPagosFixed = totalPagos.toFixed(2);
  //   return Number(totalPagosFixed);
  // };

  return (
    <View style={styles.containerRowTableEnd}>
      <View style={(styles.containerColumn, { width: 200 })}>
        <Text style={styles.textTotalesPagoCompra}>IMPORTE TOTAL:</Text>
        {dataRetencion && (
          <Text style={styles.textTotalesPagoCompra}>
            RETENCIÓN N830/2000 - COD 86 :
          </Text>
        )}
        {dataRetencion && (
          <Text style={styles.textTotalesPagoCompra}>IMPORTE A PAGAR:</Text>
        )}
        <Text style={styles.textTotalesPagoCompra}>MONTO PAGADO:</Text>
        {data.sobrante && (
          <Text style={styles.textTotalesPagoCompra}>SOBRANTE:</Text>
        )}
      </View>
      <View style={(styles.containerColumn, { width: 75 })}>
        <Text style={styles.textTotalesPagoCompra}>
          {parseCurrency(montoDeuda)}
        </Text>
        {dataRetencion && (
          <Text style={styles.textTotalesPagoCompra}>
            {parseCurrency(montoRetencion)}
          </Text>
        )}
        {dataRetencion && (
          <Text style={styles.textTotalesPagoCompra}>
            {parseCurrency(montoTotalAPagar)}
          </Text>
        )}
        <Text style={styles.textTotalesPagoCompra}>
          {parseCurrency(montoDeuda)}
        </Text>

        {data.sobrante && (
          <Text style={styles.textTotalesPagoCompra}>
            {parseCurrency(montoSobrante)}
          </Text>
        )}
      </View>
    </View>
  );
}
