import request from "../../../requests/request";

export default async function getLogo() {
  let logoBase64 = "";
  try {
    const response = await request({
      method: "GET",
      url: "/sucursal/logo/",
    });
    logoBase64 = response;
  } catch (error) {
    console.error(error);
  }
  return logoBase64;
}

/**
 * Rellena un número con ceros a la izquierda, si es necesario, para que
 * tenga una longitud especificada. Si el número ya tiene la longitud
 * determinada.
 * @param {number} number - Número a rellenar
 * @param {number} len - Longitud final del número
 * @returns {string} - Numero rellenado con ceros
 */
export const fill = (number, len) => number.toString().padStart(len, "0");

/**
 * Determina si el tipo de comprobante es un presupuesto A4
 * @param {string} tipo - Tipo de comprobante
 * @returns {boolean} - true si es un presupuesto A4, false en caso contrario
 */
export const isPresupuestoA4 = (tipo) => tipo === "presupuestoA4";
