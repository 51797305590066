import { Card, CardContent, Collapse, Grid } from "@material-ui/core";
import React from "react";
import ButtonFilter from "../../../../components/ait-reusable/Button/ButtonFilter";
import { useClientFilterStore } from "../../store/useClientFilterStore";
import {
  FilterButtons,
  FilterClientType,
  FilterCtaCte,
  FilterOrder,
  FilterPayment,
  FilterStatus,
  FilterTransport,
} from ".";

export default function Filters({ getClients, resetClient }) {
  const { filters, setFilter, openFilters, setOpenFilters, reset } =
    useClientFilterStore();

  const { orderBy, ctaCte, tipoCliente, tipoDeCliente, activos, transporte } =
    filters;

  return (
    <>
      <ButtonFilter click={() => setOpenFilters(!openFilters)} />
      <Grid container style={{ marginTop: "0.5rem" }}>
        <Grid item xs={12}>
          <Collapse in={openFilters}>
            <Card
              id={"periodo"}
              style={{
                boxShadow: "none",
                width: "100%",
                padding: 5,
              }}
            >
              <CardContent className={"p-1"} style={{ padding: 0 }}>
                <Grid item container spacing={2}>
                  <FilterOrder filter={orderBy} setFilter={setFilter} />

                  <FilterCtaCte filter={ctaCte} setFilter={setFilter} />

                  <FilterClientType
                    filter={tipoDeCliente}
                    setFilter={setFilter}
                  />

                  <FilterStatus filter={activos} setFilter={setFilter} />

                  <FilterPayment filter={tipoCliente} setFilter={setFilter} />

                  <FilterTransport filter={transporte} setFilter={setFilter} />

                  <FilterButtons
                    getClients={getClients}
                    reset={reset}
                    resetClient={resetClient}
                  />
                </Grid>
              </CardContent>
            </Card>
          </Collapse>
        </Grid>
      </Grid>
    </>
  );
}
