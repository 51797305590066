import React from "react";
import { Grid, MenuItem, TextField } from "@material-ui/core";

export default function FilterStatus({ filter, setFilter }) {
  return (
    <Grid item xs={12} sm={12} md={2} lg={2}>
      <TextField
        style={{ marginTop: 5 }}
        size="small"
        fullWidth
        select
        label="Estado"
        name="activos"
        variant="outlined"
        value={filter}
        onChange={(e) => setFilter("activos", e.target.value)}
      >
        <MenuItem value={1}>Activos</MenuItem>
        <MenuItem value={0}>Inactivos</MenuItem>
      </TextField>
    </Grid>
  );
}
