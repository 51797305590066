import React from "react";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import useGetTransportes from "../../../customHooks/useGetTransportes";

export default function AutocompleteTransporteFiltro({
  transporteSelected,
  setTransporteSelected,
}) {
  const { useTransporte } = useGetTransportes({ activeOnly: true });

  return (
    <Autocomplete
      loading={useTransporte.loading}
      value={transporteSelected}
      options={useTransporte.listTransportes}
      filterOptions={(options) => options}
      getOptionLabel={(transporte) => transporte.nombre}
      inputValue={useTransporte.searchTransporte || ""}
      onInputChange={(_, value) => {
        useTransporte.setSearchTransporte(value);
      }}
      onChange={(_, value) => setTransporteSelected(value)}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Transporte"
          variant="outlined"
          fullWidth
          InputProps={{
            ...params.InputProps,
            style: { backgroundColor: "white" },
          }}
        />
      )}
      name="buscador_transportes"
      size="small"
      noOptionsText="No se encontraron transportes"
    />
  );
}
