import React from "react";
import { View, Text } from "@react-pdf/renderer";
import { styles } from "../../assets/css/styleTicket";

export default function ArticulosComprobTicket({ data, remito }) {
  const handleRenderDescripcion = (detalle) => {
    if (detalle.descripcionPersonalizada) {
      return (
        detalle.descripcionPersonalizada.substr(0, 30).trim() +
        (detalle.descripcionPersonalizada.length > 30 ? "..." : "")
      );
    }
    return (
      detalle.descripcion_ticket.substr(0, 30).trim() +
      (detalle.descripcion_ticket.length > 30 ? "..." : "")
    );
  };

  return (
    data.detalle_ventas.length > 0 &&
    data.detalle_ventas.map((detalle, index) => (
      <View key={index} style={styles.containerRowTable}>
        <View style={styles.containerColumn}>
          <Text style={styles.textDescriptionItem}>
            {handleRenderDescripcion(detalle)}
          </Text>
          <Text style={styles.text}>{`${detalle.cantidad.toFixed(4)} x $ ${
            !remito
              ? Number(
                  (Number(detalle.subtotal) / detalle.cantidad).toFixed(4),
                ).toLocaleString("es-AR")
              : "---"
          }`}</Text>
        </View>
        <View style={styles.containerColumn}>
          <Text style={styles.text}>{`$ ${
            !remito
              ? Number(Number(detalle.subtotal).toFixed(2)).toLocaleString(
                  "es-AR",
                )
              : "---"
          }`}</Text>
        </View>
      </View>
    ))
  );
}
