import { Grid, MenuItem, TextField } from "@material-ui/core";
import React from "react";
import TooltipMoreInfoMaterial from "../../../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";
import { TextTooltipTipoCliente } from "../tooltips";

export default function FilterPayment({ filter, setFilter }) {
  const options = [
    { value: "todos", label: "Todos" },
    { value: "comision", label: "Con comisión" },
    { value: "descuento", label: "Con descuentos por plazo" },
    {
      value: "sin_configuracion",
      label: "Sin configuración",
    },
  ];

  return (
    <Grid item xs={12} sm={12} md={2} lg={2}>
      <Grid container>
        <Grid item xs={11}>
          <TextField
            style={{ marginTop: 5 }}
            size="small"
            fullWidth
            select
            label={"Condición de cobro"}
            variant="outlined"
            name="tipoCliente"
            value={filter}
            onChange={(e) => setFilter("tipoCliente", e.target.value)}
          >
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={1}>
          <div style={{ paddingTop: 10 }}>
            <TooltipMoreInfoMaterial
              titleTooltip={
                <h6
                  style={{
                    fontSize: 15,
                    marginBottom: 0,
                    marginTop: 0,
                  }}
                >
                  <TextTooltipTipoCliente />
                </h6>
              }
              color="#000"
              position={"top"}
            />
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}
