import React from "react";
import { View, Text } from "@react-pdf/renderer";
import { styles } from "../../assets/css/styleTicket";
import { parseDate } from "../../../../../utils/parsers";

export default function DataFacturaTicket({ data, remito }) {
  return (
    <View>
      <View style={styles.containerRow}>
        <View style={(styles.containerColumn, { width: 100 })}>
          <Text style={styles.textbold}>
            {!remito
              ? data.receipt.receipt_type.description +
                `${data.receipt.receipt_number ? "" : "| Validacion pendiente"}`
              : "Remito"}
          </Text>
        </View>

        <View style={(styles.containerColumn2, { width: 100 })}>
          <Text
            style={{
              paddingBottom: 3,
              fontSize: 8,
              textAlign: "right",
              paddingRight: 11,
            }}
          >
            {data.factura.nroFactura}
          </Text>
          <Text style={styles.text}>{`${data.nroFactura || ""}`}</Text>

          <Text style={styles.text}>{`Fecha: ${parseDate(
            data.factura.fechaHoraFactura,
            "DD/MM/YYYY HH:mm",
          )}`}</Text>
          {data.venta.tipo_comprobante === "Factura" && (
            <Text
              style={styles.text}
            >{`Fecha vto: ${data.factura.fecha_vencimiento ? parseDate(data.factura.fecha_vencimiento, "DD/MM/YYYY HH:mm") : "N/A"}`}</Text>
          )}
        </View>
      </View>

      <View>
        <Text style={{ marginLeft: 10, marginRight: 10 }}>
          --------------------------------
        </Text>
      </View>
    </View>
  );
}
