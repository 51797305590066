import React from "react";
import { Grid } from "@material-ui/core";

export default function TextTooltipTipoCliente() {
  return (
    <Grid container style={{ paddingTop: 8 }}>
      <Grid item xs={12} style={{ textAlign: "initial" }} className="mb-0">
        <label
          className="fontBold m-0"
          style={{ fontSize: 15, color: "black" }}
        >
          Con comisión
        </label>
        <label className="pl-3 m-0" style={{ fontSize: 15, color: "black" }}>
          Cliente que tiene activado la opción de comisión
        </label>
      </Grid>

      <Grid item xs={12} style={{ textAlign: "initial" }} className="mt-0">
        <label
          className="fontBold m-0"
          style={{ fontSize: 15, color: "black" }}
        >
          Con descuento por plazo
        </label>
        <label className="pl-3 m-0" style={{ fontSize: 15, color: "black" }}>
          Cliente que no tiene activado la opción de comisión y tiene recargos
          por plazo activos
        </label>
      </Grid>
      <Grid item xs={12} style={{ textAlign: "initial" }} className="mt-0">
        <label
          className="fontBold m-0"
          style={{ fontSize: 15, color: "black" }}
        >
          Sin configuración
        </label>
        <label className="pl-3 m-0" style={{ fontSize: 15, color: "black" }}>
          Clientes que no son ni con comisión ni con descuento por plazo
        </label>
      </Grid>
    </Grid>
  );
}
