import React, { useState, useEffect } from "react";
import {
  Paper,
  Typography,
  TextField,
  MenuItem,
  Button,
  Chip,
  Grid,
  IconButton,
} from "@material-ui/core";
import { changeDescripcionMasivo } from "../../utils";
import {
  successNotification,
  errorNotification,
} from "../../../../../components/Notifications";
import { getRubrosService } from "../../../../Articulos/Services";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { updateRubroService } from "../../Services";
import AddIcon from "@material-ui/icons/Add";
import ModalRubro from "../../../../Articulos/Modales/ModalRubro";

export default function SeleccionArticulos({
  filtro,
  newDescription,
  setOpen,
  articulos,
  handleDelete,
  update,
  columnas,
  type,
}) {
  const [rubros, setRubros] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalRubro, setModalRubro] = useState(false);
  const [rubroSelected, setRubroSelected] = useState(null);
  const [codigosSelected, setCodigosSelected] = useState(null); //Columnas seleccionadas en configuración general
  const [entidadModalRubro, setEntidadModalRubro] = useState(null);

  const opcionSinRubro = { id: null, nombre: "Sin rubro" };
  const esRubrooSubrubro = type === "rubro" || type === "subrubro";

  const getOptionsCodigos = () => {
    return codigosSelected.map((item) => {
      return (
        <MenuItem key={item.id} value={item.id}>
          Sólo Cod. {item.nombre}
        </MenuItem>
      );
    });
  };

  const getMoreOptions = () => {
    if (codigosSelected.length === 2) {
      return (
        <MenuItem key={2} value={2}>
          Cod. Artículo + Cod. Original
        </MenuItem>
      );
    }
  };

  const handleChangeDescription = (e) => {
    newDescription.setDescription(e.target.value);
  };

  const name = (x) => {
    return x.idRepuestoProveedor;
  };

  // Services
  const handleSubmit = () => {
    switch (type) {
      case "rubro":
        handleChangeRubro();
        break;
      case "subrubro":
        handleChangeRubro();
        break;
      default:
        changeDescription();
        break;
    }
  };

  const changeDescription = async () => {
    setLoading(true);
    let array = await articulos.map((x) => ({
      idRepuestoProveedor: x.idRepuestoProveedor,
    }));
    if (array.length > 0) {
      await changeDescripcionMasivo(
        array,
        newDescription.description,
        type,
      ).then((res) => {
        if (res === 200) {
          successNotification(
            "La descripción de los artículos seleccionados se han actualizado con éxito.",
          );
          setLoading(false);
          update();
        } else {
          setLoading(false);
          errorNotification(
            "Ha ocurrido un error y no se ha actualizado la descripción de los artículos seleccionados.",
          );
        }
      });
    }
  };

  const handleChangeRubro = async () => {
    setLoading(true);
    try {
      const esSubRubro = type === "rubro" ? false : true;
      const articulosFormateados = await articulos.map((x) => ({
        idRepuestoProveedor: x.idRepuestoProveedor,
      }));

      await updateRubroService(
        articulosFormateados,
        rubroSelected.id,
        esSubRubro,
      );
      setRubroSelected(null);
      successNotification(
        "Los artículos seleccionados se han actualizado con éxito.",
      );

      await handleGetRubros();
      update();
    } catch (error) {
      console.log(error);
      errorNotification(
        "Ha ocurrido un error al actualizar los artículos seleccionados.",
      );
    }
    setLoading(false);
  };

  const handleGetRubros = async () => {
    try {
      const response = await getRubrosService();
      setRubros(response);
    } catch (error) {
      console.log(error);
    }
  };

  // Utils
  const handleRenderInput = () => {
    switch (type) {
      case "rubro":
        return (
          <Autocomplete
            getOptionLabel={(x) => String(x.nombre)}
            options={[opcionSinRubro, ...handleFilterRubros(rubros, false)]}
            value={rubroSelected}
            onChange={(event, newValue) => setRubroSelected(newValue)}
            size="small"
            renderInput={(params) => (
              <TextField {...params} label="Nuevo rubro" variant="outlined" />
            )}
            noOptionsText="No existen rubros"
          />
        );
      case "subrubro":
        return (
          <Autocomplete
            getOptionLabel={(x) => String(x.nombre)}
            options={[opcionSinRubro, ...handleFilterRubros(rubros, true)]}
            value={rubroSelected}
            onChange={(event, newValue) => setRubroSelected(newValue)}
            size="small"
            renderInput={(params) => (
              <TextField
                {...params}
                label="Nuevo subrubro"
                variant="outlined"
              />
            )}
            noOptionsText="No existen subrubros"
          />
        );
      default:
        return (
          <TextField
            fullWidth
            id="description"
            label={
              type === "description"
                ? "Nueva descripción"
                : "Nuevo Cod Auxiliar"
            }
            size="small"
            multiline
            // value={newDescription.description}
            onChange={handleChangeDescription}
            inputProps={{ maxLength: 100 }}
            variant="outlined"
          />
        );
    }
  };

  const handleIsButtonDisabled = () => {
    if (loading) return true;

    switch (type) {
      case "rubro":
        return !rubroSelected;
      case "subrubro":
        return !rubroSelected;
      default:
        return !Boolean(newDescription.description.trim()) || loading;
    }
  };

  const handleFilterRubros = (rubros, subRurbo) => {
    if (!rubros.length) {
      return [];
    }

    return rubros.filter((rubro) => rubro.es_sub_rubro === subRurbo);
  };

  // Other
  const getOtherOption = () => {
    return codigosSelected[0].nombre === "Original" ? "Artículo" : "Original";
  };

  // Rubros
  const handleOpenModalRubro = (entidad) => {
    setEntidadModalRubro(entidad);
    setModalRubro(true);
  };

  const handleCloseModalRubro = () => {
    setModalRubro(false);
    setEntidadModalRubro(null);
  };

  const handleSelectRubroCreado = async (rubro) => {
    setRubroSelected(rubro);
    await handleGetRubros();
  };

  useEffect(() => {
    if (type === "rubro" || type === "subrubro") {
      handleGetRubros();
    }
  }, []);

  useEffect(() => {
    let select = [];

    columnas.map((x) => {
      if (x.nombre === "Original" || x.nombre === "Artículo") {
        if (x.es_visible) {
          let newId = x.nombre === "Original" ? 0 : 1;
          select.push({ id: newId, nombre: x.nombre });
        }
      }
    });

    setCodigosSelected(select.reverse());
  }, []);

  return (
    <div style={{ marginTop: 5 }}>
      <Paper elevation={1} style={{ padding: 10 }}>
        {articulos.length === 0 && (
          <Typography style={{ marginBottom: 10 }} variant="body2">
            Los artículos que selecciones empezarán a aparecer aquí.
          </Typography>
        )}
        {articulos.map((x) => (
          <Chip
            style={{ margin: 1 }}
            variant="outlined"
            size="small"
            color="primary"
            onDelete={() => handleDelete(x)}
            label={name(x)}
          />
        ))}
        {articulos.length > 0 && (
          <Grid
            container
            style={{ marginTop: 10, maxWidth: 360 }}
            display="flex"
            alignItems="center"
          >
            <Grid item xs={esRubrooSubrubro ? 10 : 12}>
              {handleRenderInput()}
            </Grid>
            {esRubrooSubrubro ? (
              <Grid item xs={2} md={1}>
                <IconButton
                  color="primary"
                  title="Agregar"
                  onClick={() => handleOpenModalRubro(type)}
                >
                  <AddIcon />
                </IconButton>
              </Grid>
            ) : null}
            <Grid xs={12} style={{ marginTop: 5, textAlign: "end" }}>
              <Button
                color="primary"
                style={{ outline: "none" }}
                disabled={handleIsButtonDisabled()}
                variant="contained"
                onClick={handleSubmit}
              >
                guardar
              </Button>
            </Grid>
          </Grid>
        )}
      </Paper>
      {modalRubro ? (
        <ModalRubro
          open={modalRubro}
          entidad={entidadModalRubro}
          close={handleCloseModalRubro}
          handleGetRubros={handleGetRubros}
          onSubmit={handleSelectRubroCreado}
        />
      ) : null}
    </div>
  );
}
