import React from "react";
import { Document, Page, View, Text } from "@react-pdf/renderer";
import { styles } from "../assets/css/styleTicket";
import HeaderTicket from "./Components/HeaderTicket";
import ArticulosComprobTicket from "./Components/ArticulosComprobTicket";
import TotalesComprobTicket from "./Components/TotalesComprobTicket";

export default function TicketCI({ data, config, remito }) {
  return (
    <Document title={remito ? "Remito" : "Comprobante Interno"}>
      <Page style={styles.page} size={{ width: 226.77, minHeight: 400 }}>
        <View>
          <HeaderTicket
            logoEmpresa={data.logo}
            data={data}
            config={config}
            remito={remito}
          />
          <View style={styles.containerRow}>
            <View style={styles.containerColumn}>
              <Text style={styles.textbold}>Descripción</Text>
              <Text style={styles.textbold}>Cant/Precio Unit.</Text>
            </View>
            <View style={styles.containerColumn2}>
              <Text style={styles.textbold}>IMPORTE</Text>
            </View>
          </View>
          <View>
            <Text style={{ marginLeft: 10, marginRight: 10 }}>
              --------------------------------
            </Text>
          </View>
          <ArticulosComprobTicket data={data.venta} remito={remito} />
          <View>
            <Text style={{ marginLeft: 10, marginRight: 10 }}>
              --------------------------------
            </Text>
          </View>

          <TotalesComprobTicket
            venta={data.venta}
            dcto={data.descuento}
            recargo={data.recargo}
            plazos={data.cc_plazos}
            montoTotal={data.venta.montoTotal}
            remito={remito}
            config={config}
            porcentajeDescuento={data.porcentaje_descuento}
          />
        </View>
      </Page>
    </Document>
  );
}
