import React from "react";
import { Text, View } from "@react-pdf/renderer";
import { styles } from "../assets/css/styleA4";
import FirmaBoxer from "./firmaBoxer";

export default function FooterA4({ sucursal, fixed }) {
  const { razonSocial, domicilio, correoElectronico, telefonoContacto } =
    sucursal;

  return (
    <View
      style={
        fixed
          ? {
              position: "absolute",
              bottom: 50,
              paddingLeft: 20,
              paddingRight: 30,
            }
          : ""
      }
      fixed={fixed}
    >
      <View style={styles.containerRow}>
        <View style={(styles.containerColumn, { marginLeft: 2, width: 300 })}>
          <Text
            style={{
              paddingBottom: 1,
              fontSize: 10,
              textAlign: "justify",
              color: "#1B71BA",
              textTransform: "uppercase",
            }}
          >
            {razonSocial}
          </Text>
          <Text style={styles.text}>{domicilio}</Text>
          <Text style={styles.text}>{`Email: ${
            correoElectronico || "---"
          }`}</Text>
          <Text style={styles.text}>{`TE: ${telefonoContacto || "---"}`}</Text>
        </View>
        <View
          style={
            (styles.containerColumnRight,
            { width: 225, alignItems: "flex-end" })
          }
        >
          <FirmaBoxer />
        </View>
      </View>
    </View>
  );
}
