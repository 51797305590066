import React from "react";
import SearchIcon from "@material-ui/icons/Search";
import FiltroBuscador from "./FiltroBuscador";
import FiltroStock from "./FiltroStock";
import FiltroPrecioCosto from "./FiltroPrecioCosto";
import { Box, Divider, Grid, InputAdornment } from "@material-ui/core";
import TooltipMoreInfoMaterial from "../../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";
import TextFieldAutocompleteArticulo from "../../../components/ait-reusable/TextFieldAutocompleteArticulo";

const HeaderCatalogo = (props) => {
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item lg={6} md={12} xs={12}>
          <TextFieldAutocompleteArticulo
            showBorder
            border={`2px solid ${props.handleGetColorBySelectedFilter()}`}
            name="busqueda"
            // style={{ fontSize: "15px" }}
            variant="outlined"
            fullWidth
            size="small"
            placeholder={"Buscar por código o descripción"}
            onChange={(event) => props.onScearchArticulos(event)}
            value={props.busquedaArticulo}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <>
                  <Divider orientation="vertical" style={{ height: "40px" }} />
                  <Box
                    justifyContent={"center"}
                    style={{ paddingLeft: "10px" }}
                  >
                    <TooltipMoreInfoMaterial
                      titleTooltip={
                        <h6
                          style={{
                            fontSize: 15,
                            marginBottom: 0,
                            marginTop: 0,
                          }}
                        >
                          Puede combinar palabras claves. Ejemplo: descripción +
                          proveedor, código original + descripción, etc.
                        </h6>
                      }
                      color="#000"
                      position={"top"}
                    />
                  </Box>
                </>
              ),
            }}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <FiltroBuscador
            listfilters={props.listfilters}
            filter={props.filter.filter}
            setFilter={props.filter.setter}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <div>
            <FiltroStock
              showBorder
              border={`2px solid ${props.handleGetColorBySelectedFilter()}`}
              filter={
                !props.checks.checkedArt &&
                !props.checks.sinStock &&
                !props.checks.soloStock &&
                !props.checks.tieneMeli
                  ? 1
                  : props.checks.checkedArt &&
                      !props.checks.soloStock &&
                      !props.checks.sinStock &&
                      !props.checks.tieneMeli
                    ? 2
                    : props.checks.checkedArt &&
                        props.checks.soloStock &&
                        !props.checks.sinStock &&
                        !props.checks.tieneMeli
                      ? 3
                      : props.checks.checkedArt &&
                          !props.checks.soloStock &&
                          props.checks.sinStock &&
                          !props.checks.tieneMeli
                        ? 4
                        : !props.checks.checkedArt &&
                            !props.checks.sinStock &&
                            !props.checks.soloStock &&
                            props.checks.tieneMeli
                          ? 5
                          : 1
              }
              setFilter={(value) => {
                localStorage.setItem("filtroMostrar", value);
                switch (value) {
                  case 1:
                    props.handleChange.handleChangeArt(true);
                    break;
                  case 2:
                    props.handleChange.handleChangeTodosMisArt(true);
                    break;
                  case 3:
                    props.handleChange.handleChangeSoloStock(true);
                    break;
                  case 4:
                    props.handleChange.handleChangeSinStock(true);
                    break;
                  case 5:
                    props.handleChange.handleChangeConVinculacionMeli(true);
                    break;
                  default:
                    break;
                }
              }}
            />
          </div>
        </Grid>
        <Grid item lg={6} md={12} sm={12} xs={12}>
          <FiltroPrecioCosto
            ordenarPor={props.ordenarPor}
            setOrdenarPor={props.setOrdenarPor}
            ordenProductos={props.ordenProductos}
            setOrdenProductos={props.setOrdenProductos}
          />
        </Grid>
        {/* <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ paddingLeft: "2em" }}
        >
          <div style={{ display: "grid", justifyContent: "end" }}>
            <AddNew
              title={"NUEVO ARTÍCULO"}
              pathname={"/articulos/nuevo/"}
              history={props.history}
              icon="add_circle_outline"
            />
          </div>
        </Grid> */}
      </Grid>
    </div>
  );
};

export default HeaderCatalogo;
