import React from "react";
import FooterA4 from "../../../Components/FooterA4";
import { styles } from "../../../assets/css/styleA4";
import { Text, View } from "@react-pdf/renderer";
import { LineA4Color } from "../../../Components/raya";

export default function ResumenCIA4({ data, tipo, config }) {
  const fixed = tipo === "remitoA4" ? true : false;

  return (
    <>
      {!config.ocultar_datos_ci ? (
        tipo === "remitoA4" ? (
          <View
            style={{
              position: "absolute",
              bottom: 100,
              paddingLeft: 20,
              paddingRight: 20,
              left: 0,
              right: 0,
            }}
            fixed
          >
            <LineA4Color />
          </View>
        ) : (
          <View
            style={{
              paddingTop: 5,
            }}
            fixed
          >
            <LineA4Color />
          </View>
        )
      ) : null}
      {config.activar_leyenda && data.remito.leyenda.length ? (
        <View
          style={
            fixed
              ? {
                  position: "absolute",
                  bottom: config.ocultar_datos_ci ? 40 : 110,
                  paddingLeft: 30,
                  paddingRight: 20,
                  width: "60%",
                }
              : {
                  width: "60%",
                }
          }
          fixed={fixed}
        >
          <Text style={styles.textCenterLeyenda}>
            {data.remito.leyenda[0].descripcion}
          </Text>
        </View>
      ) : null}
      {!config.ocultar_datos_ci ? (
        <FooterA4
          fixed={tipo === "remitoA4" ? true : false}
          sucursal={data.sucursal}
        />
      ) : null}
    </>
  );
}
