import React from "react";
import CheckIcon from "@material-ui/icons/Check";
import TooltipWithoutIcon from "../../../../components/ait-reusable/TooltipMoreInfo/TooltipWithoutIcon";

export default function TooltipCtaCte() {
  return (
    <TooltipWithoutIcon
      body={<h6 style={{ fontSize: 15 }}>Cliente con cuenta corriente</h6>}
      placement={"right"}
    >
      <CheckIcon
        color="success"
        style={{ color: "green", paddingBottom: "0.2em" }}
        fontSize="medium"
      />
    </TooltipWithoutIcon>
  );
}
