import { View, Text, StyleSheet } from "@react-pdf/renderer";
import React from "react";
import { styles } from "../../assets/css/styleA4";
import {
  A_DATOS_AGENTE_RETENCION,
  APELLIDO_NOMBRE_DENOMICACION_TITULO,
  B_DATOS_SUJETO_RETENIDO,
  CUIT_TITULO,
  DOMICILIO_TITULO,
} from "../Constants/constants";

export const DatosAgenteSujeto = ({ sujeto, nombre, cuit, domicilio }) => {
  const stylesPdf = StyleSheet.create({
    fontBoldTitle: {
      fontFamily: "Times-Bold",
      fontWeight: "bold",
      fontSize: 18,
    },
    fontBold: {
      fontFamily: "Times-Bold",
      fontWeight: "bold",
      fontSize: 13,
    },
  });

  return (
    <View style={{ paddingTop: 20 }}>
      {!sujeto ? (
        <Text style={stylesPdf.fontBold}>{A_DATOS_AGENTE_RETENCION}</Text>
      ) : (
        <Text style={stylesPdf.fontBold}>{B_DATOS_SUJETO_RETENIDO}</Text>
      )}

      <View style={[styles.containerRowRetencion, { paddingTop: 15 }]} fixed>
        <Text style={stylesPdf.fontBold}>
          {APELLIDO_NOMBRE_DENOMICACION_TITULO}
        </Text>
        <Text style={{ fontSize: 12 }}>{nombre || ""}</Text>
      </View>

      <View style={styles.containerRowRetencion} fixed>
        <Text style={stylesPdf.fontBold}>{CUIT_TITULO}</Text>
        <Text style={{ fontSize: 12 }}>{cuit || ""}</Text>
      </View>

      <View style={[styles.containerRowRetencion, { paddingBottom: 20 }]} fixed>
        <Text style={stylesPdf.fontBold}>{DOMICILIO_TITULO}</Text>
        <Text style={{ fontSize: 12 }}>{domicilio || ""}</Text>
      </View>
    </View>
  );
};
