import { Chip, Grid } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import moment from "moment";
import React from "react";
import DetalleMediosPago from "../../DetalleMediosPago";

export default function DetalleVenta({ selected }) {
  const {
    cliente,
    id_desc_total,
    id_pagos_tarjeta,
    pagos_cliente,
    provincia,
    responsable_venta,
    fechaHora,
    tipo_comprobante,
    is_agrupada,
    observacion_oculta,
    transporte_venta,
  } = selected;
  const isFCEMA =
    selected.tipo_comprobante === "FCEM A" ||
    selected.tipo_comprobante.nombre === "FCEM A";
  return (
    <Grid container spacing={2}>
      <Grid item xs={6} lg={6} style={{ paddingBottom: 0 }}>
        <label htmlFor="">
          <span className="fontBold">Cliente: </span>
          {cliente && cliente.razonSocial
            ? cliente.razonSocial.toUpperCase()
            : "Consumidor final"}
        </label>
      </Grid>
      <Grid item xs={6} lg={6} style={{ paddingBottom: 0 }}>
        <label htmlFor="">
          <span className="fontBold">Fecha de registro: </span>
          {moment(fechaHora).format("DD/MM HH:mm")}
        </label>
      </Grid>

      <Grid item xs={6} lg={6} style={{ paddingBottom: 0, paddingTop: 0 }}>
        <label htmlFor="">
          <span className="fontBold">Aplica descuento: </span>
          {id_desc_total
            ? (Number(id_desc_total.porcentaje) * 100).toFixed(2)
            : "---"}{" "}
          %
        </label>
      </Grid>
      <Grid item xs={6} lg={6} style={{ paddingBottom: 0, paddingTop: 0 }}>
        <label htmlFor="">
          <span className="fontBold">Provincia: </span>
          {provincia
            ? provincia
            : cliente
              ? cliente.provincia
              : "SIN ESPECIFICAR"}
        </label>
      </Grid>

      <Grid item xs={6} lg={6} style={{ paddingBottom: 0, paddingTop: 0 }}>
        <label htmlFor="">
          <span className="fontBold">Responsable: </span>
          {responsable_venta
            ? responsable_venta.nombre + " " + responsable_venta.apellido
            : "---"}
        </label>
      </Grid>
      <Grid item xs={6} lg={6} style={{ paddingBottom: 0, paddingTop: 0 }}>
        <label htmlFor="">
          <span className="fontBold">TR: </span> {tipo_comprobante.nombre}{" "}
          {is_agrupada && (
            <Chip
              variant="outlined"
              color="primary"
              size="small"
              label={"Factura agrupada"}
              style={{
                backgroundColor: "white",
                color: "#3f51b5",
              }}
            />
          )}
        </label>
      </Grid>
      {isFCEMA ? (
        <Grid item xs={6} lg={6} style={{ paddingBottom: 0, paddingTop: 0 }}>
          <label htmlFor="">
            <span className="fontBold">CBU: </span>
            {selected.receipt.optionals[0]
              ? selected.receipt.optionals[0].value
              : "--"}
          </label>
        </Grid>
      ) : null}
      {isFCEMA ? (
        <Grid item xs={6} lg={6} style={{ paddingBottom: 0, paddingTop: 0 }}>
          <label htmlFor="">
            <span className="fontBold">Alias: </span>
            {selected.receipt.optionals[1]
              ? selected.receipt.optionals[1].value
              : "--"}
          </label>
        </Grid>
      ) : null}
      {isFCEMA ? (
        <Grid item xs={6} lg={6} style={{ paddingBottom: 0, paddingTop: 0 }}>
          <label htmlFor="">
            <span className="fontBold">Opción Transferencia: </span>
            {selected.receipt.optionals[2]
              ? selected.receipt.optionals[2].value
              : "--"}
          </label>
        </Grid>
      ) : null}
      {isFCEMA ? (
        <Grid item xs={6} lg={6} style={{ paddingBottom: 0, paddingTop: 0 }}>
          <label htmlFor="">
            <span className="fontBold">Fecha Vencimiento del Pago: </span>
            {selected.receipt.expiration_date || "--"}
          </label>
        </Grid>
      ) : null}

      <Grid item xs={6} lg={6} style={{ paddingBottom: 0, paddingTop: 0 }}>
        <label htmlFor="">
          <span className="fontBold">Tipo de venta:</span>{" "}
          {selected && selected.tipo_venta
            ? selected.tipo_venta.descripcion
            : "--"}
        </label>
      </Grid>

      <Grid item xs={6} lg={6} style={{ paddingBottom: 0, paddingTop: 0 }}>
        <label htmlFor="">
          <span className="fontBold">Transporte:</span>{" "}
          {transporte_venta ? transporte_venta.nombre : "--"}
        </label>
      </Grid>

      <Grid item xs={6} lg={6} style={{ paddingBottom: 0, paddingTop: 0 }}>
        <label htmlFor="">
          <span className="fontBold">Medio de pago: </span>
          {pagos_cliente.map((medio, index) => {
            return (
              <Grid container spacing={2} key={index}>
                <Grid item xs={12} lg={12} style={{ paddingTop: 0 }}>
                  <DetalleMediosPago
                    medioSelected={medio}
                    selected={selected}
                  />
                </Grid>
              </Grid>
            );
          })}
        </label>
      </Grid>

      {id_pagos_tarjeta !== null && (
        <Grid container spacing={2}>
          <Grid item xs={3} lg={3}>
            <label htmlFor="">
              <span className="fontBold">Tarjeta: </span>
              {id_pagos_tarjeta && id_pagos_tarjeta.id_tarjeta.nombre}
            </label>
          </Grid>
          <Grid item xs={3} lg={3}>
            <label htmlFor="">
              <span className="fontBold">Nro. lote: </span>
              {id_pagos_tarjeta.numero_lote || "---"}
            </label>
          </Grid>
          <Grid item xs={3} lg={3}>
            <label htmlFor="">
              <span className="fontBold">Nro. lote: </span>
              {id_pagos_tarjeta.numero_autorizacion || "---"}
            </label>
          </Grid>
          <Grid item xs={3} lg={3}>
            <label htmlFor="">
              <span className="fontBold">Intereses: </span>
              {Number(id_pagos_tarjeta.porcentaje * 100).toFixed(2) || "---"} %
            </label>
          </Grid>
        </Grid>
      )}
      {observacion_oculta && (
        <Grid container spacing={2} style={{ padding: 8 }}>
          <Grid item xs={12} lg={12}>
            <Alert icon={false} severity="info">
              <label style={{ fontWeight: "bold", margin: 0 }}>
                Observación oculta:
              </label>
              {` ${observacion_oculta}`}
            </Alert>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
