import React from "react";
import { Page, View, Document } from "@react-pdf/renderer";
import JsBarcode from "jsbarcode";
import {
  styles,
  viewCodBarra,
  mostrarCodigo,
  mostrarLogoSucursal,
  mostrarNombreSucursal,
  mostrarPrecioArticulo,
  mostrarNombreProveedor,
  mostrarUbicacionArticulo,
  mostrarDescripcionArticulo,
  mostrarFechaImpresion,
  mostrarDivisorDeCodigos,
} from "../Reutilizables";

export default function EtiquetasA4Bulk({
  articulos,
  sucursal,
  filasArray,
  columnasArray,
  configGeneral,
  configuracionEtiqueta,
}) {
  const etiquetasPorPagina = filasArray.cant * columnasArray.cant;

  const etiquetas = articulos.flatMap((art) =>
    Array.from({ length: art.cantidadEtiquetas }, () => art),
  );

  const paginas = [];
  for (let i = 0; i < etiquetas.length; i += etiquetasPorPagina) {
    paginas.push(etiquetas.slice(i, i + etiquetasPorPagina));
  }

  const handleMostrarPrecioArticulo = (articulo) => {
    let precio;

    switch (configuracionEtiqueta.precioAMostrar) {
      case "venta":
        precio = articulo.precios.venta;
        break;
      case "costo":
        precio = articulo.precios.costo;
        break;
      case "lista":
        precio = articulo.precios.lista;
        break;
      default:
        break;
    }

    if (configuracionEtiqueta.cifrarPrecio) {
      precio = handleCifrarPrecio(precio);
    } else {
      precio = parseInt(precio).toLocaleString("ES-ar");
    }

    return precio;
  };

  const handleCifrarPrecio = (precio) => {
    const mapeo = {
      1: "E",
      2: "C",
      3: "L",
      4: "U",
      5: "Y",
      6: "I",
      7: "N",
      8: "T",
      9: "A",
      0: "R",
      ".": "/",
    };

    return precio
      .toString()
      .split("")
      .map((caracter) => mapeo[caracter] || caracter)
      .join("");
  };

  const handleGenerarCodigoBarras = (articulo) => {
    switch (configuracionEtiqueta.codigoBarrasDesde) {
      case "proveedor":
        return articulo.codProveedor || "000";
      case "original":
        return articulo.codOriginal || "000";
      case "auxiliar":
        return articulo.codAuxiliar || "000";
      default:
        return "---";
    }
  };

  const renderEtiqueta = (articulo) => {
    const codigoBarras = handleGenerarCodigoBarras(articulo);

    JsBarcode("#barcode", codigoBarras, {
      lineColor: "#000",
      width: 4,
      height: 80,
      displayValue: false,
    });

    const canvas = document.getElementById("barcode");
    const pngUrl = canvas.toDataURL("image/png");

    return (
      <View
        style={{
          width: `${100 / columnasArray.cant}%`,
          height: `${840.08 / filasArray.cant}`,
          padding: 5,
        }}
        key={articulo.id}
      >
        {configuracionEtiqueta.nombreNegocio &&
          mostrarNombreSucursal(
            sucursal.razonSocial,
            configuracionEtiqueta.tamanioTipografia,
          )}
        <View
          style={{
            width: "100%",
            display: "flex",
            marginTop: -10,
            marginBottom: -10,
            paddingTop: 3,
            paddingBottom: 3,
            gap: 10,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          {configuracionEtiqueta.logotipo &&
            mostrarLogoSucursal(configGeneral.url_imagen)}
          {codigoBarras && viewCodBarra(pngUrl, configuracionEtiqueta.logotipo)}
        </View>
        <View
          style={{
            marginBottom: 5,
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {configuracionEtiqueta.codigoProveedor &&
            mostrarCodigo(
              articulo.codProveedor,
              configuracionEtiqueta.tamanioTipografia,
            )}

          {configuracionEtiqueta.codigoProveedor &&
          articulo.codProveedor &&
          configuracionEtiqueta.codigoOriginal &&
          articulo.codOriginal
            ? mostrarDivisorDeCodigos()
            : null}

          {configuracionEtiqueta.codigoOriginal &&
            mostrarCodigo(
              articulo.codOriginal,
              configuracionEtiqueta.tamanioTipografia,
            )}

          {configuracionEtiqueta.codigoOriginal &&
          articulo.codOriginal &&
          configuracionEtiqueta.codigoAuxiliar &&
          articulo.codAuxiliar
            ? mostrarDivisorDeCodigos()
            : null}

          {configuracionEtiqueta.codigoAuxiliar &&
            mostrarCodigo(
              articulo.codAuxiliar,
              configuracionEtiqueta.tamanioTipografia,
            )}
        </View>
        {configuracionEtiqueta.descripcion &&
          mostrarDescripcionArticulo(
            articulo.descripcionProveedor,
            configuracionEtiqueta.tamanioTipografia,
          )}
        <View
          style={{
            width: "100%",
            display: "flex",
            marginBottom: 5,
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {(configuracionEtiqueta.nombreProveedor ||
            configuracionEtiqueta.numeroProveedor) &&
            mostrarNombreProveedor(
              configuracionEtiqueta.tamanioTipografia,
              articulo.proveedor,
              configuracionEtiqueta.nombreProveedor,
              articulo.numero_proveedor,
              configuracionEtiqueta.numeroProveedor,
            )}
          {configuracionEtiqueta.precio &&
            mostrarPrecioArticulo(
              handleMostrarPrecioArticulo(articulo),
              configuracionEtiqueta.tamanioTipografia,
            )}
        </View>
        {configuracionEtiqueta.ubicacion &&
          mostrarUbicacionArticulo(
            articulo.repuesto_sucursal,
            configuracionEtiqueta.tamanioTipografia,
          )}
        {configuracionEtiqueta.fechaImpresion &&
          mostrarFechaImpresion(configuracionEtiqueta.tamanioTipografia)}
      </View>
    );
  };

  return (
    <Document title="Etiquetas A4">
      {paginas.map((etiquetasPagina, pageIndex) => (
        <Page key={pageIndex} size="A4" style={styles.page}>
          <View style={styles.contenedor}>
            <View style={styles.tabla}>
              {Array.from({ length: filasArray.cant }).map((_, filaIndex) => (
                <View style={styles.fila} key={filaIndex}>
                  {Array.from({ length: columnasArray.cant }).map(
                    (_, colIndex) => {
                      const etiquetaIndex =
                        filaIndex * columnasArray.cant + colIndex;
                      const etiqueta = etiquetasPagina[etiquetaIndex];
                      if (!etiqueta) return null;
                      return renderEtiqueta(etiqueta);
                    },
                  )}
                </View>
              ))}
            </View>
          </View>
        </Page>
      ))}
    </Document>
  );
}
