import React from "react";
import { Text, View } from "@react-pdf/renderer";
import HeaderTable from "../../Components/TableGeneric/HeaderTable";
import { columnsPresupuestoA4 } from "../../Components/TableReport/utils";
import { LineA4Color, LineA4Cut } from "../../Components/raya";
import { styles } from "../../assets/css/styleA4";
import { fill, isPresupuestoA4 } from "../../utils";
import BodyTablePresupuesto from "./BodyTablePresupuesto";
import HeaderA4 from "./HeaderA4";
import ResumenA4 from "./ResumenA4";
import { parseDate } from "../../../../../utils/parsers";

export default function BodyA4({ data, tipo, config }) {
  const handleRenderDescripcionDetalle = (detalle) => {
    if (detalle.descripcionPersonalizada) {
      return detalle.descripcionPersonalizada;
    }

    if (config.ocultar_codigo_pdf) {
      return detalle.descripcion || "---";
    }

    return detalle.descripcion_ticket || "---";
  };

  return (
    <>
      <HeaderA4
        fixed={isPresupuestoA4(tipo)}
        logoEmpresa={config && config.url_imagen ? config.url_imagen : null}
        data={data}
        nro={fill(data.id_presupuesto, 6)}
        titleNro={"PRESUPUESTO"}
        config={config}
      />
      <LineA4Color />
      <View style={{ minHeight: 95, maxHeight: 130 }}>
        <View style={styles.containerRow}>
          <View style={(styles.containerColumn, { width: 150 })}>
            <View style={{ flexDirection: "row" }}>
              <View style={(styles.containerColumn, { width: 150 })}>
                <Text style={styles.text2Title}>{`Sr. (es):`}</Text>
                <Text style={styles.text2}>
                  {data.cliente && data.cliente.cliente
                    ? data.cliente.cliente.razonSocial
                    : "Consumidor Final"}
                </Text>
              </View>
            </View>

            <View style={{ flexDirection: "row" }}>
              <View style={(styles.containerColumn, { width: 150 })}>
                <Text style={styles.text2Title}>{`Domicilio:`}</Text>
                <Text style={styles.text2}>
                  {data.cliente && data.cliente.cliente.domicilio
                    ? `${data.cliente.cliente.domicilio}`
                    : "---"}
                </Text>
              </View>
            </View>

            <View style={{ flexDirection: "row" }}>
              <View style={(styles.containerColumn, { width: 150 })}>
                <Text style={styles.text2Title}>{`Correo electrónico:`}</Text>
                <Text style={styles.text2}>
                  {data.cliente && data.cliente.cliente.correoElectronico
                    ? `${data.cliente.cliente.correoElectronico}`
                    : "---"}
                </Text>
              </View>
            </View>
            <View style={{ flexDirection: "row" }}>
              <View style={(styles.containerColumn, { width: 150 })}>
                <Text style={styles.text2Title}>{`Responsable:`}</Text>
                <Text style={styles.text2}>
                  {data.responsable
                    ? `${data.responsable.nombre} ${data.responsable.apellido}`
                    : "---"}
                </Text>
              </View>
            </View>
          </View>

          <View style={(styles.containerColumn, { width: 150 })}>
            <View style={{ flexDirection: "row" }}>
              <View style={(styles.containerColumn, { width: 150 })}>
                <Text style={styles.text2Title}>{`CUIT:`}</Text>
                <Text style={styles.text2}>
                  {data.cliente && data.cliente.cliente.CUIT
                    ? data.cliente.cliente.CUIT
                    : "---"}
                </Text>
              </View>
            </View>

            <View style={{ flexDirection: "row" }}>
              <View style={(styles.containerColumn, { width: 150 })}>
                <Text style={styles.text2Title}>{`Tel.:`}</Text>
                <Text style={styles.text2}>
                  {data.cliente && data.cliente.cliente.telefonoContacto
                    ? `${data.cliente.cliente.telefonoContacto}`
                    : "---"}
                </Text>
              </View>
            </View>

            <View style={{ flexDirection: "row" }}>
              <View style={(styles.containerColumn, { width: 150 })}>
                <Text style={styles.text2Title}>{`Condición de IVA:`}</Text>
                <Text style={styles.text2}>
                  {`${
                    data.cliente !== null
                      ? data.cliente.cliente.estadoTributario.descripcion
                      : "Consumidor Final"
                  }`}
                </Text>
              </View>
            </View>
          </View>

          <View style={(styles.containerColumn, { width: 150 })}>
            <View style={{ flexDirection: "row" }}>
              <View style={(styles.containerColumn, { width: 150 })}>
                <Text style={styles.text2Title}>{`VÁLIDO HASTA:`}</Text>
                <Text style={styles.text2}>
                  {parseDate(data.vencimiento, "DD/MM/YYYY")}
                </Text>
              </View>
            </View>

            <View style={{ flexDirection: "row" }}>
              <View style={(styles.containerColumn, { width: 150 })}>
                <Text style={styles.text2Title}>{`Condición de Pago:`}</Text>
                <Text style={styles.text2}>{"---"}</Text>
              </View>
            </View>
            {config.incluir_observaciones_presupuesto ? (
              <View style={{ flexDirection: "row" }}>
                <View style={(styles.containerColumn, { width: 150 })}>
                  <Text style={styles.text2Title}>{`Observaciones: `}</Text>
                  <Text style={styles.text2}>
                    {data.observacion &&
                    data.observacion.length > 0 &&
                    data.observacion[0].detalle !== ""
                      ? data.observacion[0].detalle
                      : "Ninguna"}
                  </Text>
                </View>
              </View>
            ) : null}
          </View>
        </View>
      </View>
      <HeaderTable
        fixed={isPresupuestoA4(tipo) ? true : false}
        columns={columnsPresupuestoA4(config)}
      />
      {data.detalles.map((detalle) => (
        <View key={detalle.id_detalle_presupuesto}>
          <BodyTablePresupuesto
            descripcion={handleRenderDescripcionDetalle(detalle)}
            cant={parseFloat(detalle.cantidad)}
            precioVenta={detalle.precio && detalle.precio}
            precioCosto={detalle.precios && detalle.precios.costo}
            precioLista={detalle.precios && detalle.precios.lista}
            listaPrecios={detalle.nombre_lista_precios || "---"}
            subtotal={detalle.subtotal}
            config={config}
          />
        </View>
      ))}
      <ResumenA4 tipo={tipo} data={data} config={config} />
      {!isPresupuestoA4(tipo) && (
        <View>
          <LineA4Cut />
        </View>
      )}
    </>
  );
}
