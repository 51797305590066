import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
  Grid,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import Period from "../../../../components/ait-reusable/PeriodFilter/Period";
import ButtonGeneral from "../../../../components/ait-reusable/Button/ButtonGeneral";
import ButtonFormato from "../../../../components/ait-reusable/Button/ButtonFormato";
import CircularBackdrop from "../../../../components/ait-reusable/CircularBackdrop";
import { AlertReusable } from "../../../../components/ait-reusable/Alerts/AlertReusable";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 400,
    minWidth: 250,
  },
}));

export default function ModalPeriodo({
  dates,
  onClose,
  setDates,
  modalPeriodo,
  handleImprimirInforme,
  loadingMovimientos,
}) {
  const classes = useStyles();
  const [period, setPeriod] = useState("thisMonth");
  const [formato, setFormato] = useState("print");
  const [errors, setErrors] = useState({ fromDate: null, toDate: null });

  const optionsPeriod = [
    { value: "thisWeek", name: "Esta semana" },
    { value: "thisMonth", name: "El corriente mes" },
    { value: "lastMonth", name: "Mes Anterior" },
    { value: "custom", name: "Personalizado" },
  ];

  const validateDatesPersonalizado = () => {
    if (period === "custom") {
      let isError =
        (!dates.fromDate && dates.toDate) || (dates.fromDate && !dates.toDate)
          ? true
          : false;
      if (isError) {
        setErrors({
          ...errors,
          fromDate: !dates.fromDate ? "Debe seleccionar una fecha" : null,
          toDate: !dates.toDate ? "Debe seleccionar una fecha" : null,
        });
      } else {
        handleImprimirInforme(formato);
      }
    } else {
      handleImprimirInforme(formato);
    }
  };

  return (
    <Dialog
      open={modalPeriodo}
      onClose={onClose}
      classes={{ paper: classes.root }}
    >
      <DialogTitle>Seleccione un Período</DialogTitle>
      <Divider />
      <DialogContent>
        <AlertReusable
          severity={"info"}
          text={
            period === "custom"
              ? "Si no seleccionas ninguna fecha, se mostrarán los movimientos históricos. Sin embargo, si decides seleccionar alguna fecha, será obligatorio elegir ambas."
              : "Por defecto se selecciona este mes"
          }
        />
        <Period
          noButton={true}
          noBordered={true}
          period={period}
          setPeriod={setPeriod}
          dates={dates}
          setDates={setDates}
          clear={() => {}}
          search={null}
          searchOnLoad={null}
          optionsPeriod={optionsPeriod}
          errors={errors}
          setErrors={setErrors}
          // disablCustom={true}
        />
        <Grid container spacing={2} style={{ paddingTop: 15 }}>
          <Grid item xs={12} sm={6} lg={6}>
            <ButtonFormato
              tipo="print"
              message="Imprimir"
              icon="print"
              click={() => setFormato("print")}
              format={formato}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={6}>
            <ButtonFormato
              tipo="wpp"
              message={"Enviar wp"}
              icon="whatsapp"
              click={() => setFormato("wpp")}
              format={formato}
            />
          </Grid>

          <Grid item xs={12} style={{ paddingTop: "0.5em" }}>
            <ButtonGeneral
              fullwidth={true}
              disabled={loadingMovimientos}
              message={
                loadingMovimientos ? <CircularProgress size={22} /> : "Aceptar"
              }
              click={() => validateDatesPersonalizado()}
            />
          </Grid>
        </Grid>
      </DialogContent>
      {loadingMovimientos && (
        <CircularBackdrop openBackdrop={loadingMovimientos} />
      )}
    </Dialog>
  );
}
