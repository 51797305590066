import React from "react";
import { styles } from "../../assets/css/styleA4";
import { View, Text, Image } from "@react-pdf/renderer";
import FirmaBoxer from "../../Components/firmaBoxer";

export default function FooterA4({ notaDebito }) {
  return (
    <View style={styles.footerFactura} fixed={true}>
      <View style={styles.containerRow}>
        <View style={(styles.containerColumn, { width: 90 })}>
          <View>
            <Image
              style={{ width: 95, height: 95, position: "absolute" }}
              allowDangerousPaths={true}
              src={`data:image/png/jpg;base64,${notaDebito.qr_base64}`}
            />
          </View>
        </View>
        <View style={(styles.containerColumn, { width: 210 })}>
          <Text
            style={{
              paddingBottom: 4,
              fontSize: 10,
              textAlign: "justify",
              color: "#1B71BA",
              textTransform: "uppercase",
            }}
          >
            {notaDebito.sucursal.razonSocial || ""}
          </Text>
          <Text style={styles.text}>{notaDebito.sucursal.domicilio || ""}</Text>
          <Text style={styles.text}>{`${
            notaDebito.sucursal.correoElectronico || ""
          }`}</Text>
          <Text style={styles.text}>{` ${
            notaDebito.sucursal.telefonoContacto || ""
          }`}</Text>
        </View>
        <View style={(styles.containerColumnRight, { width: 200 })}>
          <Text
            style={{
              paddingBottom: 2,
              paddingTop: 2,
              fontSize: 8,
              textAlign: "right",
            }}
          >{`CAE: ${notaDebito.factura_receipt.cae} Vto. ${notaDebito.factura_receipt.cae_expiration} `}</Text>
          <Text style={styles.textAfip}>
            {
              "Consultas de validez: http://www.afip.gob.ar/genericos/consultacae/"
            }
          </Text>
          <Text style={styles.textAfip}>
            {
              "Teléfono Gratuito CABA, Área de Defensa y Protección al Consumidor."
            }
          </Text>
          <Text style={styles.textAfip}>{"Tel 147"}</Text>
          <View
            style={{
              alignItems: "flex-end",
              width: 200,
            }}
          >
            <FirmaBoxer />
          </View>
        </View>
      </View>
    </View>
  );
}
