import React from "react";
import {
  Grid,
  Button,
  Collapse,
  TextField,
  Typography,
  Box,
  Chip,
} from "@material-ui/core";
import Period from "../../../../components/ait-reusable/PeriodFilter/Period";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";

export default function Filters({ dataFilters }) {
  const {
    paginationIngreso,
    paginationEgreso,
    setPaginationEgreso,
    setPaginationIngreso,
    value,
    handleGetFacturas,
    dates,
    setDates,
    period,
    setPeriod,
    errors,
    setErrors,
    getMovIngresos,
    getMovEgresos,
    useEmpleado,
    useQueryEmpleado,
    tipoMovimientoSelected,
    setTipoMovimientoSelected,
    tiposMovimiento,
    observacionValue,
    setObservacionValue,
    montosValue,
    setMontosValue,
  } = dataFilters;

  const collapseOpen = true;
  const optionsPeriod = [
    { value: "today", name: "Hoy" },
    { value: "yesterday", name: "Ayer" },
    { value: "thisWeek", name: "Esta semana" },
    { value: "thisMonth", name: "El corriente mes" },
    { value: "lastMonth", name: "El mes anterior" },
    { value: "lastNinetyDays", name: "Últimos 90 días" },
    { value: "thisYear", name: "El corriente año" },
    { value: "custom", name: "Personalizado" },
  ];

  const filterOptions = createFilterOptions({
    matchFrom: "start",
    stringify: (option) => option.nombre,
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {/* <ButtonFilter click={() => setCollapseOpen(!collapseOpen)} /> */}
        <Grid
          container
          spacing={2}
          style={{ paddingTop: 8, paddingLeft: 8, paddingRight: 8 }}
        >
          <Grid item xs={12}>
            <Collapse in={collapseOpen}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={12}>
                  <Period
                    period={period}
                    setPeriod={setPeriod}
                    noButton={true}
                    dates={dates}
                    setDates={setDates}
                    noBordered
                    search={() => {
                      if (value === 0) {
                        paginationIngreso.page !== 1
                          ? setPaginationIngreso({
                              ...paginationIngreso,
                              page: 1,
                            })
                          : handleGetFacturas();
                      } else {
                        paginationEgreso.page !== 1
                          ? setPaginationEgreso({
                              ...paginationEgreso,
                              page: 1,
                            })
                          : handleGetFacturas();
                      }
                    }}
                    errors={errors}
                    setErrors={setErrors}
                    optionsPeriod={optionsPeriod}
                    sizeGrid={3}
                  >
                    <Grid item xs={12} md={3}>
                      <Autocomplete
                        value={useEmpleado.empleado}
                        options={useEmpleado.empleados}
                        fullWidth={true}
                        filterOptions={(options, state) => options}
                        getOptionLabel={(empleado) =>
                          empleado.nombre + " " + empleado.apellido
                        }
                        inputValue={useQueryEmpleado.query || ""}
                        onInputChange={(event, value) => {
                          useQueryEmpleado.setQuery(value);
                        }}
                        onChange={(event, value) =>
                          useEmpleado.setEmpleado(value)
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Responsable"
                            variant="outlined"
                            fullWidth={true}
                          />
                        )}
                        name="buscador_clientes"
                        size="small"
                      />
                    </Grid>

                    {/* <Grid item xs={12} md={3}>
                      <TextField
                        style={{ backgroundColor: "white" }}
                        name="tipo_movimiento"
                        fullWidth
                        select
                        size="small"
                        label={"Motivo"}
                        value={tipoMovimientoSelected}
                        onChange={(e) =>
                          setTipoMovimientoSelected(e.target.value)
                        }
                        variant="outlined"
                      >
                        <MenuItem value={"all"}>Todos</MenuItem>
                        {tiposMovimiento.map((option, index) => {
                          return (
                            <MenuItem key={index} value={option.id}>
                              {option.nombre}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </Grid> */}
                    <Grid item xs={12} md={3}>
                      <Autocomplete
                        multiple
                        autoHighlight
                        options={tiposMovimiento}
                        getOptionLabel={(option) => ""}
                        size="small"
                        filterOptions={filterOptions}
                        value={tipoMovimientoSelected}
                        filterSelectedOptions
                        noOptionsText={<Typography>No hay opciones</Typography>}
                        onChange={(event, value, reason) => {
                          setTipoMovimientoSelected(value);
                        }}
                        renderOption={(option) => (
                          <Box display="flex">
                            <Typography variant="body1">
                              <span> {option.nombre}</span>
                            </Typography>
                          </Box>
                        )}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip
                              variant="outlined"
                              color="primary"
                              label={option.nombre}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Motivos"
                            variant="outlined"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        style={{ backgroundColor: "white" }}
                        name="observacion"
                        fullWidth
                        size="small"
                        label={"Buscar por observación"}
                        value={observacionValue}
                        onChange={(e) => setObservacionValue(e.target.value)}
                        variant="outlined"
                      />
                    </Grid>
                    {/* MONTO MINIMO */}
                    <Grid item xs={12} md={3}>
                      <TextField
                        style={{ backgroundColor: "white" }}
                        name="monto_minimo"
                        fullWidth
                        size="small"
                        label={"Monto mínimo"}
                        value={montosValue.montoMinimo}
                        onChange={(e) =>
                          setMontosValue({
                            ...montosValue,
                            montoMinimo: e.target.value,
                          })
                        }
                        variant="outlined"
                      />
                    </Grid>
                    {/* MONTO MAXIMO */}
                    <Grid item xs={12} md={3}>
                      <TextField
                        style={{ backgroundColor: "white" }}
                        name="monto_maximo"
                        fullWidth
                        size="small"
                        label={"Monto máximo"}
                        value={montosValue.montoMaximo}
                        onChange={(e) =>
                          setMontosValue({
                            ...montosValue,
                            montoMaximo: e.target.value,
                          })
                        }
                        variant="outlined"
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={2}
                      lg={2}
                      xl={2}
                      style={{ paddingBottom: 15 }}
                    >
                      <Button
                        onClick={() =>
                          value === 0 ? getMovIngresos() : getMovEgresos()
                        }
                        variant="contained"
                        color="primary"
                        fullWidth
                      >
                        CONSULTAR
                      </Button>
                    </Grid>
                  </Period>
                </Grid>
              </Grid>
            </Collapse>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
