import {
  moduloAdministrador,
  moduloAdministrarModulos,
  moduloAfipRoute,
  moduloAjustePrecioCostoRoute,
  // moduloCajaRoute,
  moduloArqueoCajaRoute,
  moduloArticulosNuevoRoute,
  moduloArticulosRepetidos,
  moduloAuditoriasDePedidos,
  moduloCajaVentaRoute,
  moduloCargarClienteRoute,
  moduloCargarProveedorRoute,
  moduloCatalogoProveedorRoute,
  moduloCatalogosRoute,
  moduloChatGPT,
  moduloClienteBilleteraVirtual,
  moduloClienteChequesRoute,
  moduloClienteIdRoute,
  moduloClientesRoute,
  moduloComprasAdeudadas,
  moduloComprasAfip,
  moduloComprasRoute,
  moduloConfFiltrosIdRoute,
  moduloConfFiltrosNuevoRoute,
  moduloConfFiltrosRoute,
  moduloConfGeneralRoute,
  moduloConfiguracionRoute,
  moduloCrearAuditoriaPedido,
  moduloCuentaCorrienteProveedor,
  moduloCuentaProveedores,
  moduloCuentas,
  moduloDashboardPath,
  moduloDepositoRoute,
  moduloEditarAuditoriaPedido,
  moduloEstadisticas,
  moduloFacturasAdeudadasRoute,
  moduloImprimirEtiquetasRoute,
  moduloInformeChequesRoute,
  moduloInformeCombinacionRoute,
  moduloInformeComprasRoute,
  moduloInformeLibroIvaRoute,
  moduloInformeMovimientoCajaRoute,
  moduloInformeMovimientosBilletera,
  moduloInformeMovimientoStockRoute,
  moduloInformeParcialesRoute,
  moduloInformePedidosRoute,
  moduloInformePromedioDiario,
  moduloInformeRendimientos,
  moduloInformesRoute,
  moduloInformeVentasPorMarcaRoute,
  // moduloMercadoLibreRoute,
  moduloIntegracionesRoute,
  moduloListasManuales,
  moduloMarcasProveedorRoute,
  moduloModificarArticuloRoute,
  moduloModificarProveedorRoute,
  moduloMovCajaRoute,
  moduloMovimientoStockArticuloRoute,
  moduloNuevaIntegracionRoute,
  moduloPagoCompras,
  moduloPagoComprasProveedor,
  moduloPagosRoute,
  moduloPanelRoute,
  moduloParametrosListas,
  moduloParametrosProveedorRoute,
  moduloPedidosEditar,
  // moduloComprasNormalRoute,
  // moduloComprasRapidaRoute,
  moduloPedidosRoute,
  moduloPresupuestoIdRoute,
  moduloPresupuestoRoute,
  moduloPublicacionesMercadoLibreRoute,
  moduloPublicacionesWoocommerceRoute,
  moduloRemitoRoute,
  moduloRepuestoProveedor,
  moduloRetenciones,
  moduloReVentaRoute,
  moduloTarjetasRoute,
  moduloUbicacionRoute,
  moduloVentaOrdenRoute,
  moduloVentaPresupuestoRoute,
  moduloVentaRemitoRoute,
  moduloVentaRoute,
  moduloVentasRoute,
  moduloVerProveedoresRoute,
  moduloVinculacionMasivaPublicacionesMeli,
  moduloVinculacionPublicacionesMELIRoute,
  moduloVinculacionPublicacionesWoocommerceRoute,
  moduloZonaRoute,
} from "./modulosRoutes";

//Stock
// const isAuth = true;

export default function (
  isVendedor,
  meli_integracion,
  woocommerce_integracion,
  integracion_afip,
) {
  if (isVendedor) {
    return [
      moduloAfipRoute(),
      moduloVentaRoute(),
      moduloReVentaRoute(),
      moduloVentaPresupuestoRoute(),
      moduloPresupuestoRoute(),
      moduloPresupuestoIdRoute(),
      moduloRemitoRoute(),
      // DEPRECATED
      // moduloCajaRoute(),
      moduloArqueoCajaRoute(),
      moduloMovCajaRoute(),
      moduloCajaVentaRoute(),
      moduloVentasRoute(),
      moduloTarjetasRoute(),
      moduloConfFiltrosRoute(),
      moduloConfFiltrosIdRoute(),
      moduloConfFiltrosNuevoRoute(),
      moduloConfiguracionRoute(),
      moduloClientesRoute(),
      moduloClienteIdRoute(),
      moduloCargarClienteRoute(),
      moduloArticulosNuevoRoute(),
      moduloModificarArticuloRoute(),
      moduloCatalogosRoute(),
      moduloVerProveedoresRoute(),
      moduloCargarProveedorRoute(),
      moduloModificarProveedorRoute(),
      moduloCatalogoProveedorRoute(),
      moduloParametrosProveedorRoute(),
      moduloFacturasAdeudadasRoute(),
      moduloPagosRoute(),
      moduloClienteBilleteraVirtual(),
      moduloInformesRoute(),
      moduloCuentaCorrienteProveedor(),
      moduloComprasAdeudadas(),
      moduloInformeMovimientoStockRoute(),
      // moduloInformeMovimientoCajaRoute(),
      moduloInformeMovimientosBilletera(),
      moduloInformePromedioDiario(),
      // moduloInformeVentasPorMarcaRoute(),
      // moduloInformeLibroIvaRoute(),
      moduloUbicacionRoute(),
      moduloDepositoRoute(),
      moduloZonaRoute(),
      moduloMarcasProveedorRoute(),
      moduloComprasRoute(),
      // moduloComprasNormalRoute(),
      // moduloComprasRapidaRoute(),
      moduloInformeComprasRoute(),
      moduloPedidosRoute(),
      moduloInformePedidosRoute(),
      moduloInformeParcialesRoute(),
      moduloImprimirEtiquetasRoute(),
      moduloPedidosEditar(),
      moduloPagoCompras(),
      moduloPagoComprasProveedor(),
      moduloInformeCombinacionRoute(),
      moduloVentaRemitoRoute(),
      moduloArticulosRepetidos(),
      moduloMovimientoStockArticuloRoute(),
      moduloVentaOrdenRoute(),
      ...moduloListasManuales(),
      moduloChatGPT(),
      moduloClienteChequesRoute(),
      moduloAuditoriasDePedidos(),
      moduloCrearAuditoriaPedido(),
      moduloEditarAuditoriaPedido(),
      moduloCuentaProveedores(),
      moduloParametrosListas(),
      moduloInformeRendimientos(),
      ...moduloCuentas,
    ];
  } else {
    return [
      moduloRetenciones(),
      moduloAfipRoute(),
      moduloDashboardPath(),
      moduloEstadisticas(),
      moduloVentaRoute(),
      moduloReVentaRoute(),
      moduloVentaPresupuestoRoute(),
      moduloPresupuestoRoute(),
      moduloPresupuestoIdRoute(),
      moduloRemitoRoute(),
      // DEPRECATED
      // moduloCajaRoute(),
      moduloArqueoCajaRoute(),
      moduloMovCajaRoute(),
      moduloCajaVentaRoute(),
      moduloVentasRoute(),
      moduloTarjetasRoute(),
      moduloConfGeneralRoute(),
      moduloConfFiltrosRoute(),
      moduloConfFiltrosIdRoute(),
      moduloConfFiltrosNuevoRoute(),
      moduloConfiguracionRoute(),
      moduloPanelRoute(),
      moduloClientesRoute(),
      moduloClienteIdRoute(),
      moduloCuentaCorrienteProveedor(),
      moduloComprasAdeudadas(),
      moduloCargarClienteRoute(),
      moduloArticulosNuevoRoute(),
      moduloModificarArticuloRoute(),
      moduloCatalogosRoute(),
      moduloVerProveedoresRoute(),
      moduloCargarProveedorRoute(),
      moduloModificarProveedorRoute(),
      moduloCatalogoProveedorRoute(),
      moduloParametrosProveedorRoute(),
      moduloAjustePrecioCostoRoute(),
      moduloFacturasAdeudadasRoute(),
      moduloPagosRoute(),
      moduloClienteChequesRoute(),
      moduloClienteBilleteraVirtual(),
      moduloImprimirEtiquetasRoute(),
      moduloInformesRoute(),
      moduloInformeMovimientoStockRoute(),
      moduloInformeMovimientoCajaRoute(),
      moduloInformeMovimientosBilletera(),
      moduloInformeChequesRoute(),
      moduloInformePromedioDiario(),
      moduloInformeVentasPorMarcaRoute(),
      moduloInformeLibroIvaRoute(),
      moduloUbicacionRoute(),
      moduloDepositoRoute(),
      moduloZonaRoute(),
      moduloMarcasProveedorRoute(),
      moduloComprasRoute(),
      // moduloComprasNormalRoute(),
      // moduloComprasRapidaRoute(),
      moduloInformeComprasRoute(),
      moduloPedidosRoute(),
      moduloInformePedidosRoute(),
      moduloInformeParcialesRoute(),
      moduloPedidosEditar(),
      moduloPagoCompras(),
      moduloPagoComprasProveedor(),
      ...(meli_integracion ? [moduloPublicacionesMercadoLibreRoute()] : []),
      ...(woocommerce_integracion
        ? [moduloPublicacionesWoocommerceRoute()]
        : []),
      moduloIntegracionesRoute(),
      moduloNuevaIntegracionRoute(),
      ...(meli_integracion ? [moduloVinculacionPublicacionesMELIRoute()] : []),
      ...(woocommerce_integracion
        ? [moduloVinculacionPublicacionesWoocommerceRoute()]
        : []),

      moduloInformeCombinacionRoute(),
      moduloVentaRemitoRoute(),
      moduloArticulosRepetidos(),
      moduloMovimientoStockArticuloRoute(),
      moduloVentaOrdenRoute(),
      ...moduloListasManuales(),
      ...moduloAdministrador,
      moduloChatGPT(),
      ...(integracion_afip ? [moduloComprasAfip()] : []),
      moduloAuditoriasDePedidos(),
      moduloCrearAuditoriaPedido(),
      moduloEditarAuditoriaPedido(),
      moduloCuentaProveedores(),
      moduloParametrosListas(),
      moduloAdministrarModulos(),
      ...moduloCuentas,
      ...moduloVinculacionMasivaPublicacionesMeli,
      moduloInformeRendimientos(),
      ...moduloRepuestoProveedor,
    ];
  }
}
