import { Backdrop, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import TextFieldAutocompleteArticulo from "../../../../../components/ait-reusable/TextFieldAutocompleteArticulo";
import TooltipWithoutIcon from "../../../../../components/ait-reusable/TooltipMoreInfo/TooltipWithoutIcon";
import { clickableErrorNotification } from "../../../../../components/Notifications";
import request from "../../../../../requests/request";
import { getDataDuplicados } from "../../../../../requests/urls";
import { maxLength } from "../../../../../utils/maxLength";

const useStyles = makeStyles((theme) => ({
  "@global": {
    '.MuiAutocomplete-option[data-focus="true"]': {
      background: "#B2B2B2 ",
    },
  },
  option: {
    "&:hover": {
      backgroundColor: "#B2B2B2 !important",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    fontSize: 13,
    fontWeight: 700,
    background: theme.palette.common.black,
    color: "#fff",
  },
  fontSizeOptions: {
    fontSize: 18,
  },
  labelRoot: {
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.1em",
    },
  },
  customInputAdornment: {
    color: "blue",
  },
}));

export const OptionsLabel = ({ articulo }) => {
  const classes = useStyles();
  return (
    <div>
      <TooltipWithoutIcon
        placement={"bottom"}
        body={
          <h6 style={{ fontSize: 15, marginBottom: 0, marginTop: 0 }}>
            {!(
              articulo.repuesto_sucursal === null ||
              articulo.repuesto_sucursal.deposito === undefined
            )
              ? `${
                  articulo.repuesto_sucursal.deposito.descripcion +
                  " - " +
                  articulo.repuesto_sucursal.zona.descripcion +
                  " - " +
                  articulo.repuesto_sucursal.estante.descripcion +
                  " - " +
                  articulo.repuesto_sucursal.fila.descripcion
                }`
              : "Sin ubicación"}
          </h6>
        }
      >
        <div>
          <span
            className={classes.fontSizeOptions}
            style={{ fontWeight: "bolder" }}
          >
            C:
          </span>
          <span className={classes.fontSizeOptions}>
            {articulo.codProveedor || "N/A"}
          </span>{" "}
          --
          <span style={{ fontWeight: "bolder" }}>AUX:</span>
          <span className={classes.fontSizeOptions}>
            {articulo.codAuxiliar || "N/A"}
          </span>{" "}
          --
          <span style={{ fontWeight: "bolder" }}>P:</span>
          <span className={classes.fontSizeOptions}>
            {articulo.alias_proveedor || "N/A"}
          </span>{" "}
          --
          <span style={{ fontWeight: "bolder" }}>D:</span>
          <span className={classes.fontSizeOptions}>
            {articulo.descripcionProveedor}
          </span>{" "}
          --
          <span style={{ fontWeight: "bolder" }}>S:</span>
          <span className={classes.fontSizeOptions}>
            {articulo.repuesto_sucursal
              ? articulo.repuesto_sucursal.stockDisponible
              : "N/A"}
          </span>{" "}
          --
          <span style={{ fontWeight: "bolder" }}>$</span>
          <span className={classes.fontSizeOptions}>
            {articulo.precios.venta || "0.00"}
          </span>
        </div>
      </TooltipWithoutIcon>
    </div>
  );
};

export default function AutocompleteArticulo({
  busquedaArticulo,
  setBusqueda,
  articulos,
  loading,
  onSelectArticulo,
}) {
  const { configGeneral } = useSelector((state) => state.configGeneral);
  const classes = useStyles();
  const history = useHistory();

  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [autocompleteValue, setAutocompleteValue] = useState(null);

  const getDataArtDuplicados = async (articulo) => {
    setOpenBackdrop(true);
    try {
      const response = await request({
        method: "GET",
        url: getDataDuplicados,
        params: {
          id: articulo.codProveedor,
          codigo: "proveedor",
          id_proveedor: articulo.idProveedor,
          page: 1,
        },
      });
      setOpenBackdrop(false);
      return response.data.data.items;
    } catch (error) {
      return null;
    }
  };

  const handleOnChangeArticulo = async (articulo) => {
    setAutocompleteValue(articulo);

    if (onSelectArticulo) {
      onSelectArticulo(articulo);
    }

    const repetido = await getDataArtDuplicados(articulo);
    if (repetido.length > 1) {
      clickableErrorNotification(
        "El artículo agregado podría estar duplicado en el sistema. Haga click aquí para revisarlo.",
        5000,
        () =>
          history.push(
            `/proveedores/articulos-duplicados/?proveedor=${articulo.idProveedor}&cod=${articulo.codProveedor}`,
          ),
      );
    }

    document.getElementById("buscador_articulos").focus();
    setBusqueda("");
    setAutocompleteValue(null);
  };

  /**Funcion que filtra por varios campos con el autocomplete */
  // const filterOptions = (options, { inputValue }) =>
  //   matchSorter(options, inputValue, {
  //     keys: ["codProveedor", "codOriginal", "descripcionProveedor"],
  //   });

  const handleChangeInput = (value, reason) => {
    reason !== "reset" && setBusqueda(value);
  };

  return (
    <>
      <Autocomplete
        fullWidth
        classes={{
          option: classes.option,
        }}
        autoHighlight={configGeneral.es_autoseleccionado}
        loading={loading}
        value={autocompleteValue}
        options={articulos}
        filterOptions={(options) => options}
        getOptionLabel={(option) => option.codProveedor}
        renderOption={(articulo) => <OptionsLabel articulo={articulo} />}
        inputValue={busquedaArticulo}
        onInputChange={(_e, value, reason) => handleChangeInput(value, reason)}
        onInput={(e) => maxLength(e, 50)}
        onChange={(_e, value, _reason) => handleOnChangeArticulo(value)}
        renderInput={(params) => (
          <TextFieldAutocompleteArticulo
            {...params}
            size="medium"
            label={"Busqueda por nombre o código"}
            variant="outlined"
            InputLabelProps={{
              classes: {
                root: classes.labelRoot,
              },
            }}
          />
        )}
        id="buscador_articulos"
        name="buscador_articulos"
      />

      <Backdrop className={classes.backdrop} open={openBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
