import React from "react";
import { Document, Page, View, Text } from "@react-pdf/renderer";
import { styles } from "../assets/css/styleTicket";
import HeaderTicket from "./Components/HeaderTicket";
import SubHeaderFacturaTicket from "./Components/SubHeaderFacturaTicket";
import DataFacturaTicket from "./Components/DataFacturaTicket";
import ArticulosFacturaTicket from "./Components/ArticulosFacturaTicket";
import TotalesTicketFactura from "./Components/TotalesTicketFactura";
import TotalesCtaCte from "./Components/TotalesCtaCte";
import ValidationAfip from "./Components/ValidationAfip";

export default function TicketFactura({ data, remito, config }) {
  return (
    <Document title={remito ? "Remito" : "Factura Venta"}>
      <Page style={styles.page} size={{ width: 226.77, minHeight: 400 }}>
        <View>
          <HeaderTicket
            logoEmpresa={data.logo}
            data={data}
            config={{ ocultar_datos_ci: false }}
            remito={remito}
          />
          <DataFacturaTicket data={data} remito={remito} />
          <SubHeaderFacturaTicket
            data={data}
            pagos={data.pagos_cliente}
            remito={remito}
          />
          <View style={styles.containerRow}>
            <View style={styles.containerColumn}>
              <Text style={styles.textbold}>{`Descripción(%IVA)`}</Text>
              <Text style={styles.textbold}>Cant/Precio Unit.</Text>
            </View>
            <View style={styles.containerColumn2}>
              <Text style={styles.textbold}>IMPORTE</Text>
            </View>
          </View>

          <Text
            style={{
              marginLeft: 10,
              marginRight: 10,
            }}
          >
            --------------------------------
          </Text>

          <ArticulosFacturaTicket
            data={data.receipt}
            factura={data.factura}
            venta={data.venta}
            remito={remito}
            detallesVentasAgrupadas={data.detalles_ventas_agrupadas}
          />

          <Text style={{ marginLeft: 10, marginRight: 10 }}>
            --------------------------------
          </Text>
          {!remito && (
            <TotalesTicketFactura
              dcto={data.descuento}
              recargo={data.recargo}
              factura={data.factura}
              vat={data.vat}
              receipt={data.receipt}
              data={data}
            />
          )}

          {data.cc_plazos.length > 0 && !remito && (
            <React.Fragment>
              <Text style={{ marginLeft: 10, marginRight: 10 }}>
                --------------------------------
              </Text>
              <TotalesCtaCte
                plazos={data.cc_plazos}
                montoTotal={data.receipt.total_amount}
                fechaVenta={data.factura.fechaHoraFactura}
                data={data}
              />
            </React.Fragment>
          )}

          {data.validation && (
            <ValidationAfip validation={data.validation} qr={data.qr} />
          )}
          <View>
            {data.factura.fechaHoraFactura &&
            new Date(data.factura.fechaHoraFactura) >
              new Date(2021, 5, 30, 23, 59, 59) &&
            data.factura.idTipoFactura &&
            data.factura.idClienteDeSucursal &&
            data.factura.idClienteDeSucursal.idCliente.idEstadoTributario
              .descripcion !== "Monotributista" &&
            data.factura.idTipoFactura.nombre === "M" ? (
              <Text style={{ textAlign: "center", fontSize: 7 }}>
                OPERACION SUJETA A RETENCIÓN
              </Text>
            ) : null}
            {data.factura.fechaHoraFactura &&
            new Date(data.factura.fechaHoraFactura) >
              new Date(2021, 5, 30, 23, 59, 59) &&
            data.factura.idTipoFactura &&
            data.factura.idClienteDeSucursal &&
            data.factura.idClienteDeSucursal.idCliente.idEstadoTributario
              .descripcion === "Monotributista" &&
            (data.factura.idTipoFactura.nombre === "M" ||
              data.factura.idTipoFactura.nombre === "A") ? (
              <Text style={{ textAlign: "center", fontSize: 6 }}>
                El crédito fiscal discriminado en el presente comprobante, sólo
                podrá ser computado a efectos del Régimen de Sostenimiento e
                Inclusión Fiscal para Pequeños Contribuyentes de la Ley Nº
                27.618
              </Text>
            ) : null}
          </View>
          {config.activar_leyenda && data.venta.leyenda.length ? (
            <View>
              <View>
                <Text style={{ marginLeft: 10, marginRight: 10 }}>
                  --------------------------------
                </Text>
              </View>
              <View>
                <Text style={{ padding: 8, fontSize: 8, textAlign: "center" }}>
                  {data.venta.leyenda[0].descripcion}
                </Text>
              </View>
              <View>
                <Text style={{ marginLeft: 10, marginRight: 10 }}>
                  --------------------------------
                </Text>
              </View>
            </View>
          ) : null}
          <View style={{ paddingBottom: 5 }}>
            <Text style={{ padding: 10, fontSize: 9, textAlign: "center" }}>
              MUCHAS GRACIAS POR SU COMPRA
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
}
