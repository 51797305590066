import React from "react";
import FormCambiosMasivo from "./FormCambiosMasivo";

export default function TercerCodigo({ props }) {
  // const { articulosSelected, update } = props;
  const moreProps = {
    title: "Agregar código auxiliar",
    type: "code",
  };

  return <FormCambiosMasivo {...props} {...moreProps} />;
}
