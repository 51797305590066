import { Badge, IconButton, MenuItem, SvgIcon } from "@material-ui/core";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import DeleteIcon from "@material-ui/icons/Delete";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import UndoIcon from "@material-ui/icons/Undo";
import moment from "moment";
import React from "react";
import { StyledMenu } from "../../../components/ait-reusable/Menu/MenuB";
import TooltipMoreInfoMaterial from "../../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";
import TooltipWithoutIcon from "../../../components/ait-reusable/TooltipMoreInfo/TooltipWithoutIcon";
import request from "../../../requests/request";
import {
  anulacionOrdenPago,
  anulacionOrdenRetiro,
  anulacionPagoCliente,
  getClienteSucursal,
  obtenerEgresos,
  obtenerIngresos,
  obtenerTotales,
  readMedioPago,
} from "../../../requests/urls";

export const initialResumen = [
  {
    nombre: "cobros",
    monto: 0,
    icon: <ArrowUpwardIcon style={{ color: "green" }} />,
  },
  {
    nombre: "salidas",
    monto: 0,
    icon: <ArrowDownwardIcon style={{ color: "red" }} />,
  },
  {
    nombre: "monto billetera",
    monto: 0,
    icon: <ImportExportIcon />,
  },
];
export default async function getMediosPago() {
  let result = [];
  try {
    const response = await request({
      method: "GET",
      url: readMedioPago,
    });
    result = response.data;
  } catch (error) {
    console.error(error);
  }
  return result;
}
export async function deleteMov(id) {
  let result = [];
  try {
    const response = await request({
      method: "POST",
      url: anulacionPagoCliente(id),
    });
    result = response.data;
  } catch (error) {
    console.error(error);
  }
  return result;
}

export async function deleteOrdenSelected(pagoSelected) {
  const esCarga = pagoSelected.tipo_registro === "orden_carga_saldo";
  const dataRetiro = {
    orden_retiro_saldo_id: pagoSelected.orden_retiro_saldo_id,
  };
  const dataPago = {
    orden_carga_saldo_id: pagoSelected.orden_carga_saldo_id,
  };

  let result = {};

  try {
    const response = await request({
      method: "PUT",
      url: esCarga ? anulacionOrdenPago : anulacionOrdenRetiro,
      data: esCarga ? dataPago : dataRetiro,
    });
    result = response;
  } catch (error) {
    console.error(error);
  }
  return result;
}
export async function getTotales(idCliente) {
  let result = [];
  try {
    const response = await request({
      method: "GET",
      url: obtenerTotales(idCliente),
    });
    result = response.data;
  } catch (error) {
    console.error(error);
  }
  return result;
}

export async function getdataCliente(idCliente) {
  let result = [];
  try {
    const response = await request({
      method: "GET",
      url: getClienteSucursal(idCliente),
    });
    result = response.data.data;
  } catch (error) {
    console.error(error);
  }
  return result;
}

export async function getIngresos(
  idCliente,
  page,
  dates,
  useEmpleado,
  tipoMovimientoSelected,
  observacionValue,
  montosValue,
) {
  let result = [];
  try {
    const response = await request({
      method: "GET",
      url: obtenerIngresos(idCliente),
      params: {
        page: page,
        desde: dates.fromDate,
        hasta: dates.toDate,
        empleado: useEmpleado.empleado ? useEmpleado.empleado.idEmpleado : null,
        motivo: !tipoMovimientoSelected.length
          ? null
          : tipoMovimientoSelected.map((x) => x.id),
        observacion: observacionValue,
        monto_minimo: !montosValue.montoMinimo
          ? 0
          : Number(montosValue.montoMinimo),
        monto_maximo: !montosValue.montoMaximo
          ? 999999999999999
          : Number(montosValue.montoMaximo),
      },
    });
    result = response.data.data;
  } catch (error) {
    console.error(error);
  }
  return result;
}

export async function getEgresos(
  idCliente,
  page,
  dates,
  useEmpleado,
  tipoMovimientoSelected,
  observacionValue,
  montosValue,
) {
  let result = [];
  try {
    const response = await request({
      method: "GET",
      url: obtenerEgresos(idCliente),
      params: {
        page: page,
        desde: dates.fromDate,
        hasta: dates.toDate,
        empleado: useEmpleado.empleado ? useEmpleado.empleado.idEmpleado : null,
        motivo: !tipoMovimientoSelected.length
          ? null
          : tipoMovimientoSelected.map((x) => x.id),
        observacion: observacionValue,
        monto_minimo: !montosValue.montoMinimo
          ? 0
          : Number(montosValue.montoMinimo),
        monto_maximo: !montosValue.montoMaximo
          ? 99999999
          : Number(montosValue.montoMaximo),
      },
    });
    result = response.data.data;
  } catch (error) {
    console.error(error);
  }
  return result;
}

const mediosDePagoFormated = (pago) => {
  if (pago.medio_pago && !pago.detalles_orden) {
    return pago.medio_pago;
  } else if (!pago.medio_pago && !pago.detalles_orden) {
    return "---";
  } else if (!pago.medio_pago && pago.detalles_orden) {
    let text = "";
    pago.tipo_registro === "orden_retiro_saldo"
      ? (text = "Orden de retiro")
      : (text = "Orden de pago");
    return text;
  }
};

const mediosDePagoDeOrden = (arrayDetalles) => {
  let arrayMediosDePago = arrayDetalles.map((a) => (
    <>
      {a.medio_pago}
      <br />
    </>
  ));
  return arrayMediosDePago;
};

const isDisabled = (mov) => {
  if (
    mov.tipo_movimiento === "Pagos de Comisiones" ||
    mov.tipo_movimiento === "Devolución" ||
    mov.tipo_movimiento === "Pagos de Venta"
  )
    return true;
};

const responsableDeOrden = (pago) => {
  let fullName = "";
  if (pago.responsable_carga) {
    fullName = `${pago.responsable_carga.nombre} ${pago.responsable_carga.apellido}`;
    return fullName;
  } else if (pago.responsable_retiro) {
    fullName = `${pago.responsable_retiro.nombre} ${pago.responsable_retiro.apellido}`;
    return fullName;
  } else if (pago.responsable_pago_cliente) {
    fullName = `${pago.responsable_pago_cliente.nombre} ${pago.responsable_pago_cliente.apellido}`;
    return fullName;
  }
};

const motivoPago = (pago) => {
  if (pago.venta && !pago.venta.has_factura && pago.carga_saldo) {
    return `${pago.tipo_movimiento} asociada a CI ${pago.venta.idVenta}`;
  } else if (pago.venta && pago.venta.has_factura && pago.carga_saldo) {
    return `${pago.tipo_movimiento} asociada a Factura ${pago.factura}`;
  } else {
    return pago.tipo_movimiento || "NA";
  }
};

export function formatPagos(
  mov,
  printPdf,
  deletePago,
  config,
  handleOpenModalCargarSaldo,
  handleOpenModalDetalleOrden,
  printPdfOrden,
) {
  return mov.map((pago) => ({
    fecha: moment(pago.fechaHora).format("DD-MM-YYYY HH:mm"),
    monto: `$${parseFloat(pago.monto).toLocaleString("es-AR")}`,
    tipo: (
      <div
        style={{
          display: "flex",
          alignContent: "center",
          justifyContent: "center",
          gap: 5,
        }}
      >
        {mediosDePagoFormated(pago)}

        {pago.detalles_orden && (
          <TooltipMoreInfoMaterial
            titleTooltip={mediosDePagoDeOrden(pago.detalles_orden)}
          />
        )}
      </div>
    ),
    descripcion: pago.observacion || "---",
    motivo: motivoPago(pago),
    responsable:
      pago.responsable_carga ||
      pago.responsable_retiro ||
      pago.responsable_pago_cliente
        ? responsableDeOrden(pago)
        : "---",
    estado: pago.fecha_anulacion ? "Anulado" : "Vigente",
    print: (
      <OptionsPrint
        mov={pago}
        printPdf={printPdf}
        deletePago={deletePago}
        config={config}
        handleOpenModalCargarSaldo={handleOpenModalCargarSaldo}
        handleOpenModalDetalleOrden={handleOpenModalDetalleOrden}
        printPdfOrden={printPdfOrden}
      />
    ),
  }));
}

const OptionsPrint = ({
  mov,
  printPdf,
  deletePago,
  config,
  handleOpenModalCargarSaldo,
  handleOpenModalDetalleOrden,
  printPdfOrden,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event, id) => {
    // setitemSeleted(id);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <>
        <Badge>
          <i
            onClick={(e) => handleClick(e, mov.idPagosCliente)}
            className="material-icons iconEdit"
            title="Imprimir comprobante"
          >
            print
          </i>
        </Badge>
        <StyledMenu
          keepMounted
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          onClick={handleClose}
        >
          {config && config.en_ticket && (
            <MenuItem
              onClick={() => {
                if (mov.tipo_registro.includes("orden")) {
                  printPdfOrden(
                    mov,
                    mov.carga_saldo === null
                      ? "ticketPagoDeuda"
                      : "billeteraTicket",
                  );
                } else {
                  printPdf(
                    mov,
                    mov.carga_saldo === null
                      ? "ticketPagoDeuda"
                      : "billeteraTicket",
                  );
                }
              }}
            >
              Ticket
            </MenuItem>
          )}
          {config && config.en_a4 && (
            <MenuItem
              onClick={() => {
                if (mov.tipo_registro.includes("orden")) {
                  printPdfOrden(
                    mov,
                    mov.carga_saldo === null ? "A4PagoDeuda" : "billeteraA4",
                  );
                } else {
                  printPdf(
                    mov,
                    mov.carga_saldo === null ? "A4PagoDeuda" : "billeteraA4",
                  );
                }
              }}
            >
              A4
            </MenuItem>
          )}
          {config && config.ahorro_papel && (
            <MenuItem
              onClick={() => {
                if (mov.tipo_registro.includes("orden")) {
                  printPdfOrden(
                    mov,
                    mov.carga_saldo === null ? "A4Ahorro" : "billeteraA4Ahorro",
                  );
                } else {
                  printPdf(
                    mov,
                    mov.carga_saldo === null ? "A4Ahorro" : "billeteraA4Ahorro",
                  );
                }
              }}
            >
              Imprimir A4 - Ahorro de papel
            </MenuItem>
          )}
        </StyledMenu>
      </>

      {mov.medio_pago === null && !mov.tipo_registro.includes("orden") && (
        <SvgIcon
          style={{ cursor: "pointer" }}
          titleAccess="Generar contramovimiento"
          onClick={() => handleOpenModalCargarSaldo(mov)}
        >
          <UndoIcon />
        </SvgIcon>
      )}

      {mov.detalles_orden && (
        <Badge>
          <i
            className="material-icons iconEdit"
            title="Ver detalles"
            style={{ cursor: "pointer" }}
            onClick={() => handleOpenModalDetalleOrden(mov)}
          >
            description
          </i>
        </Badge>
      )}

      {!mov.fecha_anulacion && (
        <>
          {isDisabled(mov) ? (
            <TooltipWithoutIcon title="Los pagos de venta, devoluciones o pagos de comisiones no se pueden anular">
              <span>
                <IconButton
                  title="Anular"
                  disabled
                  style={{ padding: 0, paddingTop: 3 }}
                >
                  <DeleteIcon color={"disabled"} />
                </IconButton>
              </span>
            </TooltipWithoutIcon>
          ) : (
            <IconButton title="Anular" style={{ padding: 0, paddingTop: 3 }}>
              <DeleteIcon color={"error"} onClick={() => deletePago(mov)} />
            </IconButton>
          )}
        </>
      )}
    </div>
  );
};
