import React from "react";
import { Text, View } from "@react-pdf/renderer";
import { styles } from "../../assets/css/styleA4";
import { LineA4Table, LineA4TableWhite } from "../raya";

export default function BodyTableSinPrecio({ descripcion, cant, noLine }) {
  return (
    <View style={{ paddingTop: 5 }}>
      <View style={styles.containerRowBodyTable}>
        <View style={(styles.containerColumn, { width: 450 })}>
          <Text style={styles.textLeftTable}>{descripcion || "---"}</Text>
        </View>
        <View style={(styles.containerColumn, { width: 65 })}>
          <Text style={styles.textCenterTable}>{cant}</Text>
        </View>
      </View>
      <View style={{ marginTop: -15 }}>
        {noLine ? <LineA4TableWhite /> : <LineA4Table />}
      </View>
    </View>
  );
}
