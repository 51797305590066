import React from "react";
import { Text, View } from "@react-pdf/renderer";
import { styles } from "../../assets/css/styleA4";
import { parseCurrency } from "../../../../../utils/parsers";
import { LineA4Table, LineA4TableWhite } from "../raya";

export default function BodyTableFactura({
  descripcion,
  cant,
  precio,
  iva,
  subtotal,
  noLine,
  remito,
}) {
  return (
    <View style={{ paddingTop: 5 }}>
      <View style={styles.containerRowBodyTable}>
        <View style={(styles.containerColumn, { width: 350 })}>
          <Text style={styles.textLeftTable}>{descripcion}</Text>
        </View>
        <View style={(styles.containerColumn, { width: 25 })}>
          <Text style={styles.textCenterTable}>{cant}</Text>
        </View>
        <View style={(styles.containerColumn, { width: 50 })}>
          {!remito ? (
            <Text style={styles.textCenterTable}>
              {precio !== ""
                ? `$ ${Number(precio).toLocaleString("es-AR")}`
                : ""}
            </Text>
          ) : (
            <Text style={styles.textCenterTable}>---</Text>
          )}
        </View>

        <View style={(styles.containerColumn2, { width: 50 })}>
          {!remito ? (
            <Text style={styles.textRightTable}>
              {iva !== "" ? parseCurrency(iva) : ""}
            </Text>
          ) : (
            <Text style={styles.textRightTable}>---</Text>
          )}
        </View>
        <View style={(styles.containerColumn2, { width: 50 })}>
          {!remito ? (
            <Text style={styles.textRightTable}>
              {subtotal === "" || subtotal !== "0.00"
                ? `$ ${Number(subtotal).toLocaleString("es-AR")}`
                : ""}
            </Text>
          ) : (
            <Text style={styles.textRightTable} />
          )}
        </View>
      </View>
      <View style={{ marginTop: -15 }}>
        {noLine ? <LineA4TableWhite /> : <LineA4Table />}
      </View>
    </View>
  );
}
