import React from "react";
import { Document, Page, Text, View } from "@react-pdf/renderer";
import { LineA4Table } from "../Components/raya";
import { parseDate } from "../../../../utils/parsers";
import { styles } from "../assets/css/styleA4";
import FirmaBoxer from "../Components/firmaBoxer";
import HeaderResumen from "../DeReporte/Components/HeaderResumen";
import HeaderDataCliente from "../DeReporte/Components/HeaderDataCliente";

// const FormatearMonto = ({ cliente }) => {
//   if (cliente.es_entrada) {
//     return (
//       <Text style={styles.text4Verde}>
//         {`+ ${parseCurrency(Number(cliente.monto_movimiento))}`}
//       </Text>
//     );
//   } else {
//     return (
//       <Text style={styles.text4Rojo}>
//         {`${parseCurrency(Number(cliente.monto_movimiento))}`}
//       </Text>
//     );
//   }
// };

export default function MovimientosCCProveedores({ data }) {
  const formatDebe = (movimiento) => {
    if (movimiento.tipo_movimiento === "Nota de Crédito") {
      let movimientoFormateado = Number(movimiento.monto_total).toFixed(2);
      return `$ ${Number(movimientoFormateado).toLocaleString("ES-ar")}`;
    } else if (movimiento.tipo_movimiento === "Orden de Pago") {
      let movimientoFormateado = Number(movimiento.monto_pago).toFixed(2);
      return `$ ${Number(movimientoFormateado).toLocaleString("ES-ar")}`;
    } else {
      return "";
    }
  };

  const formatHaber = (movimiento) => {
    if (movimiento.tipo_movimiento === "Compra") {
      let movimientoFormateado = Number(movimiento.saldo_actualizado).toFixed(
        2,
      );
      return `$ -${Number(movimientoFormateado).toLocaleString("ES-ar")}`;
    } else {
      return "";
    }
  };

  const columnsCtacte = [
    {
      width: 80,
      text: "FECHA",
    },
    {
      width: 100,
      text: "MOVIMIENTO",
    },
    {
      width: 100,
      text: "OBSERVACIÓN",
    },
    {
      width: 60,
      text: "DEBE",
    },
    {
      width: 60,
      text: "HABER",
    },
    {
      width: 60,
      text: "SALDO ACTUAL",
    },
  ];

  return (
    <Document title={"Reporte de Movimientos Cuenta Corriente Proveedor"}>
      <Page style={styles.page} size="A4">
        <View>
          <HeaderDataCliente
            razon_social={data.movimientos[0].proveedor.razonSocial}
            cuit={
              data.movimientos[0].proveedor.CUIT
                ? data.movimientos[0].proveedor.CUIT
                : "---"
            }
          />
          <HeaderResumen
            title={"MOVIMIENTOS"}
            subtitle={"CUENTA CORRIENTE PROVEEDOR"}
            tipoValue1={"Monto Cuenta Corriente"}
            tipoValue2={
              data.movimientos[0].proveedor.deuda > 0 ? "Deuda" : "A Favor"
            }
            value1={data.movimientos[0].proveedor.monto_cc.toLocaleString(
              "ES-ar",
            )}
            value2={data.movimientos[0].proveedor.deuda * -1}
            logoempresa={data.sucursal.boxer.url_imagen}
            sucursal={data.sucursal.razonSocial}
          />
          <View style={{ marginBottom: 20 }} fixed></View>

          <View fixed>
            <View style={styles.rowHeaderTable2}>
              {columnsCtacte.map((column, index) => (
                <View
                  key={index}
                  style={
                    (styles.containerColumn,
                    { width: column.width, textAlign: column.textAlign })
                  }
                >
                  <Text style={styles.text3}>{column.text}</Text>
                </View>
              ))}
            </View>
          </View>
          <View>
            <View style={styles.containerRowBodyTable}>
              <View style={(styles.containerColumn, { width: 80 })}>
                <Text style={styles.text4}></Text>
              </View>
              <View style={(styles.containerColumn, { width: 100 })}>
                <Text style={styles.text4}>Saldo Inicial</Text>
              </View>
              <View style={(styles.containerColumn, { width: 100 })}>
                <Text style={styles.text4}></Text>
              </View>
              <View style={(styles.containerColumn, { width: 60 })}>
                <Text style={styles.text4}></Text>
              </View>
              <View style={(styles.containerColumn, { width: 60 })}>
                <Text style={styles.text4}></Text>
              </View>
              <View style={(styles.containerColumn2, { width: 60 })}>
                <Text style={styles.text4}>$0</Text>
              </View>
            </View>
            <View style={{ marginTop: -15 }}>
              <LineA4Table />
            </View>
          </View>
          {data.movimientos.map((movimiento, index) => (
            <View key={index} style={{ paddingTop: 6 }}>
              <View style={styles.containerRowBodyTable}>
                <View style={(styles.containerColumn, { width: 80 })}>
                  <Text style={styles.text4}>
                    {movimiento.fecha_hora_pago
                      ? parseDate(
                          movimiento.fecha_hora_pago,
                          "DD/MM/YYYY HH:mm",
                        )
                      : parseDate(movimiento.fecha_alta, "DD/MM/YYYY HH:mm")}
                  </Text>
                </View>
                <View style={(styles.containerColumn, { width: 100 })}>
                  <Text style={styles.text4}>
                    {movimiento.tipo_movimiento}{" "}
                    {movimiento.descuento &&
                    movimiento.tipo_movimiento === "Orden de Pago"
                      ? `(${movimiento.descuento * 100}% desc.)`
                      : ""}
                  </Text>
                </View>
                <View style={(styles.containerColumn, { width: 100 })}>
                  <Text style={styles.text4}>
                    {movimiento.format_comprobante}
                  </Text>
                </View>

                <View style={(styles.containerColumn, { width: 60 })}>
                  <Text style={styles.text4}>{formatDebe(movimiento)}</Text>
                </View>
                <View style={(styles.containerColumn2, { width: 60 })}>
                  <Text style={styles.text4}>{formatHaber(movimiento)}</Text>
                </View>
                <View style={(styles.containerColumn2, { width: 60 })}>
                  <Text style={styles.text4}>
                    ${" "}
                    {Number(data.saldo_actual[index + 1]).toLocaleString(
                      "ES-ar",
                    )}
                  </Text>
                </View>
              </View>
              <View style={{ marginTop: -15 }}>
                <LineA4Table />
              </View>
            </View>
          ))}
        </View>
        <View
          style={{
            alignItems: "flex-end",
            position: "absolute",
            fontSize: 10,
            bottom: 65,
            left: 0,
            right: 0,
            width: "100%",
            color: "#1B71BA",
          }}
          // fixed
        >
          <View style={styles.containerRowTableEnd}>
            <View style={(styles.containerColumn, { width: 95 })}>
              <Text style={styles.textTotalesFactura}>{`MONTO CTA. CTE.`}</Text>

              {Number(data.movimientos[0].proveedor.deuda) !== 0 ? (
                <Text style={styles.textTotalesFactura}>
                  {data.movimientos[0].proveedor.deuda > 0
                    ? "DEUDA"
                    : "A FAVOR"}
                </Text>
              ) : null}
            </View>

            <View style={(styles.containerColumn, { width: 95 })}>
              <Text style={styles.textTotalesFactura}>
                $
                {Number(
                  data.movimientos[0].proveedor.monto_cc.toLocaleString(
                    "ES-ar",
                  ),
                ).toLocaleString("es-AR")}
              </Text>
              {Number(data.movimientos[0].proveedor.deuda) !== 0 ? (
                <Text style={styles.textTotalesFactura}>
                  $
                  {Number(
                    data.movimientos[0].proveedor.deuda * -1,
                  ).toLocaleString("es-AR")}
                </Text>
              ) : null}
            </View>
          </View>

          {/* <FirmaBoxer /> */}
        </View>

        <View
          style={{
            alignItems: "flex-end",
            position: "absolute",
            fontSize: 10,
            bottom: 20,
            left: 0,
            right: 0,
            width: "100%",
          }}
          fixed
        >
          <FirmaBoxer />
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
    // Made By Juan Urani
  );
}
