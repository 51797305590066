import React from "react";
import { View, Text } from "@react-pdf/renderer";
import NroFacturaA4 from "../../DeVentas/Components/nroFacturaA4";
import HeaderA4 from "./HeaderA4";
import { LineA4Color, LineA4Cut } from "../../Components/raya";
import { styles } from "../../assets/css/styleA4";
import FooterA4 from "../../Components/FooterA4";
import { fill } from "../../utils";
import { formatPagaCon } from "./utils";
import { parseCurrency } from "../../../../../utils/parsers";
import ResumenPagos from "./resumenPagos";

export default function BodyA4({ data, tipo, config }) {
  const nroComprobante = (data) => {
    if (data.detalle_ventas) {
      return data.idVenta;
    } else {
      return data.idVenta;
    }
  };

  return (
    <>
      <View>
        <NroFacturaA4
          title={"RECIBO DE VENTA"}
          nro={fill(nroComprobante(data.venta), 6)}
        />

        <HeaderA4 data={data} config={config} />

        <LineA4Color />

        <View style={styles.containerRow}>
          <View style={styles.containerColumn}>
            <Text style={styles.text2}>
              {data.venta.cliente
                ? `Recibí de: ${data.venta.cliente.razonSocial}`
                : "Consumidor final"}
            </Text>
          </View>
        </View>

        <View style={styles.containerRow}>
          <View style={styles.containerColumn}>
            <Text style={styles.text2}>{`La suma de: ${
              data.monto_letters
                ? data.monto_letters
                : data.monto_letters.replace("menos", "")
            }`}</Text>
          </View>
        </View>

        <LineA4Color />

        <View style={styles.containerRow}>
          <View style={styles.containerColumn}>
            <Text style={styles.text2}>{`En concepto de: PAGO DE VENTA`}</Text>
          </View>
        </View>

        <LineA4Color />

        {data.venta.pagos_cliente && (
          <View style={styles.containerRow}>
            <View style={styles.containerColumn}>
              <Text style={styles.text}>Pago con:</Text>
              {data.venta.pagos_cliente.map((d, i) => (
                <Text key={i} style={styles.text}>
                  {formatPagaCon(d)}
                </Text>
              ))}
            </View>
          </View>
        )}

        <View style={styles.containerRow}>
          <View style={(styles.containerColumn, { width: 250 })}>
            <Text style={styles.text}>{`Formas de Pago:`}</Text>
            <ResumenPagos data={data.pagos_cliente} styles={styles.text} />
          </View>
        </View>

        <LineA4Color />

        {data.venta.responsable_venta ? (
          <View style={styles.containerRow}>
            <View style={styles.containerColumn}>
              <Text
                style={styles.text2}
              >{`Responsable: ${data.venta.responsable_venta.nombre} ${data.venta.responsable_venta.apellido}`}</Text>
            </View>
          </View>
        ) : (
          "---"
        )}

        <View style={styles.containerRow}>
          <View style={styles.containerColumn}>
            <Text style={styles.text2}>
              {`Observaciones: ${data.venta.descripcion || "Ninguna"}`}
            </Text>
          </View>
        </View>

        <LineA4Color />

        <View style={styles.containerRow}>
          <View style={styles.containerColumn}>
            <Text style={styles.text2}>{`Recibí Conforme:__________ `}</Text>
          </View>
          <View style={styles.containerColumn}>
            <Text style={styles.text2}>{`Entregué Conforme:__________ `}</Text>
          </View>
          <View style={styles.containerColumn}>
            <Text style={styles.text2}>
              {`Total: ${parseCurrency(Number(data.venta.montoTotal))}`}
            </Text>
          </View>
        </View>

        <LineA4Color />
      </View>
      {tipo !== "reciboA4Ahorro" ? (
        <View
          style={{
            position: "absolute",
            bottom: 100,
            paddingLeft: 20,
            paddingRight: 20,
            left: 0,
            right: 0,
          }}
          fixed
        >
          <LineA4Color />
        </View>
      ) : null}
      <FooterA4
        fixed={tipo !== "reciboA4Ahorro" ? true : false}
        sucursal={data.sucursal}
      />
      {tipo === "reciboA4Ahorro" && (
        <View>
          <LineA4Cut />
        </View>
      )}
    </>
  );
}
