export const CLIENT_TABLE_COLUMNS = (tienePermisosCliente) =>
  tienePermisosCliente
    ? [
        "Nro.",
        "Última compra",
        "Último pago",
        "Cliente",
        "Estado",
        "CUIT/DNI",
        "Saldo",
        "--",
      ]
    : [
        "Nro.",
        "Última compra",
        "Último pago",
        "Cliente",
        "Estado",
        "CUIT/DNI",
        "--",
      ];
