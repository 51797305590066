import React from "react";
import { Document, Page, View, Text, Image } from "@react-pdf/renderer";
import moment from "moment";
// import logoB from "../../../../images/logos boxer.png";
import { styles } from "../assets/css/styleTicket";
import { parseCurrency } from "../../../../utils/parsers";

export function MyDocumentTicket({ data }) {
  return (
    <Document title={`${data.leyenda}`}>
      <Page
        /**-- por defecto la unidad de medida es pt 226.77 es proporcional a 80mm y
         * cercano al tipo de hoja C7 este es de 81mm
         * https://github.com/diegomura/react-pdf/blob/master/src/utils/pageSizes.js
         */
        style={styles.page}
        size={{ width: 226.77, minHeight: 400 }}
      >
        {data.map((data, index) => (
          <View key={index}>
            <View
              style={{
                justifyContent: "center",
                paddingTop: 10,
                flexDirection: "row",
              }}
            >
              {data.logo ? (
                <Image
                  cache={false}
                  style={{ width: 100, height: 60 }}
                  allowDangerousPaths={true}
                  src={`data:image/png/jpg;base64,${data.logo}`}
                />
              ) : null}
            </View>
            <View
              style={{
                justifyContent: "center",
                paddingBottom: 10,
                flexDirection: "row",
              }}
            >
              <Text style={styles.text}>{data.empresa.razonSocial}</Text>
            </View>
            <View style={styles.containerRow}>
              <View style={styles.containerColumn}>
                <Text style={styles.text}>COMPROBANTE DE PAGO</Text>
                <Text style={styles.text}>{`FECHA DE EMISIÓN: ${moment(
                  data.fecha_emision,
                ).format("DD/MM/YY HH:mm")}`}</Text>
                <Text style={styles.text}>{data.empresa.domicilio}</Text>
                <Text style={styles.text}>{`INGRESOS BRUTOS: ${
                  data.empresa.ingresosBrutos || "0.00"
                }`}</Text>
                <Text style={styles.text}>{`CUIT: ${
                  data.empresa.CUIT || "---"
                }`}</Text>
                <Text style={styles.text}>{`TE: ${
                  data.empresa.telefonoContacto || "---"
                }`}</Text>
              </View>
            </View>
            <View>
              <Text style={{ marginLeft: 10, marginRight: 10 }}>
                --------------------------------
              </Text>
            </View>
            <View style={styles.containerRow}>
              <View style={(styles.containerColumn, { width: 100 })}>
                <Text style={styles.textbold}>
                  {data.factura.idTipoFactura === "X"
                    ? "Cuenta Corriente"
                    : `Factura ${data.factura.idTipoFactura.nombre}`}
                </Text>
              </View>
              <View style={styles.containerColumn2}>
                {data.factura.nroFactura && (
                  <Text
                    style={styles.text}
                  >{`${data.factura.nroFactura}`}</Text>
                )}
                {data.factura.fechaHoraFactura && (
                  <Text style={styles.text}>{`${moment(
                    data.factura.fechaHoraFactura,
                  ).format("DD/MM/YYYY HH:mm")}`}</Text>
                )}
              </View>
            </View>
            <View>
              <Text style={{ marginLeft: 10, marginRight: 10 }}>
                --------------------------------
              </Text>
            </View>
            <View style={styles.containerRow}>
              <View style={styles.containerColumn}>
                <Text style={styles.text}>{`Cliente: ${
                  data.cliente ? data.cliente.razonSocial : "Consumidor Final"
                }`}</Text>
                <Text style={styles.text}>{`CUIT: ${
                  data.cliente.CUIT || "---"
                }`}</Text>

                <Text style={styles.text}>{`Domicilio: ${
                  data.cliente ? data.cliente.domicilio : "---"
                }`}</Text>
                <Text
                  style={styles.text}
                >{`Vend: ${data.empleado.nombre} ${data.empleado.apellido}`}</Text>

                <Text style={styles.text}>{`${data.forma_pago || "---"}`}</Text>

                <Text style={styles.text}>{`Observaciones: ${
                  data.observacion || "Ninguna"
                }`}</Text>

                <Text style={styles.text}>{`Observaciones de pago: ${
                  (data.pago &&
                    data.pago.idPago &&
                    data.pago.idPago.observacion) ||
                  "Ninguna"
                }`}</Text>
              </View>
            </View>
            <View>
              <Text style={{ marginLeft: 10, marginRight: 10 }}>
                --------------------------------
              </Text>
            </View>
            <View style={styles.containerRow}>
              <View style={styles.containerColumn}>
                <Text style={styles.textbold}>Cód. Artículo</Text>
                <Text style={styles.textbold}>{`Descripción(%IVA)`}</Text>
                <Text style={styles.textbold}>Cant/Precio Unit.</Text>
              </View>
              <View style={styles.containerColumn2}>
                <Text style={styles.textbold}>IMPORTE</Text>
              </View>
            </View>
            <View>
              <Text style={{ marginLeft: 10, marginRight: 10 }}>
                --------------------------------
              </Text>
            </View>

            {data.detalle[0].detalle_ventas.map((x) => (
              <View style={styles.containerRow}>
                <View style={styles.containerColumn}>
                  <Text style={styles.textDescriptionItem}>
                    {`${
                      x.repuesto
                        ? x.repuesto.codigo
                        : "-" +
                          x.descripcion.substr(0, 30).trim() +
                          (x.descripcion.length > 30 ? "..." : "")
                    }`}
                  </Text>
                  <Text style={styles.text}>
                    {parseFloat(x.cantidad).toFixed(3)} {" x "}
                    {`$ ${Number(
                      parseFloat(x.precio_unitario).toFixed(2),
                    ).toLocaleString("es-AR")}`}
                  </Text>
                  <Text style={styles.text}>{`IVA:${
                    x.porcentaje * 100
                  }%`}</Text>
                </View>
                <View style={styles.containerColumn}>
                  <Text style={styles.text}>
                    {`$ ${Number(
                      parseFloat(x.subtotal).toFixed(2),
                    ).toLocaleString("es-AR")}`}
                  </Text>
                </View>
              </View>
            ))}

            <View>
              <Text style={{ marginLeft: 10, marginRight: 10 }}>
                --------------------------------
              </Text>
            </View>
            <View style={styles.containerRow}>
              <View style={styles.containerColumn}>
                <Text style={styles.text}>{`CONCILIACIÓN Nº ${
                  (data.pago &&
                    data.pago.idPago &&
                    data.pago.idPago.idPagosCliente) ||
                  ""
                }`}</Text>
              </View>
            </View>
            <View style={styles.containerRow}>
              <View style={styles.containerColumn}>
                <Text style={styles.textbold}>PAGO ACTUAL</Text>
                <Text style={styles.textbold}>DESCUENTO</Text>
                <Text style={styles.textbold}>DEUDA</Text>
                <Text style={styles.textbold}>PAGOS ACUMULADOS</Text>
              </View>
              <View style={styles.containerColumn2}>
                <Text style={styles.text}>{parseCurrency(data.recibimos)}</Text>
                <Text style={styles.text}>
                  {parseCurrency(data.detalle[0].descuento_plazos || 0)}
                </Text>
                <Text style={styles.text}>
                  {parseCurrency(
                    Number(
                      data.detalle[0].descuento_plazos !== null
                        ? 0
                        : data.saldo,
                    ),
                  )}
                </Text>
                <Text style={styles.text}>{parseCurrency(data.sumaPagos)}</Text>
              </View>
            </View>
            <View style={{ margin: "10px 10px 30px 10px" }}>
              <View>
                <Text>--------------------------------</Text>
              </View>
            </View>
          </View>
        ))}
      </Page>
    </Document>
  );
}
