import React, { useMemo } from "react";
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
} from "@tanstack/react-table";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import { TextField } from "@material-ui/core";
import styles from "./styles.module.css";

export default function TableComponent({ data, setTableData }) {
  const handleDeleteRow = (rowIndex) => {
    setTableData((prevData) =>
      prevData.filter((_, index) => index !== rowIndex),
    );
  };

  const handleEditCantidadEtiquetas = (rowIndex, value) => {
    setTableData((prevData) =>
      prevData.map((row, index) =>
        index === rowIndex ? { ...row, cantidadEtiquetas: value } : row,
      ),
    );
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "codProveedor",
        header: "Código de proveedor",
      },
      {
        accessorKey: "codOriginal",
        header: "Código original",
      },
      {
        accessorKey: "descripcionProveedor",
        header: "Descripción del artículo",
      },
      {
        accessorKey: "proveedor",
        header: "Proveedor del artículo",
      },
      {
        accessorKey: "cantidadEtiquetas",
        header: "Cantidad de etiquetas",
        cell: ({ row }) => (
          <div className={styles.centerContent}>
            <TextField
              type="number"
              variant="outlined"
              inputProps={{
                min: 1,
                "aria-label": "Cantidad de etiquetas",
                style: { textAlign: "center" },
              }}
              value={row.original.cantidadEtiquetas}
              onChange={(e) =>
                handleEditCantidadEtiquetas(row.index, e.target.value)
              }
              size="small"
              style={{ width: "80px" }}
            />
          </div>
        ),
      },
      {
        id: "acciones",
        header: "Acciones",
        cell: ({ row }) => (
          <div className={styles.centerContent}>
            <IconButton
              onClick={() => handleDeleteRow(row.index)}
              color="secondary"
              size="small"
              style={{ padding: 6 }}
              aria-label="Eliminar"
            >
              <DeleteIcon />
            </IconButton>
          </div>
        ),
      },
    ],
    [],
  );

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <table className={styles.table}>
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th key={header.id} className={styles.tableHeader}>
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => (
          <tr key={row.id} className={styles.tableRow}>
            {row.getVisibleCells().map((cell) => (
              <td key={cell.id} className={styles.tableCell}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
