import React from "react";
import { Text, View } from "@react-pdf/renderer";
import { styles } from "../../assets/css/styleA4";

export default function HeaderTable({ columns, fixed }) {
  return (
    <View style={styles.rowHeaderTable} fixed={fixed ? true : false}>
      {columns.map((column, index) => (
        <View
          key={index}
          style={
            (styles.containerColumn,
            { width: column.width, textAlign: column.textAlign })
          }
        >
          <Text style={styles.text3}>{column.text}</Text>
        </View>
      ))}
    </View>
  );
}
