import {
  Backdrop,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import ButtonAceptar from "../Button/ButtonAceptar";
import ButtonCancelar from "../Button/ButtonCancelar";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("sm")]: {
      minWidth: "500px",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function ModalConfirm({
  open,
  handleClose,
  handleSubmit,
  title,
  body,
  buttons,
  disabled,
  openBackdrop,
  showButtonAceptar = true,
}) {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      classes={{ paper: classes.root }}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText align="center" id="alert-dialog-description">
          {body}
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions style={{ justifyContent: "center" }}>
        <ButtonCancelar click={handleClose} message={buttons.cancel} />
        {showButtonAceptar && (
          <ButtonAceptar
            disabled={disabled || false}
            click={handleSubmit}
            message={buttons.submit}
          />
        )}
      </DialogActions>
      {openBackdrop && (
        <Backdrop className={classes.backdrop} open={openBackdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </Dialog>
  );
}
