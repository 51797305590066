import {
  Backdrop,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { updateCliente } from "../../Redux/Actions/presupuestoActions";
import {
  errorNotification,
  successNotification,
} from "../../components/Notifications";
import {
  getBlobPdf,
  imprimirPdf,
} from "../../components/ait-reusable/HandlePdf/handlePdf";
import ModalConfirm from "../../components/ait-reusable/ModalConfirm/ModalConfirm";
import ModalWhatsApp from "../../components/ait-reusable/ModalWhatsApp/ModalWhatsApp";
import { getPdfBlob } from "../../components/ait-reusable/ModalWhatsApp/uploadPdf";
import { Showmodal } from "../../components/ait-reusable/PDF/Components/PreviewPdf";
import ReportTable from "../../components/ait-reusable/Report/ReportTable";
import PageTitle from "../../components/common/PageTitle";
import { PERMISOS } from "../../constantes/permisos";
import { useDebounce } from "../../customHooks/useDebounce";
import request from "../../requests/request";
import {
  informeCtaCte,
  informeVtasAdeudas,
  modificarCuentaCorriente,
} from "../../requests/urls";
import { firtDayCurrenMonth, lastDayCurrenMonth } from "../../utils/dates";
import { tienePermisos } from "../../utils/tienePermisos";
import BodyModalBajaCliente from "./BodyModalBajaCliente";
import { Filters } from "./Components/filters";
import { ModalPeriodo } from "./Components/modals";
import DeudasTotales from "./DeudasTotales";
import FooterInfoCliente from "./FooterInfoCliente";
import HeaderCliente from "./HeaderCliente";
import InfoCliente from "./InfoCliente";
import "./assets/styles.css";
import { CLIENT_TABLE_COLUMNS } from "./constants";
import { imprimirInformeService } from "./cuentaCorrienteServices";
import { deleteCliente, fetchClients } from "./services";
import { useClientFilterStore, useClientSearchStore } from "./store";

import {
  BodyModalCuentaCorriente,
  filterCliente,
  formatClientes,
} from "./utils";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const Clientes = (props) => {
  const { filters } = useClientFilterStore();
  const {
    search,
    searchMethod,
    selectedClientData,
    setSearch,
    setSearchMethod,
    setSelectedClientData,
    resetSelectedClientData,
  } = useClientSearchStore();

  const classes = useStyles();
  const dispatch = useDispatch();
  const { cliente } = useSelector((state) => state.presupuesto);

  const [clientes, setClientes] = useState([]);
  const [preview, setPreview] = useState(false);
  const [movimientos, setMovimientos] = useState(null);
  const [openBackdrop, setOpenBackdrop] = useState(null);
  const [modalPeriodo, setModalPeriodo] = useState(false);
  const [openModalBaja, setOpenModalBaja] = useState(false);
  const [modalWhatsapp, setModalWhatsapp] = useState(false);
  const [movCuentaCorriente, setMovCuentaCorriente] = useState([]);
  const [loadingMovimientos, setLoadingMovimientos] = useState(false);
  const [loadingCuentaCorriente, setLoadingCuentaCorriente] = useState(false);
  const [openModalCuentaCorriente, setOpenModalCuentaCorriente] =
    useState(false);
  const [datapdf, setDatapdf] = useState({
    ctacte: null,
    vtas: null,
  });
  const [dates, setDates] = useState({
    fromDate: firtDayCurrenMonth(),
    toDate: lastDayCurrenMonth(),
  });
  const [pagination, setPagination] = useState({
    page: 1,
    num_pages: 0,
    num_items: 0,
    next_page: null,
  });
  const [dataPdfFetched, setDataPdfFetched] = useState(null);
  const [urlPdf, setUrlPdf] = useState(null);
  const [openModalWhatsappPDF, setOpenModalWhatsappPDF] = useState(false);

  const { configGeneral } = useSelector((state) => state.configGeneral);
  const { empleado } = useSelector((state) => state.loginReducer);

  const debouncedSearchTerm = useDebounce(search, 350);
  const tienePermisosCliente = tienePermisos(empleado, PERMISOS.CLIENTES);

  const { isFetching, refetch } = useQuery(
    ["clientes", debouncedSearchTerm, pagination.page],
    ({ signal }) =>
      fetchClients(filters, debouncedSearchTerm, pagination, signal),
    {
      onError: () => {
        errorNotification(
          "Ocurrió un error al obtener la lista de clientes. Intente nuevamente.",
        );
      },
      onSuccess: (data) => {
        setClientes(data.items);
        setPagination((prev) => ({
          ...prev,
          num_items: data.num_items,
          next_page: data.next_page,
        }));
      },
      retry: true,
    },
  );

  const toggle = () => {
    setDates({
      ...dates,
      fromDate: firtDayCurrenMonth(),
      toDate: lastDayCurrenMonth(),
    });
    setPreview(false);
    setMovimientos(null);
  };

  const toggleModalBaja = () => setOpenModalBaja(!openModalBaja);

  const handleClickCliente = (c) => {
    setSelectedClientData(filterCliente(c.id, clientes));
  };

  const handlePutCuentaCorriente = async () => {
    setLoadingCuentaCorriente(true);
    try {
      const response = await request({
        method: "PUT",
        url: modificarCuentaCorriente(selectedClientData.idClienteDeSucursal),
        data: {
          cuenta_corriente: Number(!selectedClientData.cuenta_corriente),
        },
      });
      handleResponsePutCuentaCorriente(response);
      successNotification("Cuenta corriente modificada exitosamente.");
    } catch (_err) {
      errorNotification("No se ha podido actualizar.");
      setLoadingCuentaCorriente(false);
    }
  };

  const handleResponsePutCuentaCorriente = (response) => {
    const { status } = response;
    if (status === 201) {
      setSelectedClientData({
        ...selectedClientData,
        cuenta_corriente: response.data.data.cuenta_corriente,
      });
      setLoadingCuentaCorriente(false);
      setOpenModalCuentaCorriente(false);
      updateCuentaCorrienteEnVentas(response);
    } else {
      setLoadingCuentaCorriente(false);
    }
  };

  const updateCuentaCorrienteEnVentas = (response) => {
    const newClient = {
      ...cliente,
      cuenta_corriente: response.data.data.cuenta_corriente,
    };
    if (cliente) {
      if (
        cliente.idClienteDeSucursal === selectedClientData.idClienteDeSucursal
      ) {
        dispatch(updateCliente(newClient));
      }
    }
  };

  const handleSearch = (value) => {
    setPagination((prev) => ({ prev, page: 1 }));
    setSearch(value);
    resetSelectedClientData();
  };

  const handleCloseModalCuentaCorriente = () => {
    setOpenModalCuentaCorriente(!openModalCuentaCorriente);
  };

  const printInforme = async (idCliente) => {
    const token = localStorage.getItem("token");
    const defaultHeaders = { Authorization: `Token ${token}` };
    await axios
      .all([
        axios.get(informeCtaCte(idCliente), { headers: defaultHeaders }),
        axios.get(informeVtasAdeudas(idCliente), { headers: defaultHeaders }),
      ])
      .then(
        axios.spread((response_ctacte, response_vtas) => {
          if (response_ctacte.status === 200 && response_vtas.status === 200) {
            setDatapdf({
              ctacte: response_ctacte.data,
              vtas: response_vtas.data,
            });
            setPreview(true);
          }
        }),
      )
      .catch((error) => console.error(error));
  };

  const handleImprimirInforme = async (formato) => {
    setLoadingMovimientos(true);

    try {
      const response = await imprimirInformeService(selectedClientData, dates);
      setMovCuentaCorriente(response.data);
      if (response.data.movimientos.length) {
        setModalPeriodo(false);
        setLoadingMovimientos(false);
        setOpenBackdrop(true);

        let newData = { ...response.data, periodo: dates };

        if (formato === "print") {
          const blobPdf = await getBlobPdf("cuentaCorrienteCliente", newData);
          if (configGeneral.previsualizacion) {
            imprimirPdf(blobPdf);
          } else {
            setMovimientos(true);
            imprimirPdf(blobPdf);
          }
          setOpenBackdrop(false);
        } else {
          handleOpenModalPdf(newData);
        }
      }
      if (!response.data.movimientos.length) {
        errorNotification("No hay movimientos para mostrar");
        setLoadingMovimientos(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleOpenModalPdf = (newData) => {
    setDataPdfFetched(newData);
  };

  const handleOpenModalWhatsApp = (cliente) => {
    setModalWhatsapp(true);
    setSelectedClientData(cliente);
  };

  const onCloseModalPeriodo = () => {
    setDates({
      ...dates,
      fromDate: firtDayCurrenMonth(),
      toDate: lastDayCurrenMonth(),
    });
    setModalPeriodo(false);
  };

  useEffect(() => {
    if (movimientos) {
      setModalPeriodo(false);
    }
  }, [movimientos]);

  useEffect(() => {
    if (selectedClientData && searchMethod === "search") {
      setPagination((prev) => ({ ...prev, page: 1 }));
      setSearch(selectedClientData.idCliente.razonSocial);
    } else {
      resetSelectedClientData();
      setSearchMethod(null);
    }
  }, []);

  useEffect(() => {
    if (urlPdf) {
      setOpenModalWhatsappPDF(true);
    } else if (dataPdfFetched) {
      getPdfBlob("cuentaCorrienteCliente", dataPdfFetched, configGeneral).then(
        (res) => {
          if (res) {
            setUrlPdf(res.secure_url);
          }
          setOpenBackdrop(false);
        },
      );

      setDataPdfFetched(null);
    }
  }, [urlPdf, dataPdfFetched]);

  const columns = CLIENT_TABLE_COLUMNS(tienePermisosCliente);

  return (
    <Grid className="px-4">
      <Grid container spacing={2} className="page-columns py-4">
        <PageTitle
          sm={4}
          title="Cliente"
          subtitle="Listado CLIENTES"
          className="text-sm-left"
        />
      </Grid>

      <Grid item xs={12} style={{ marginBottom: "0.5rem" }}>
        <Filters getClients={refetch} resetClient={resetSelectedClientData} />
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={9} lg={9}>
          <ReportTable
            className={"pointer-table"}
            loading={isFetching}
            items={formatClientes(
              clientes,
              props.history,
              printInforme,
              setModalPeriodo,
              setSelectedClientData,
              handleOpenModalWhatsApp,
              tienePermisosCliente,
            )}
            columns={columns}
            pagination={pagination}
            setPagination={setPagination}
            handleClickRow={handleClickCliente}
          >
            <HeaderCliente
              search={search}
              handleSearch={handleSearch}
              debouncedSearchTerm={debouncedSearchTerm}
              filters={filters}
              props={props}
              empleado={empleado}
            />
          </ReportTable>
        </Grid>

        <Grid item xs={12} sm={12} lg={3} md={3} className={"text-center"}>
          {tienePermisosCliente && <DeudasTotales />}
          <Card>
            <CardContent>
              <Typography variant="h6">Detalles cliente</Typography>
              {selectedClientData && (
                <div style={{ borderBotton: 0 }}>
                  <InfoCliente
                    comision={selectedClientData.comision}
                    cliente={selectedClientData}
                    descuento={selectedClientData.descuento_prefijado}
                    saldo={
                      parseFloat(selectedClientData.monto_billetera) -
                      parseFloat(selectedClientData.deuda)
                    }
                  />
                  {selectedClientData && (
                    <FooterInfoCliente
                      {...props}
                      history={props.history}
                      cliente={selectedClientData}
                      getClientes={refetch}
                      setCliente={handleClickCliente}
                      toggle={toggleModalBaja}
                      handlePutCuentaCorriente={handlePutCuentaCorriente}
                      loadingCuentaCorriente={loadingCuentaCorriente}
                      setOpenModalCuentaCorriente={setOpenModalCuentaCorriente}
                      tienePermisosCliente={tienePermisosCliente}
                    />
                  )}
                </div>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {openModalBaja && selectedClientData && (
        <ModalConfirm
          title={`Baja de cliente Nº${selectedClientData.idClienteDeSucursal}`}
          open={openModalBaja}
          handleClose={toggleModalBaja}
          handleSubmit={() =>
            deleteCliente(
              selectedClientData,
              toggleModalBaja,
              resetSelectedClientData,
            )
          }
          body={<BodyModalBajaCliente cliente={selectedClientData} />}
          buttons={{ cancel: "CANCELAR", submit: "CONFIRMAR" }}
        />
      )}

      {movimientos && (
        <Showmodal
          preview={movimientos}
          toggle={toggle}
          data={movCuentaCorriente}
          tipo={"cuentaCorrienteCliente"}
        />
      )}

      {preview && (
        <Showmodal
          tipo={"reporte A4 ctacte"}
          preview={preview}
          toggle={toggle}
          data={datapdf}
        />
      )}

      {openModalCuentaCorriente &&
        selectedClientData &&
        (selectedClientData.deuda !== 0 ||
          selectedClientData.monto_billetera !== "0.00") && (
          <ModalConfirm
            title={`${
              selectedClientData.cuenta_corriente ? "Desactivar" : "Activar"
            } la cuenta corriente del cliente N°${
              selectedClientData.idClienteDeSucursal
            }`}
            open={openModalCuentaCorriente}
            handleClose={handleCloseModalCuentaCorriente}
            handleSubmit={handlePutCuentaCorriente}
            body={<BodyModalCuentaCorriente cliente={selectedClientData} />}
            buttons={{ cancel: "CANCELAR", submit: "CONFIRMAR" }}
            disabled={loadingCuentaCorriente}
          />
        )}

      {modalPeriodo && (
        <ModalPeriodo
          dates={dates}
          setDates={setDates}
          modalPeriodo={modalPeriodo}
          onClose={onCloseModalPeriodo}
          setModalPeriodo={setModalPeriodo}
          loadingMovimientos={loadingMovimientos}
          handleImprimirInforme={handleImprimirInforme}
        />
      )}

      {modalWhatsapp && (
        <ModalWhatsApp
          open={modalWhatsapp}
          close={() => setModalWhatsapp(false)}
          telefono={selectedClientData.idCliente.telefonoContacto}
          nombre={selectedClientData.idCliente.razonSocial}
          editar={`/clientes/modificar/${selectedClientData.idClienteDeSucursal}/`}
        />
      )}

      {openBackdrop && (
        <Backdrop className={classes.backdrop} open={openBackdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      {openModalWhatsappPDF && (
        <ModalWhatsApp
          open={openModalWhatsappPDF}
          close={() => {
            setOpenModalWhatsappPDF(false);
            resetSelectedClientData();
            setOpenBackdrop(false);
            setUrlPdf(null);
            setDataPdfFetched(null);
          }}
          urlPdf={urlPdf}
          telefono={
            selectedClientData && selectedClientData.idCliente.telefonoContacto
          }
          nombre={
            selectedClientData && selectedClientData.idCliente.razonSocial
          }
          mensajePdf={`¡Hola${
            selectedClientData && selectedClientData.idCliente.razonSocial
              ? ` ${selectedClientData.idCliente.razonSocial}!`
              : "!"
          } Te envíamos a continuación el reporte de cuenta corriente y ventas adeudadas en formato PDF. Por favor Hace click en el siguiente link para visualizarlo: `}
          editar={`/clientes/modificar/${selectedClientData.idClienteDeSucursal}/`}
        />
      )}
    </Grid>
  );
};

export default Clientes;
