import React from "react";
import { Document, Page, Text, View } from "@react-pdf/renderer";
import { formatArrayComprobantes, getTotalImpactado } from "./util";
import { LineA4Table } from "../raya";
import { styles } from "../../assets/css/styleA4";
import FirmaBoxer from "../firmaBoxer";
import HeaderResumen from "../../DeReporte/Components/HeaderResumen";
import TotalesPagos from "./TotalesPagos";
import {
  formatCurrency,
  parseCurrency,
  parseDate,
} from "../../../../../utils/parsers";

export default function PagosDeCompras({ data, config, sucursal }) {
  const validarDetalles = (compra) => {
    if (compra.medio_pago.nombre === "Tarjeta Débito") {
      return compra.medio_pago.numero_autorizacion;
    } else if (compra.medio_pago.nombre === "Efectivo") {
      return "";
    } else if (compra.medio_pago.nombre === "Tarjeta Crédito") {
      return compra.monto_medio_pago.numero_autorizacion;
    } else if (compra.medio_pago.nombre === "Cuenta Corriente") {
      return "";
    } else if (compra.medio_pago.nombre === "Cheque") {
      return `${
        compra.detalles_pago_compra_cheque[0].numero_cheque
          ? compra.detalles_pago_compra_cheque[0].numero_cheque
          : ""
      } ${
        compra.detalles_pago_compra_cheque[0].referencia
          ? compra.detalles_pago_compra_cheque[0].referencia
          : ""
      }`;
    } else if (compra.medio_pago.nombre === "Transferencia Bancaria") {
      return `${compra.detalles_pago_compra_transferencia[0].id} - ${compra.detalles_pago_compra_transferencia[0].banco}`;
    }
  };

  const montoDeuda = getTotalImpactado(data);

  const columnasPagos = [
    {
      width: 80,
      text: "FECHA",
    },
    {
      width: 100,
      text: "VALORES",
    },
    {
      width: 100,
      text: "DETALLES",
    },
    {
      width: 80,
      text: "IMPORTE",
    },
  ];

  // const columnasRetenciones = [
  //   {
  //     width: 80,
  //     text: "FECHA",
  //   },
  //   {
  //     width: 100,
  //     text: "VALORES",
  //   },
  //   {
  //     width: 100,
  //     text: "DETALLES",
  //   },
  //   {
  //     width: 80,
  //     text: "IMPORTE",
  //   },
  // ];

  const columnasComprobantes = [
    {
      width: 80,
      text: "FECHA",
    },
    {
      width: 140,
      text: "COMPROBANTES",
    },
    {
      width: 80,
      text: "TOTAL FACTURA",
    },
    // {
    //   width: 60,
    //   text: "SALDO",
    // },
    {
      width: 80,
      text: "A PAGAR",
    },
  ];

  const dataRetencion =
    data.retencion_pago_compra.length > 0 ? data.retencion_pago_compra : null;

  return (
    <Document title={"Reporte de Cuenta Corriente y Ventas Adeudadas"}>
      <Page style={styles.page} size="A4">
        <View>
          <View style={styles.containerRow} fixed>
            <View style={styles.containerColumnLeft}>
              {!config.ocultar_datos_ci ? (
                <Text
                  style={{
                    fontWeight: 600,
                    width: 200,
                    fontSize: 12,
                    marginBottom: 5,
                    color: "#1B71BA",
                    textTransform: "uppercase",
                  }}
                >
                  {sucursal && sucursal.razonSocial ? sucursal.razonSocial : ""}
                </Text>
              ) : null}
              <Text style={{ fontSize: 12, fontWeight: "extrabold" }}>
                Fecha: {parseDate(data.fecha_hora_pago, "DD/MM/YY HH:mm")}
              </Text>
              <Text style={{ fontSize: 12, fontWeight: "extrabold" }}>
                Orden de Pago: {data.orden_pago}
              </Text>
              {!config.ocultar_datos_ci ? (
                <Text style={{ fontSize: 12, fontWeight: "extrabold" }}>
                  CUIT: {sucursal && sucursal.CUIT}
                </Text>
              ) : null}
              {!config.ocultar_datos_ci ? (
                <Text style={{ fontSize: 12, fontWeight: "extrabold" }}>
                  Ing. Brutos:{" "}
                  {`${(sucursal && sucursal.ingresos_brutos) || "N/A"}`}
                </Text>
              ) : null}
            </View>
          </View>
          <HeaderResumen
            title={`Pago de compra realizado a ${data.proveedor.razonSocial}`}
            subtitle={data.proveedor.CUIT ? `CUIT: ${data.proveedor.CUIT}` : ""}
            tipoValue1={"Importe Pagos"}
            tipoValue2={"Importe Comprobantes"}
            value1={data.detalles_pago_compra[0].monto_medio_pago}
            value2={data.monto_pago}
            config={config}
            logoempresa={config && config.url_imagen ? config.url_imagen : null}
            sucursal={sucursal}
          />
          <View style={{ marginBottom: 5 }} fixed></View>
        </View>
        <View style={{ marginBottom: 5, marginTop: 20 }} fixed></View>

        <View fixed>
          <Text style={{ fontSize: 16, paddingBottom: "4px" }}>
            Comprobantes
          </Text>
          <View style={styles.rowHeaderTable2}>
            {columnasComprobantes.map((column, index) => (
              <View
                key={index}
                style={
                  (styles.containerColumn,
                  { width: column.width, textAlign: column.textAlign })
                }
              >
                <Text style={styles.text3}>{column.text}</Text>
              </View>
            ))}
          </View>
        </View>
        {formatArrayComprobantes(data.pago, data).map((x, index) => (
          <View key={index}>
            <View style={styles.containerRowBodyTable}>
              <View style={(styles.containerColumn, { width: 80 })}>
                <Text style={styles.text4}>
                  {parseDate(x.fecha, "DD/MM/YY")}
                </Text>
              </View>
              <View style={(styles.containerColumn, { width: 140 })}>
                <Text style={styles.text4}>{x.compronante}</Text>
              </View>
              <View style={(styles.containerColumn, { width: 80 })}>
                <Text style={styles.text4}>{x.totalFactura}</Text>
              </View>
              {/* <View style={(styles.containerColumn, { width: 60 })}>
                <Text style={styles.text4}>{x.saldo}</Text>
              </View> */}
              <View style={(styles.containerColumn, { width: 80 })}>
                <Text style={styles.text4}>{x.pagado}</Text>
              </View>
            </View>
            <View style={{ marginTop: -15, paddingBottom: 5 }}>
              <LineA4Table />
            </View>
          </View>
        ))}
        <View>
          <View style={styles.containerRowBodyTable}>
            <View
              style={
                (styles.containerColumn, { width: data.descuento ? 70 : 80 })
              }
            >
              <Text style={styles.text4} />
            </View>
            <View
              style={
                (styles.containerColumn, { width: data.descuento ? 70 : 80 })
              }
            >
              <Text style={styles.text4} />
            </View>
            <View
              style={
                (styles.containerColumn, { width: data.descuento ? 70 : 80 })
              }
            >
              <Text style={styles.text4} />
            </View>
            <View
              style={
                (styles.containerColumn,
                { width: data.descuento ? 200 : 150, paddingTop: 10 })
              }
            >
              <Text style={styles.textTotalesPagoCompra}>
                TOTAL: {`${parseCurrency(montoDeuda)}`}
                {data.descuento
                  ? ` (${Number(data.descuento * 100).toFixed(2)}% desc.)`
                  : " "}
              </Text>
            </View>
          </View>
        </View>

        <View style={{ marginTop: 20 }} fixed></View>

        <View fixed>
          <Text style={{ fontSize: 16, paddingBottom: "4px" }}>Pagos</Text>
          <View style={styles.rowHeaderTable2}>
            {columnasPagos.map((column, index) => (
              <View
                key={index}
                style={
                  (styles.containerColumn,
                  { width: column.width, textAlign: column.textAlign })
                }
              >
                <Text style={styles.text3}>{column.text}</Text>
              </View>
            ))}
          </View>
        </View>
        {data.detalles_pago_compra.map((x, index) => (
          <View key={index}>
            <View style={styles.containerRowBodyTable}>
              <View style={(styles.containerColumn, { width: 80 })}>
                <Text style={styles.text4}>
                  {parseDate(data.fecha_real_pago, "DD/MM/YY HH:mm")}
                </Text>
              </View>
              <View style={(styles.containerColumn, { width: 100 })}>
                <Text style={styles.text4}>{x.medio_pago.nombre}</Text>
              </View>
              <View style={(styles.containerColumn, { width: 100 })}>
                <Text style={styles.text4}>{validarDetalles(x)}</Text>
              </View>
              <View style={(styles.containerColumn, { width: 80 })}>
                <Text style={styles.text4}>{`$${parseFloat(
                  x.monto_medio_pago,
                ).toLocaleString("ES-ar")}`}</Text>
              </View>
            </View>
            <View style={{ marginTop: -15 }}>
              <LineA4Table />
            </View>
          </View>
        ))}
        <View>
          <TotalesPagos
            pagos={data.detalles_pago_compra}
            dataRetencion={dataRetencion}
            data={data}
          />
        </View>

        {data.retencion_pago_compra.length &&
        data.retencion_pago_compra[0].monto_retencion ? (
          <View fixed>
            <Text style={{ fontSize: 16, paddingBottom: "4px" }}>
              Retención
            </Text>
            <View style={styles.rowHeaderTable2}>
              {columnasPagos.map((column, index) => (
                <View
                  key={index}
                  style={
                    (styles.containerColumn,
                    { width: column.width, textAlign: column.textAlign })
                  }
                >
                  <Text style={styles.text3}>{column.text}</Text>
                </View>
              ))}
            </View>
          </View>
        ) : null}
        {data.retencion_pago_compra.length &&
        data.retencion_pago_compra[0].monto_retencion
          ? data.retencion_pago_compra.map((x, index) => (
              <View key={index}>
                <View style={styles.containerRowBodyTable}>
                  <View style={(styles.containerColumn, { width: 80 })}>
                    <Text style={styles.text4}>
                      {parseDate(x.fecha_retencion, "DD/MM/YY HH:mm")}
                    </Text>
                  </View>
                  <View style={(styles.containerColumn, { width: 100 })}>
                    <Text style={styles.text4}>{`Retención N.º ${x.id}`}</Text>
                  </View>
                  <View style={(styles.containerColumn, { width: 100 })}>
                    <Text style={styles.text4}>{x.observacion || "---"}</Text>
                  </View>
                  <View style={(styles.containerColumn, { width: 80 })}>
                    <Text style={styles.text4}>
                      {formatCurrency(x.monto_retencion)}
                    </Text>
                  </View>
                </View>
                <View style={{ marginTop: -15 }}>
                  <LineA4Table />
                </View>
              </View>
            ))
          : null}

        {/* <View>
          <TotalesPagos
            pagos={data.detalles_pago_compra}
            dataRetencion={dataRetencion}
            data={data}
          />
        </View> */}

        <View
          style={{
            alignItems: "flex-end",
            position: "absolute",
            fontSize: 10,
            bottom: 50,
            left: 0,
            right: 0,
            width: "100%",
          }}
          fixed
        >
          <FirmaBoxer />
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
}
