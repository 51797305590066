import React from "react";
import { View, Text, Image } from "@react-pdf/renderer";
import { styles } from "../../assets/css/styleA4";
import NoValidoFactura from "../../Components/logoTipoPago";
import { parseDate } from "../../../../../utils/parsers";

export default function HeaderA4({ data, config }) {
  return (
    <View
      style={{ flexDirection: "row", justifyContent: "space-between" }}
      fixed
    >
      <View
        style={{
          flexDirection: "column",
          alignItems: "flex-start",
          width: 130,
        }}
      >
        <Text
          style={{
            fontWeight: 600,
            width: 200,
            fontSize: 12,
            marginBottom: 5,
            marginLeft: 10,
            color: "#1B71BA",
          }}
        >
          RECIBO DE PAGO DE VENTA
        </Text>

        <View style={styles.containerRow}>
          <View style={(styles.containerColumn, { width: 60 })}>
            <Text style={styles.text}>Fecha: </Text>
            {!config.ocultar_datos_ci && (
              <Text style={styles.text}>CUIT: </Text>
            )}
          </View>
          <View style={(styles.containerColumn, { width: 100 })}>
            <Text style={styles.text}>
              {data.venta.fechaHoraVenta &&
                parseDate(data.venta.fechaHoraVenta, "DD/MM/YYYY HH:mm")}
            </Text>
            {!config.ocultar_datos_ci && (
              <Text style={styles.text}>{data.sucursal.CUIT}</Text>
            )}
          </View>
        </View>
      </View>

      <NoValidoFactura sucursal={data.sucursal} config={config} />

      <View style={styles.containerRowLogo}>
        <View style={(styles.containerColumn, { width: 105 })}>
          {!config.ocultar_datos_ci && data.logo && (
            <View style={styles.viewImage}>
              <Image
                cache={false}
                style={{ width: 105, height: 68 }}
                allowDangerousPaths={true}
                src={data.logo && `data:image/png/jpg;base64,${data.logo}`}
              ></Image>
            </View>
          )}
        </View>
      </View>
    </View>
  );
}
