import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  Button,
  Container,
  makeStyles,
  CardActions,
  CardContent,
} from "@material-ui/core";
import {
  pagoDeCompras,
  listProveedores,
  anulacionDeCompra,
} from "../../../requests/urls";
import {
  errorNotification,
  successNotification,
} from "../../../components/Notifications";
import {
  getBlobPdf,
  imprimirPdf,
} from "../../../components/ait-reusable/HandlePdf/handlePdf";
import { firtDayCurrenMonth, lastDayCurrenMonth } from "../../../utils/dates";
import { useParams } from "react-router-dom";
import {
  formatPagosDeCompras,
  formatStats,
  ModalContentAunlacionPago,
} from "./util";
import { useHistory } from "react-router";
import { columns } from "./util";
import Stats from "../Ventas/Stats";
import moment from "moment";
import Filters from "./Filters";
import request from "../../../requests/request";
import PageTitle from "../../../components/common/PageTitle";
import Pagination from "react-js-pagination";
import ModalConfirm from "../../../components/ait-reusable/ModalConfirm/ModalConfirm";
import ReusableTable from "../../../components/ait-reusable/Report/ReusableTable";
import ModalDetallesPago from "./Modales/ModalDetallePago";
import CircularBackdrop from "../../../components/ait-reusable/CircularBackdrop";
import { Showmodal } from "../../../components/ait-reusable/PDF/Components/PreviewPdf";
import { useSelector } from "react-redux";
import ObservacionPago from "./Modales/ObservacionPago";
import FiltroObservaciones from "./FiltroObservaciones";
import { useDebounce } from "../../../customHooks/useDebounce";
import useVerificarPermiso from "../../../customHooks/useVerificarPermisos";
import { PERMISOS } from "../../../constantes";
import { useGetSucursal } from "../../../customHooks/useGetSucursal";

const useStyles = makeStyles((theme) => ({
  root: {
    "&:hover": {
      backgroundColor: "#3f51b5",
      color: "white",
      borderColor: "#3f51b5",
    },
  },
}));

export default function PagoCompras() {
  const classes = useStyles();
  const history = useHistory();

  const { sucursal } = useGetSucursal();
  const { empleado } = useSelector((state) => state.loginReducer);
  useVerificarPermiso(empleado, PERMISOS.PROVEEDORES);

  const [period, setPeriod] = useState("thisMonth");
  const [totales, setTotales] = useState([]);
  const [preview, setPreview] = useState(false);
  const [loading, setLoading] = useState(false);
  const [backdrop, setBackdrop] = useState(false);
  const [proveedor, setProveedor] = useState(null);
  const [proveedores, setProveedores] = useState([]);
  const [mediosDePago, setMediosDePago] = useState([]);
  const [pagoSelected, setPagoSelected] = useState(null);
  const [modalDetalles, setModalDetalles] = useState(false);
  const [pagosDeCompras, setPagosDeCompras] = useState([]);
  const [modalAnulacion, setModalAnulacion] = useState(false);
  const [medioPagoSelected, setMedioPagoSelected] = useState("all");
  const [tipoPagoSelected, setTipoPagoSelected] = useState("all");
  const [notaCreditoSelected, setNotaCreditoSelected] = useState("all");
  const [dates, setDates] = useState({
    fromDate: firtDayCurrenMonth(),
    toDate: lastDayCurrenMonth(),
  });
  const [pagination, setPagination] = useState({
    actualPage: 1,
    num_pages: 0,
    num_items: 0,
    next_page: null,
  });
  const [modalObservacion, setModalObservacion] = useState(false);
  const [filtroObservacion, setFiltroObservacion] = useState("");
  const [loadingAnular, setLoadingAnular] = useState(false);
  const debouncedFiltroObservacion = useDebounce(filtroObservacion, 1000);

  const { configGeneral } = useSelector((state) => state.configGeneral);

  const PROVEEDOR_SIN_ID = proveedor ? proveedor.idProveedor : null;

  const notasCreditoPago =
    pagoSelected &&
    pagoSelected.ncs_de_pago.length &&
    pagoSelected.ncs_de_pago.filter((x) => x.es_descuento === true);
  const notasCreditoPagoSinDesc =
    pagoSelected &&
    pagoSelected.ncs_de_pago.length &&
    pagoSelected.ncs_de_pago.filter((x) => x.es_descuento === false);

  const { idProveedor } = useParams();

  const handleGetPagosDeCompras = async () => {
    setLoading(true);
    const toDate = new Date(dates.toDate);
    toDate.setDate(toDate.getDate() + 1);

    try {
      const response = await request({
        method: "GET",
        url: pagoDeCompras,
        params: {
          desde: dates.fromDate,
          hasta: toDate.toISOString().slice(0, 10),
          page: pagination.actualPage,
          proveedor: idProveedor ? idProveedor : PROVEEDOR_SIN_ID,
          medio_pago: medioPagoSelected === "all" ? null : medioPagoSelected,
          nc_descuento:
            notaCreditoSelected === "all" ? null : notaCreditoSelected,
          tipo_pago: tipoPagoSelected === "all" ? null : tipoPagoSelected,
          observacion:
            debouncedFiltroObservacion === ""
              ? null
              : debouncedFiltroObservacion,
        },
      });
      if (response.status === 200) {
        setPagosDeCompras(response.data.data.items);
        setLoading(false);
        setPagination({
          ...pagination,
          num_items: response.data.data.num_items,
          num_pages: response.data.data.num_pages,
          next_page: response.data.data.next_page,
        });
      }
      if (response.status === 204) {
        setPagosDeCompras([]);
        setPagination({
          actualPage: 1,
          num_pages: 0,
          num_items: 0,
          next_page: null,
        });
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const handleGetMediosDePago = async () => {
    try {
      const response = await request({
        method: "GET",
        url: "/api/venta/mediosPago/",
      });
      setMediosDePago(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetProveedores = async () => {
    try {
      const response = await request({
        method: "GET",
        url: listProveedores,
      });
      setProveedores(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleGetTotales = async () => {
    const toDate = new Date(dates.toDate);
    toDate.setDate(toDate.getDate() + 1);

    try {
      const response = await request({
        method: "GET",
        url: "/api/pagos/totales/",
        params: {
          desde: dates.fromDate,
          hasta: toDate.toISOString().slice(0, 10),
          proveedor: idProveedor ? idProveedor : PROVEEDOR_SIN_ID,
          medio_pago: medioPagoSelected === "all" ? null : medioPagoSelected,
        },
      });
      setTotales(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmitFiltros = () => {
    handleGetPagosDeCompras();
    handleGetTotales();
  };

  const handleAnularPago = async () => {
    setLoadingAnular(true);
    try {
      const response = await request({
        method: "PUT",
        url: anulacionDeCompra(pagoSelected.id_pagocompra),
      });
      successNotification("Pago anulado con éxito.");
      handleGetTotales();
    } catch (error) {
      console.error(error);
      errorNotification(
        "Error al anular el pago, por favor intente nuevamente.",
      );
    }
    setModalAnulacion(false);
    setLoadingAnular(false);
    handleGetTotales();
    handleGetPagosDeCompras();
  };

  const handleModalAnularPago = (pago) => {
    setPagoSelected(pago);
    setModalAnulacion(true);
  };

  const handleModalDetallesPago = (pago) => {
    setPagoSelected(pago);
    setModalDetalles(true);
  };

  const handleComprobantePdf = async (pago) => {
    setBackdrop(true);
    setPagoSelected(pago);
    const blobPdf = await getBlobPdf(
      "pagoCompras",
      pago,
      configGeneral,
      null,
      null,
      sucursal,
    );
    if (configGeneral.previsualizacion) {
      imprimirPdf(blobPdf);
    } else {
      imprimirPdf(blobPdf);
      setPreview(true);
    }

    setBackdrop(false);
  };

  const handlePageChange = (e) => {
    setPagination({ ...pagination, actualPage: e });
  };

  useEffect(() => {
    handleGetTotales();
    handleGetProveedores();
    handleGetMediosDePago();
  }, []);

  useEffect(() => {
    if (pagination.num_pages !== 0) {
      handleGetPagosDeCompras();
      formatStats(totales);
    }
  }, [pagination.actualPage]);

  const addObservacionPago = (pago) => {
    setModalObservacion(true);
    setPagoSelected(pago);
  };

  useEffect(() => {
    handleGetPagosDeCompras();
    handleGetTotales();
  }, [debouncedFiltroObservacion]);

  // Componente children que posee los componentes de la pantalla,
  // se utiliza para en base a si tiene Proveedor o no encerrar el
  // componente en un Container o un div
  const commonContent = (
    <>
      {!idProveedor ? (
        <Grid container className="page-columns py-4">
          <Grid item xs={11} md={11} lg={11} sm={11}>
            <PageTitle
              title="Pagos de Compras"
              subtitle="Pagos"
              className="text-sm-left"
            />
          </Grid>
          <Grid item xs={1} md={1} lg={1} sm={1}>
            <Button
              outline
              title="ATRAS"
              variant="outlined"
              className={classes.root}
              color="primary"
              style={{
                height: "50%",
                cursor: "pointer",
                padding: "2px 5px",
                margin: "24px 0px",
              }}
              onClick={() => history.goBack()}
            >
              <span
                className="material-icons my-auto"
                style={{ cursor: "pointer", fontSize: 32 }}
              >
                reply
              </span>
            </Button>
          </Grid>
        </Grid>
      ) : null}

      <Grid container spacing={2}>
        <Filters
          dates={dates}
          period={period}
          setDates={setDates}
          proveedor={proveedor}
          setPeriod={setPeriod}
          idProveedor={idProveedor}
          proveedores={proveedores}
          setProveedor={setProveedor}
          mediosDePago={mediosDePago}
          tipoPagoSelected={tipoPagoSelected}
          medioPagoSelected={medioPagoSelected}
          handleSubmitFiltros={handleSubmitFiltros}
          setTipoPagoSelected={setTipoPagoSelected}
          setMedioPagoSelected={setMedioPagoSelected}
          notaCreditoSelected={notaCreditoSelected}
          setNotaCreditoSelected={setNotaCreditoSelected}
        />
        <Grid container spacing={2} className="page-columns py-4">
          <Grid item lg={3} md={3} sm={12} xs={12} className="text-center">
            <Stats totales={totales} />
          </Grid>
          <Grid item lg={9} md={9} sm={12} xs={12}>
            <Card>
              <CardContent>
                <ReusableTable
                  columns={columns}
                  loading={loading}
                  items={formatPagosDeCompras(
                    pagosDeCompras,
                    handleModalAnularPago,
                    handleModalDetallesPago,
                    handleComprobantePdf,
                    addObservacionPago,
                    setLoading,
                  )}
                  noResults="No se encontraron pagos de compras"
                >
                  <FiltroObservaciones
                    filtroObservacion={filtroObservacion}
                    setFiltroObservacion={setFiltroObservacion}
                  />
                </ReusableTable>
                {!loading && (
                  <CardActions>
                    <Grid
                      container
                      spacing={2}
                      className="d-flex mt-3 mb-0 justify-content-center"
                    >
                      <Pagination
                        activePage={pagination.actualPage}
                        itemsCountPerPage={10}
                        totalItemsCount={pagination.num_items}
                        pageRangeDisplayed={6}
                        onChange={(e) => handlePageChange(e)}
                        itemClass="page-item"
                        linkClass="page-link"
                      />
                    </Grid>
                  </CardActions>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </>
  );

  return (
    <>
      {!idProveedor ? (
        <Container fluid className={"main-content-container px-4"}>
          {commonContent}
        </Container>
      ) : (
        <div>{commonContent}</div>
      )}
      {modalAnulacion && (
        <ModalConfirm
          open={modalAnulacion}
          handleClose={() => setModalAnulacion(false)}
          handleSubmit={handleAnularPago}
          title={`Confirmar anulación del pago de compra`}
          disabled={notasCreditoPago.length || loadingAnular}
          body={
            <ModalContentAunlacionPago
              pagoSelected={pagoSelected}
              notasCreditoPago={notasCreditoPago}
            />
          }
          buttons={{ submit: "Aceptar", cancel: "Cancelar" }}
          showButtonAceptar={notasCreditoPago.length ? false : true}
        />
      )}
      {modalDetalles && (
        <ModalDetallesPago
          pago={pagoSelected}
          title={`Detalles del Pago al proveedor ${pagoSelected.proveedor.razonSocial}`}
          open={modalDetalles}
          notasCreditoPagoSinDesc={notasCreditoPagoSinDesc}
          close={() => {
            setModalDetalles(false);
            setPagoSelected(null);
          }}
          notasCreditoPago={notasCreditoPago}
        />
      )}
      {modalObservacion && pagoSelected && (
        <ObservacionPago
          open={modalObservacion}
          handleClose={() => {
            setModalObservacion(false);
            setPagoSelected(null);
          }}
          pagoSelected={pagoSelected}
          handleGetPagosDeCompras={handleGetPagosDeCompras}
        />
      )}
      {preview && (
        <Showmodal
          preview={preview}
          data={pagoSelected}
          sucursal={sucursal}
          tipo={"pagoCompras"}
          toggle={() => setPreview(false)}
        />
      )}

      <CircularBackdrop openBackdrop={backdrop} />
    </>
  );
}
