import React from "react";
import { Grid, TextField, MenuItem } from "@material-ui/core";

export default function FilterOrder({ filter, setFilter }) {
  return (
    <Grid item xs={12} sm={12} md={4} lg={4}>
      <TextField
        style={{ marginTop: 5 }}
        size="small"
        fullWidth
        select
        label="Ordenar Por"
        name="Ordenar por"
        variant="outlined"
        value={filter}
        InputLabelProps={{ shrink: filter }}
        onChange={(e) => setFilter("orderBy", e.target.value)}
      >
        <MenuItem value={"sin_orden"}>Sin orden</MenuItem>
        <MenuItem value={"a_z"}>Alfabeticamente A-Z</MenuItem>
        <MenuItem value={"z_a"}>Alfabéticamente Z-A</MenuItem>
        <MenuItem value={"dias_sin_pagar_mayor_a_menor"}>
          Días sin pagar de Mayor a Menor
        </MenuItem>
        <MenuItem value={"dias_sin_comprar_mayor_a_menor"}>
          Días sin comprar de Mayor a Menor
        </MenuItem>
        <MenuItem value={"deuda_mayor_a_menor"}>
          Deuda de Mayor a Menor
        </MenuItem>
        <MenuItem value={"deuda_menor_a_mayor"}>
          Deuda de Menor a Mayor
        </MenuItem>
        <MenuItem value={"saldo_mayor_a_menor"}>
          Saldo de Mayor a Menor
        </MenuItem>
        <MenuItem value={"saldo_menor_a_mayor"}>
          Saldo de Menor a Mayor
        </MenuItem>
        <MenuItem value={"cantidad_comprobantes_adeudados_mayor_a_menor"}>
          Cantidad Comprobantes Adeudados de Mayor a Menor
        </MenuItem>
        <MenuItem value={"cantidad_comprobantes_adeudados_menor_a_mayor"}>
          Cantidad Comprobantes Adeudados de Menor a Mayor
        </MenuItem>
      </TextField>
    </Grid>
  );
}
