import React from "react";
import { styles } from "../../assets/css/styleA4";
import { Text, View } from "@react-pdf/renderer";
import { LineA4Table } from "../../Components/raya";
import { parseCurrency, parseDate } from "../../../../../utils/parsers";

export const RowOrdenCargaSaldo = ({ x }) => {
  return (
    <View style={{ paddingTop: 6 }}>
      <View style={styles.containerRowBodyTable}>
        <View style={(styles.containerColumn, { width: 50 })}>
          <Text style={styles.text4}>{parseDate(x.fecha)}</Text>
        </View>
        <View style={(styles.containerColumn, { width: 80 })}>
          <Text style={styles.text4}>Recibo N° {x.numero_comprobante}</Text>
        </View>
        <View style={(styles.containerColumn, { width: 120 })}>
          <Text style={styles.text4}>
            {x.observacion &&
              `${
                x.observacion
                  ? x.observacion.substr(0, 30).trim() +
                    (x.observacion.length > 30 ? "..." : "")
                  : "---"
              }`}
          </Text>

          {x.tipo_registro === "orden_carga_saldo_anuladas" && (
            <View style={{ paddingTop: x.observacion === "" ? 0 : -15 }}>
              <Text style={styles.text4}>Recibo anulado</Text>
            </View>
          )}
        </View>
        <View style={(styles.containerColumn, { width: 80 })}>
          {x.debe !== 0 && (
            <Text style={styles.text4Verde}>+ {parseCurrency(x.debe)}</Text>
          )}
        </View>
        <View style={(styles.containerColumn, { width: 80 })}>
          {x.haber !== 0 && (
            <Text style={styles.text4Rojo}>- {parseCurrency(x.haber)}</Text>
          )}
        </View>
        <View style={(styles.containerColumn2, { width: 80 })}>
          <Text style={styles.text4}>{parseCurrency(x.saldo_actual)}</Text>
        </View>
      </View>
      <View style={{ marginTop: -5 }}>
        <LineA4Table />
      </View>
    </View>
  );
};
