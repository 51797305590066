import { View, Text, StyleSheet } from "@react-pdf/renderer";
import React from "react";
import { styles } from "../../assets/css/styleA4";
import {
  ACLARACION,
  CARGO,
  DECLARACION,
  FIRMA_AGENTE_RETENCION,
} from "../Constants/constants";

export default function Footer() {
  const stylesPdf = StyleSheet.create({
    fontBoldTitle: {
      fontFamily: "Times-Bold",
      fontWeight: "bold",
      fontSize: 18,
    },
    fontBold: {
      fontFamily: "Times-Bold",
      fontWeight: "bold",
      fontSize: 13,
    },
  });

  return (
    <>
      <View style={{ paddingTop: 100 }}>
        <View style={styles.containerRowRetencion} fixed>
          <Text style={stylesPdf.fontBold}>{FIRMA_AGENTE_RETENCION}</Text>
        </View>

        <View style={[styles.containerRowRetencion, { paddingTop: 20 }]} fixed>
          <Text style={stylesPdf.fontBold}>{ACLARACION}</Text>
        </View>

        <View
          style={[
            styles.containerRowRetencion,
            { paddingTop: 20, marginBottom: 40 },
          ]}
          fixed
        >
          <Text style={stylesPdf.fontBold}>{CARGO}</Text>
        </View>
        <View style={{ border: 1, textAlign: "center", padding: 4 }} fixed>
          <Text style={{ fontSize: 10 }}>{DECLARACION}</Text>
        </View>
      </View>
    </>
  );
}
