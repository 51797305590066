import React, { useState, useEffect } from "react";

import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import Period from "../../../components/ait-reusable/PeriodFilter/Period";
import { validateFilters } from "../../../components/ait-reusable/Filtros/utils";
import { getFormatedNow } from "../../../utils/dates";
import { downloadFile } from "./utils";

export default function LibrosIIBB() {
  const [dates, setDates] = useState({
    fromDate: getFormatedNow(),
    toDate: getFormatedNow(),
  });
  const [period, setPeriod] = useState("today");
  const [libro, setLibro] = useState("libro-iibb");
  const [formato, setFormato] = useState("txt");
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const optionsPeriod = [
    { value: "today", name: "Hoy" },
    { value: "thisWeek", name: "Esta semana" },
    { value: "thisMonth", name: "El corriente mes" },
    { value: "thisYear", name: "El corriente año" },
    { value: "custom", name: "Personalizado" },
  ];
  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      downloadFile(libro, dates, formato);
    }
  }, [errors]);

  const validateDates = () => {
    setErrors(validateFilters(dates));
    setIsSubmitting(true);
  };
  return (
    <Grid item sm={4} xs={4}>
      <Period
        period={period}
        setPeriod={setPeriod}
        dates={dates}
        setDates={setDates}
        clear={() => {}}
        searchOnLoad={false}
        optionsPeriod={optionsPeriod}
        errors={errors}
        setErrors={setErrors}
        sizeGrid={12}
        noButton={true}
      >
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <FormControl size={"small"} fullWidth variant="outlined">
            <InputLabel id="lbl-tipo-libro">Libros</InputLabel>
            <Select
              labelId="lbl-tipo-libro"
              id="select-tipo-libro"
              value={libro}
              onChange={(e) => setLibro(e.target.value)}
              label="Libro"
            >
              <MenuItem value="libro-iibb">Libro de IIBB</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <FormControl size={"small"} fullWidth variant="outlined">
            <InputLabel id="lbl-formato">Formato</InputLabel>
            <Select
              labelId="lbl-formato"
              id="select-formato"
              value={formato}
              onChange={(e) => setFormato(e.target.value)}
              label="formato"
            >
              <MenuItem value="txt">TXT (compatible con AFIP)</MenuItem>
              <MenuItem value="xls">Excel</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ paddingBottom: 15 }}
        >
          <Button
            onClick={validateDates}
            variant="contained"
            color="primary"
            fullWidth
          >
            CONSULTAR
          </Button>
        </Grid>
      </Period>
    </Grid>
  );
}
