import React from "react";
import { View, Text } from "@react-pdf/renderer";
import NroFacturaA4 from "../../DeVentas/Components/nroFacturaA4";
import HeaderA4 from "./HeaderA4";
import { LineA4Color, LineA4Cut } from "../../Components/raya";
import { styles } from "../../assets/css/styleA4";
import FooterA4 from "../../Components/FooterA4";
import { fill } from "../../utils";
import { formatPagaCon } from "./utils";

export default function BodyA4({ data, tipo }) {
  const nroComprobante = (data) => {
    if (data.detalles) {
      return data.nroComprabante;
    } else {
      return data.nroComprobante;
    }
  };

  return (
    <>
      <View>
        <NroFacturaA4
          title={"COMPROBANTE"}
          nro={fill(nroComprobante(data), 6)}
        />
        <HeaderA4 data={data} />
        <LineA4Color />
        <View style={styles.containerRow}>
          <View style={styles.containerColumn}>
            <Text style={styles.text2}>{`${
              data.recibo.includes("Recibí") ? "Recibí de: " : "Entregué a:"
            } ${data.cliente.razonSocial}`}</Text>
          </View>
        </View>
        <View style={styles.containerRow}>
          <View style={styles.containerColumn}>
            <Text style={styles.text2}>{`La suma de: ${
              data.recibo.includes("Recibí")
                ? data.monto_letters
                : data.monto_letters.replace("menos", "")
            }`}</Text>
          </View>
        </View>
        <LineA4Color />
        <View style={styles.containerRow}>
          <View style={styles.containerColumn}>
            <Text style={styles.text2}>{`En concepto de: ${
              data.concepto ? data.concepto : data.leyenda
            }`}</Text>
          </View>
        </View>

        <LineA4Color />

        {data.detalles && (
          <View style={styles.containerRow}>
            <View style={styles.containerColumn}>
              <Text style={styles.text2}>Pago con:</Text>
              {data.detalles.map((d, index) => (
                <Text key={index} style={styles.text2}>
                  {formatPagaCon(d)}
                </Text>
              ))}
            </View>
          </View>
        )}
        <View style={styles.containerRow}>
          <View style={styles.containerColumn}>
            {!data.detalles && (
              <Text style={styles.text2}>{`Pago con: ${data.forma_pago}`}</Text>
            )}
          </View>
        </View>
        <LineA4Color />

        {data.responsable_pago ? (
          <View style={styles.containerRow}>
            <View style={styles.containerColumn}>
              <Text
                style={styles.text2}
              >{`Responsable: ${data.responsable_pago.nombre} ${data.responsable_pago.apellido}`}</Text>
            </View>
          </View>
        ) : null}

        <View style={styles.containerRow}>
          <View style={styles.containerColumn}>
            <Text style={styles.text2}>{`Observaciones: ${
              data.observacion || "Ninguna"
            }`}</Text>
          </View>
        </View>

        <LineA4Color />
        <View style={styles.containerRow}>
          <View style={styles.containerColumn}>
            <Text style={styles.text2}>{`Recibí Conforme:__________ `}</Text>
          </View>
          <View style={styles.containerColumn}>
            <Text style={styles.text2}>{`Entregué Conforme:__________ `}</Text>
          </View>
          <View style={styles.containerColumn}>
            <Text style={styles.text2}>{`Total: $ ${
              data.recibo.includes("Recibí")
                ? Number(parseFloat(data.monto).toFixed(2)).toLocaleString(
                    "es-AR",
                  )
                : Number(
                    parseFloat(Math.abs(data.monto)).toFixed(2),
                  ).toLocaleString("es-AR")
            }`}</Text>
          </View>
        </View>
        <LineA4Color />
      </View>
      {tipo !== "billeteraA4Ahorro" ? (
        <View
          style={{
            position: "absolute",
            bottom: 100,
            paddingLeft: 20,
            paddingRight: 20,
            left: 0,
            right: 0,
          }}
          fixed
        >
          <LineA4Color />
        </View>
      ) : null}
      <FooterA4
        fixed={tipo !== "billeteraA4Ahorro" ? true : false}
        sucursal={data.empresa}
      />
      {tipo === "billeteraA4Ahorro" && (
        <View>
          <LineA4Cut />
        </View>
      )}
    </>
  );
}
