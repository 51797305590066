import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import {
  initialStateAutomatico,
  validationYupAutomatico,
} from "./validationYup";
import { Formik } from "formik";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import request from "../../../../requests/request";
import {
  createClienteSucursalCuitApi,
  updateClienteSucursal,
} from "../../../../requests/urls";
import {
  errorNotification,
  successNotification,
} from "../../../../components/Notifications";
import {
  getAddress,
  getInformacionFiscal,
  getName,
  onClickCuit,
  valueCuit,
} from "./utils";
import DatosCliente from "./DatosCliente";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import AlertTipoClave from "./AlertTipoClave";
import { useSelector } from "react-redux";
import FormExtraAutomatica from "./FormExtraAutomatica";
import FormClienteInactivo from "./FormClienteInactivo";
import Alert from "@material-ui/lab/Alert";
import { SwitchDniOrCuit } from "../../../../components/ait-reusable/SwitchDniOrCuit";
import useConversionNumeroMiles from "../../../../customHooks/useConversionNumeroMiles";
import { EstadoTributarioTextField } from "./EstadoTributarioTextField";
import TooltipWithoutIcon from "../../../../components/ait-reusable/TooltipMoreInfo/TooltipWithoutIcon";
import HelpIcon from "@material-ui/icons/Help";

const FormCargaAutomatica = ({
  estados,
  agregarCliente,
  setOpenBackdrop,
  cargaManual,
  isVendedor,
  arrayObservaciones,
  setOpenModalObservaciones,
  datosOrdenMeli,
  closeModal,
  setOpenModalInfoFiscal,
  setDataInfoFiscal,
}) => {
  const [cliente, setCliente] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [disabledValidate, setDisabledValidate] = useState(false);
  const [errorCuit, setErrorCuit] = useState(false);
  const [clienteInactivo, setClienteInactivo] = useState(null);
  const [infoClienteInactivo, setInfoClienteInactivo] = useState(false);
  const [dataClienteNuevo, setDataClienteNuevo] = useState(null);
  const [isCuit, setCuit] = useState(true);
  const { impuestos: impuestos_boxer } = useSelector(
    (state) => state.loginReducer,
  );
  const { convertNumeroSinMiles } = useConversionNumeroMiles();
  const isDisabled = !cliente || disabled || !cliente.domicilio_fiscal;
  const formikRef = React.useRef();

  const onSubmit = async (values) => {
    if (clienteInactivo) {
      editarClienteInactivo(values);
    } else {
      setDisabled(true);
      setOpenBackdrop(true);
      try {
        const response = await request({
          method: "POST",
          url: createClienteSucursalCuitApi,
          data: {
            domicilio: getAddress(cliente),
            estado_tributario: values.idEstadoTributario,
            cuit: valueCuit(values, cliente),
            razon_social: getName(cliente),
            provincia: cliente.domicilio_fiscal.provincia_id,
            is_meli: values.cliente_meli,
            id_descuento: null,
            observacion: arrayObservaciones,
            cuenta_corriente: Number(values.cuenta_corriente),
            deuda_maxima:
              values.deudaMaxima === ""
                ? 0
                : parseFloat(convertNumeroSinMiles(values.deudaMaxima)).toFixed(
                    2,
                  ),
            telefono: values.telefonoContacto,
            correo: values.correoElectronico,
            dias_deuda: values.dias_deuda === "" ? null : values.dias_deuda,
            dni: values.dni || cliente.numero_documento || null,
            tipo_cliente: values.tipo_cliente,
            comprobante_por_defecto: values.comprobantePorDefecto,
            envio_automatico_comprobante_venta:
              values.envio_automatico_comprobante_venta,
            impuestos: values.impuestos,
            forzar_medio_pago_contado_pdf: values.forzar_medio_pago_contado_pdf,
          },
        });
        handleResponseCliente(response);
        setDisabled(false);
        setOpenBackdrop(false);
      } catch (error) {
        console.error(error);
        if (error.message.includes("409")) {
          const clienteActivo = error.response.data.data.activo;
          if (!clienteActivo) {
            setDataClienteNuevo(values);
            setClienteInactivo(error.response.data.data);
            setInfoClienteInactivo(true);
            errorNotification(
              "Ya existe un cliente registrado con esa información y esta inactivo.",
            );
          } else {
            errorNotification(
              "Ya existe un cliente registrado con esa información. " +
                "Por favor, revisar el CUIT",
            );
          }
        } else if (error.message.includes("500")) {
          errorNotification(
            "Error de validacion, corrobore los datos ingresados.",
          );
        }

        setDisabled(false);
        setOpenBackdrop(false);
      }
    }
  };

  const handleResponseCliente = (response) => {
    setDisabled(false);
    setDisabledValidate(true);
    if (response.status === 201) {
      agregarCliente(response.data.data);
      successNotification("Cliente registrado exitosamente.");
      setDisabledValidate(false);
    } else {
      errorNotification("Ocurrio un error durante el registro.");
    }
  };

  const handleResponseCuit = (response) => {
    setErrorCuit(false);
    setDisabledValidate(true);
    setDisabled(false);
    console.log(formikRef.current);
    if (response.data) {
      if (response.data.tipoClave === "CUIL") {
        setDisabledValidate(false);
      }
      setCliente(response.data);
      let impuestos_cliente = impuestos_boxer.map((impuesto_boxer) => {
        const impuesto_cliente = response.data.impuestos.find(
          (imp) => imp.impuesto_id === impuesto_boxer.id,
        );
        return impuesto_cliente
          ? { ...impuesto_boxer, ...impuesto_cliente, activo: true }
          : //Se carga el IIBB defecto seleccionado
            {
              ...impuesto_boxer,
              activo:
                impuesto_boxer.tipo_actualizacion === "Manual" &&
                impuesto_boxer.nombre === "Percepción de IIBB",
            };
      });
      formikRef.current.setFieldValue("impuestos", impuestos_cliente);
    } else {
      setCliente(null);
      setErrorCuit(true);
    }
  };

  const validateForm = async (values) => {
    setDisabled(false);
    await getInformacionFiscal({
      cuit: values.CUIT || null,
      dni: values.dni || null,
      handleResponse: handleResponseCuit,
      setLoading: setOpenBackdrop,
      setOpenModalInfoFiscal: setOpenModalInfoFiscal,
      setDataInfoFiscal: setDataInfoFiscal,
      closeModal: closeModal,
    });
  };

  const resetCuit = () => {
    setDisabledValidate(false);
    setErrorCuit(false);
  };

  const editarClienteInactivo = async (values) => {
    setDisabled(true);
    setOpenBackdrop(true);
    try {
      const dataCliente = {
        dni: clienteInactivo.numero_documento,
        domicilio: clienteInactivo.cliente.domicilio,
        razon_social: clienteInactivo.razonSocial,
        telefono: clienteInactivo.cliente.telefonoContacto,
        correo: values.correoElectronico,
        estado_tributario: values.idEstadoTributario,
        provincia: clienteInactivo.cliente.provincia,
        cuit: values.CUIT,
        ingresos: null,
        deuda_maxima:
          values.deudaMaxima === ""
            ? 0
            : parseFloat(values.deudaMaxima).toFixed(2),
        is_meli: values.cliente_meli,
        es_comision: clienteInactivo.es_comision,
        comision: clienteInactivo.comision,
        id_descuento: clienteInactivo.descuento_prefijado
          ? clienteInactivo.descuento_prefijado.id_descuento
          : clienteInactivo.descuento_prefijado,
        recargo: clienteInactivo.recargo,
        cuenta_corriente: Number(values.cuenta_corriente),
        responsable_cuenta: clienteInactivo.idCliente.responsable_cuenta,
        esta_grabado_iibb: values.esta_grabado_iibb,
        dias_deuda: values.dias_deuda === "" ? null : values.dias_deuda,
        observacion: arrayObservaciones,
        is_cliente_inactivo: true,
        comprobante_por_defecto: values.comprobantePorDefecto,
        envio_automatico_comprobante_venta:
          values.envio_automatico_comprobante_venta,
        impuestos: values.impuestos,
        forzar_medio_pago_contado_pdf:
          clienteInactivo.cliente.forzar_medio_pago_contado_pdf,
      };
      const response = await request({
        method: "PUT",
        url: updateClienteSucursal(clienteInactivo.idClienteDeSucursal),
        data: dataCliente,
      });
      handleResponseClienteInactivo(response);
    } catch (error) {
      setOpenBackdrop(false);
      setDisabled(false);
      errorNotification("Ocurrió un error al activar el cliente.");
    }
  };

  const handleResponseClienteInactivo = (response) => {
    if (response.status === 201) {
      setOpenBackdrop(false);
      successNotification("Cliente dado de alta exitosamente.");
      agregarCliente(response.data.data);
      setClienteInactivo(null);
      setInfoClienteInactivo(false);
      setDataClienteNuevo(null);
      closeModal();
    } else {
      setOpenBackdrop(false);
      errorNotification("Ocurrió un error al activar el cliente.");
    }
  };

  const handleDisabled = (errors) => {
    let isDisabled;
    if (isCuit) {
      isDisabled = disabled || Boolean(errors.CUIT);
    } else {
      isDisabled = disabled || Boolean(errors.dni);
    }

    return isDisabled;
  };

  const estadosTributariosValidation = () => {
    let arrayEstados = [];
    if (cliente && cliente.tipoClave === "CUIL") {
      arrayEstados = estados.filter(
        (x) => x.nombre !== "RI" && x.nombre !== "MO" && x.nombre !== "EX",
      );
    } else {
      arrayEstados = estados;
    }

    return arrayEstados;
  };

  return infoClienteInactivo ? (
    <FormClienteInactivo
      clienteInactivo={clienteInactivo}
      setClienteInactivo={setClienteInactivo}
      closeModal={closeModal}
      setOpenBackdrop={setOpenBackdrop}
      agregarCliente={agregarCliente}
      setInfoClienteInactivo={setInfoClienteInactivo}
    />
  ) : (
    <Formik
      initialValues={
        clienteInactivo
          ? dataClienteNuevo
          : initialStateAutomatico({
              datosOrdenMeli: datosOrdenMeli,
              impuestos_boxer,
            })
      }
      innerRef={formikRef}
      validationSchema={validationYupAutomatico(isCuit)}
      onSubmit={async (
        values,
        { resetForm, setErrors, setStatus, setSubmitting },
      ) => {
        try {
          cliente &&
          (values.CUIT === String(cliente.id_persona) ||
            values.dni === cliente.numero_documento)
            ? onSubmit(values)
            : onClickCuit({
                cuit: isCuit ? values.CUIT : null,
                dni: isCuit ? null : values.dni,
                handleResponseCuit,
                setOpenBackdrop,
              });
        } catch (error) {
          setErrors({ submit: error.message });
          setStatus({ success: false });
          setSubmitting(false);
        }
      }}
    >
      {({
        touched,
        errors,
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <SwitchDniOrCuit
            value={isCuit}
            setValue={setCuit}
            clearData={() => {
              resetCuit();
              setCliente(null);
              setFieldValue("CUIT", "");
              setFieldValue("dni", "");
            }}
          />
          {cliente && cliente.tipo_clave === "CUIL" && (
            <Box pb={1}>
              <AlertTipoClave
                text={
                  "Esta persona posee CUIL pero no CUIT, por lo que solo podrá seleccionar los estados tributarios Consumidor Final y/o Sin especificar."
                }
                severity={"warning"}
              />
            </Box>
          )}

          {cliente &&
            !cliente.domicilio_fiscal &&
            !cliente.domicilio_fiscal.direccion && (
              <Box pb={1}>
                <AlertTipoClave
                  text={
                    "AFIP no dispone de la información predeterminada de domicilio y provincia de la persona. Para continuar, por favor, cargue manualmente al cliente."
                  }
                  severity={"error"}
                />
              </Box>
            )}

          <Grid container spacing={2}>
            <EstadoTributarioTextField
              setFieldValue={setFieldValue}
              disabledValidate={disabledValidate}
              values={values}
              handleChange={handleChange}
              errors={errors}
              touched={touched}
              estadosTributariosValidation={estadosTributariosValidation}
              cliente={cliente}
            />

            {isCuit ? (
              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  name="CUIT"
                  disabled={disabledValidate}
                  error={Boolean(touched.CUIT && errors.CUIT)}
                  style={{ backgroundColor: "white" }}
                  fullWidth
                  InputProps={{
                    maxLength: 11,
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon style={{ cursor: "pointer" }}>search</Icon>
                      </InputAdornment>
                    ),
                  }}
                  label={
                    values.idEstadoTributario === 1 ||
                    values.idEstadoTributario === 2 ||
                    values.idEstadoTributario === 3
                      ? "CUIT *"
                      : "CUIT"
                  }
                  value={values.CUIT}
                  onChange={(e) => {
                    if (e.target.value.length < 12 && !isNaN(e.target.value)) {
                      handleChange(e);
                    }
                  }}
                  onBlur={handleBlur}
                  helperText={touched.CUIT && errors.CUIT}
                  variant="outlined"
                />
              </Grid>
            ) : (
              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  name="dni"
                  disabled={disabledValidate}
                  error={Boolean(touched.dni && errors.dni)}
                  style={{ backgroundColor: "white" }}
                  fullWidth
                  InputProps={{
                    maxLength: 11,
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon style={{ cursor: "pointer" }}>search</Icon>
                      </InputAdornment>
                    ),
                  }}
                  label={
                    values.idEstadoTributario === 1 ||
                    values.idEstadoTributario === 2 ||
                    values.idEstadoTributario === 3
                      ? "DNI *"
                      : "DNI"
                  }
                  value={values.dni}
                  onChange={(e) => {
                    if (e.target.value.length < 10 && !isNaN(e.target.value)) {
                      handleChange(e);
                    }
                  }}
                  onBlur={handleBlur}
                  helperText={touched.dni && errors.dni}
                  variant="outlined"
                />
              </Grid>
            )}
          </Grid>
          {cliente &&
            (cliente.tipo_clave === "CUIT" || cliente.tipo_clave === "CUIL") &&
            (values.CUIT === String(cliente.id_persona) ||
              values.dni === cliente.numero_documento) && (
              <DatosCliente cliente={cliente} />
            )}
          {cliente &&
            (cliente.tipo_clave === "CUIT" || cliente.tipo_clave === "CUIL") &&
            (values.CUIT === String(cliente.id_persona) ||
              values.dni === cliente.numero_documento) && (
              <>
                <FormExtraAutomatica
                  values={values}
                  handleChange={handleChange}
                  touched={touched}
                  handleBlur={handleBlur}
                  errors={errors}
                  setFieldValue={setFieldValue}
                />
              </>
            )}
          {cliente &&
            (cliente.tipo_clave === "CUIT" || cliente.tipo_clave === "CUIL") &&
            (values.CUIT === String(cliente.id_persona) ||
              values.dni === cliente.numero_documento) && (
              <Grid item sm={12} md={6} lg={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleChange}
                      checked={values.cuenta_corriente || false}
                      name="cuenta_corriente"
                      onBlur={handleBlur}
                      color="primary"
                    />
                  }
                  label={
                    !values.cuenta_corriente
                      ? "Activar cuenta corriente"
                      : "Desactivar cuenta corriente"
                  }
                />
              </Grid>
            )}
          {cliente && (
            <Grid item lg={6} xs={12} md={6}>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleChange}
                      checked={
                        values.envio_automatico_comprobante_venta || false
                      }
                      name="envio_automatico_comprobante_venta"
                      onBlur={handleBlur}
                      color="primary"
                    />
                  }
                  label={"Envio automático de comprobante de venta por email"}
                />
                <Box>
                  <TooltipWithoutIcon
                    body={
                      <h6
                        style={{
                          fontSize: 15,
                          marginBottom: 0,
                          marginTop: 0,
                        }}
                      >
                        El comprobante se enviará automáticamente al correo
                        electrónico que has configurado, incluso si no
                        seleccionas la opción de enviar correo al confirmar la
                        venta.
                      </h6>
                    }
                    placement={"bottom"}
                  >
                    <IconButton aria-label="delete">
                      <HelpIcon />
                    </IconButton>
                  </TooltipWithoutIcon>
                </Box>
              </Box>
            </Grid>
          )}

          {cliente &&
            cliente.tipo_clave === "CUIT" &&
            values.impuestos &&
            values.impuestos.length > 0 && (
              <React.Fragment>
                <Grid item xs={12}>
                  <Typography variant="h6">Impuestos</Typography>
                  <Divider />
                  <Alert severity="info" style={{ marginTop: 8 }}>
                    <Typography variant="body2">
                      Los impuestos que se encuentran seleccionados se aplicarán
                      a las ventas realizadas al cliente.
                    </Typography>
                  </Alert>
                </Grid>
                {values.impuestos.map((impuesto, index) => {
                  return (
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={handleChange}
                            checked={values.impuestos[index].activo}
                            name={`impuestos[${index}].activo`}
                            onBlur={handleBlur}
                            color="primary"
                            disabled={impuesto.tipo_actualizacion !== "Manual"}
                          />
                        }
                        label={`Aplica ${impuesto.nombre} ${
                          impuesto.alicuota ? `- ${impuesto.alicuota}%` : ""
                        }`}
                      />
                    </Grid>
                  );
                })}
              </React.Fragment>
            )}

          {cliente &&
            (cliente.tipo_clave === "CUIT" || cliente.tipo_clave === "CUIL") &&
            (values.CUIT === String(cliente.id_persona) ||
              values.dni === cliente.numero_documento) && (
              <Grid item xs={12}>
                <Button
                  color="primary"
                  variant="outlined"
                  size="small"
                  onClick={() => setOpenModalObservaciones(true)}
                >
                  Ver observaciones del cliente
                </Button>
              </Grid>
            )}
          {cliente &&
            (values.CUIT === String(cliente.id_persona) ||
              values.dni === cliente.numero_documento) &&
            !clienteInactivo && (
              <AlertTipoClave
                text={
                  (cliente.tipo_clave === "CUIT" ||
                    cliente.tipo_clave === "CUIL") &&
                  cliente.domicilio_fiscal.direccion
                    ? "Si puedes registrarlo de forma automática!"
                    : "Esta persona no puede ser registrada de forma automática porque no posee CUIT o no posee los datos correspondientes."
                }
                severity={
                  (cliente.tipo_clave === "CUIT" ||
                    cliente.tipo_clave === "CUIL") &&
                  cliente.domicilio_fiscal.direccion
                    ? "success"
                    : "error"
                }
              />
            )}
          {errorCuit && !clienteInactivo && (
            <AlertTipoClave
              text={
                "No hemos encontrado una persona asociada a los datos ingresados."
              }
              severity={"error"}
            />
          )}
          {clienteInactivo && !infoClienteInactivo && (
            <Grid container className="justify-content-center mt-3">
              <Grid item>
                <Alert severity="info">
                  Los datos del cliente inactivo se modificarán por estos nuevos
                  datos.
                </Alert>
              </Grid>
            </Grid>
          )}
          <Grid container className="justify-content-center mt-3 mb-3">
            {clienteInactivo ? (
              <Button
                type="submit"
                variant="contained"
                size="small"
                style={{
                  backgroundColor: "#14af64",
                  color: "#fff",
                  marginRight: 5,
                  outline: "none",
                  fontWeight: "bolder",
                }}
              >
                {"ACTIVAR Y MODIFICAR"}
              </Button>
            ) : cliente &&
              (values.CUIT === String(cliente.id_persona) ||
                values.dni === cliente.numero_documento) &&
              (cliente.tipo_clave === "CUIT" ||
                cliente.tipo_clave === "CUIL") ? (
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  size="small"
                  disabled={isDisabled}
                  style={{
                    backgroundColor: isDisabled ? "#cacccb" : "#14af64",
                    color: "#fff",
                    marginRight: 5,
                    outline: "none",
                    fontWeight: "bolder",
                  }}
                >
                  {"REGISTRAR"}
                </Button>
                <Button
                  disabled={disabled}
                  size="small"
                  onClick={() => {
                    setDisabledValidate(false);
                    setCliente(null);
                  }}
                  color="primary"
                  variant="contained"
                  style={{
                    outline: "none",
                    fontWeight: "bolder",
                  }}
                >
                  {isCuit ? "¿Probar otro cuit?" : "¿Probar otro dni?"}
                </Button>
              </Grid>
            ) : (
              <Grid item>
                <Button
                  disabled={handleDisabled(errors)}
                  size="small"
                  onClick={() => {
                    disabledValidate ? resetCuit() : validateForm(values);
                  }}
                  color="primary"
                  variant="contained"
                  style={{
                    outline: "none",
                    fontWeight: "bolder",
                  }}
                >
                  {disabledValidate
                    ? `¿Probar otro ${isCuit ? "cuit" : "dni"}?`
                    : " VALIDAR"}
                </Button>
                {disabledValidate && (
                  <Button
                    variant="contained"
                    size="small"
                    style={{
                      backgroundColor: "#14af64",
                      color: "#fff",
                      marginLeft: 5,
                      outline: "none",
                      fontWeight: "bolder",
                    }}
                    onClick={cargaManual}
                  >
                    ¿Realizar carga manual?
                  </Button>
                )}
              </Grid>
            )}
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default FormCargaAutomatica;
