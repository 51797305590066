import React from "react";
import { View, Image, Text } from "@react-pdf/renderer";
import { styles } from "../../assets/css/styleTicket";
import ResumenPagos from "./resumenPagos";
import { mostrarMedioPagoContado } from "./utils";
import { parseDate } from "../../../../../utils/parsers";

export default function HeaderTicket({ logoEmpresa, data, config, remito }) {
  return (
    <View>
      {(data.venta.tipo_comprobante === "Comprobante interno" ||
        (data.comprobanteAImprimir &&
          data.comprobanteAImprimir === "Venta")) && (
        <View
          style={{
            justifyContent: "center",
            paddingBottom: 10,
            flexDirection: "row",
          }}
        >
          {!config.ocultar_datos_ci ? (
            <Text
              style={{ paddingBottom: 3, fontSize: 11, textAlign: "justify" }}
            >
              {remito ? "Remito" : "Comprobante interno"}
            </Text>
          ) : null}
        </View>
      )}
      {logoEmpresa && !config.ocultar_datos_ci && (
        <View
          style={{
            justifyContent: "center",
            paddingTop: 5,
            flexDirection: "row",
          }}
        >
          <Image
            cache={false}
            style={{ width: 120, height: 80 }}
            allowDangerousPaths={true}
            src={logoEmpresa && `data:image/png/jpg;base64,${logoEmpresa}`}
          />
        </View>
      )}
      {!config.ocultar_datos_ci && (
        <View
          style={{
            justifyContent: "center",
            paddingTop: 5,
            paddingLeft: 10,
            paddingRight: 10,
            flexDirection: "row",
          }}
        >
          {data.venta.tipo_comprobante === "Factura" && (
            <Text
              style={{
                paddingBottom: 3,
                fontSize: 8,
                textAlign: "justify",
                textTransform: "uppercase",
              }}
            >
              {data.factura.idSucursal.razonSocial}
            </Text>
          )}
        </View>
      )}
      {data.venta.tipo_comprobante === "Comprobante interno" ||
      (data.comprobanteAImprimir && data.comprobanteAImprimir === "Venta") ? (
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          <View style={styles.containerColumn}>
            <Text style={styles.text}>{`Nro.: ${data.venta.idVenta}`}</Text>
            <Text style={styles.text}>{`Fecha: ${parseDate(
              data.venta.fechaHoraVenta,
              "DD/MM/YYYY HH:mm",
            )}`}</Text>
            {data.venta.cliente ? (
              <Text
                style={styles.text}
              >{`Cliente: ${data.venta.cliente.razonSocial}`}</Text>
            ) : (
              <Text style={styles.text}>CONSUMIDOR FINAL</Text>
            )}
            {data.venta.cliente ? (
              <Text style={styles.text}>{`Domicilio: ${
                data.venta.cliente.domicilio
                  ? data.venta.cliente.domicilio
                  : "---"
              }`}</Text>
            ) : null}
            {data.venta.cliente ? (
              <Text style={styles.text}>{`Correo: ${
                data.venta.cliente.correoElectronico
                  ? data.venta.cliente.correoElectronico
                  : "---"
              }`}</Text>
            ) : null}
            {data.venta.cliente ? (
              <Text style={styles.text}>{`CUIT: ${
                data.venta.cliente.CUIT ? data.venta.cliente.CUIT : "---"
              }`}</Text>
            ) : null}
            {data.venta.cliente ? (
              <Text style={styles.text}>{`Tel.: ${
                data.venta.cliente.telefonoContacto
                  ? data.venta.cliente.telefonoContacto
                  : "---"
              }`}</Text>
            ) : null}
            {!remito && (
              <View style={(styles.containerColumn, { width: 250 })}>
                <Text style={styles.text}>{`Formas de Pago:`}</Text>
                {mostrarMedioPagoContado(null, data.venta) ? (
                  <Text style={styles.text}>- CONTADO</Text>
                ) : (
                  <ResumenPagos
                    data={data.pagos_cliente}
                    styles={styles.text}
                  />
                )}
              </View>
            )}

            <Text style={styles.text}>{`Observación:${
              data.venta.descripcion || "Ninguna"
            }`}</Text>

            {data.venta.responsable_venta ? (
              <Text
                style={styles.text}
              >{`Responsable: ${data.venta.responsable_venta.nombre}`}</Text>
            ) : null}
          </View>
        </View>
      ) : (
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          <View style={styles.containerColumn}>
            <Text
              style={{
                paddingBottom: 3,
                fontSize: 8,
                textAlign: "center",
              }}
            >
              {data.factura.idSucursal.domicilio}
            </Text>
            <Text style={styles.text}>{`CUIT: ${
              data.factura.idSucursal.CUIT || "---"
            }`}</Text>
            <Text style={styles.text}>{`INGRESOS BRUTOS: ${
              data.factura.idSucursal.ingresosBrutos || "N/A"
            }`}</Text>
            <Text style={styles.text}>{`TE: ${
              data.factura.idSucursal.telefonoContacto || "---"
            }`}</Text>
            <Text style={styles.text}>
              {data.factura.idSucursal.idEstadoTributario.descripcion || " "}
            </Text>
          </View>
        </View>
      )}

      <Text style={{ marginLeft: 10, marginRight: 10 }}>
        --------------------------------
      </Text>
    </View>
  );
}
