import React from "react";
import { Grid, Button } from "@material-ui/core";

export default function FilterButtons({ getClients, reset, resetClient }) {
  const handleReset = async () => {
    await reset();
    resetClient();
    getClients();
  };

  return (
    <Grid item xs={12} sm={12} md={6} lg={6} spacing={2}>
      <Grid container spacing={2} className="justify-xs-center">
        <Grid item>
          <Button
            onClick={getClients}
            variant="contained"
            color="primary"
            disableElevation
          >
            CONSULTAR
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={handleReset}
            variant="contained"
            color="tertiary"
            disableElevation
          >
            LIMPIAR FILTROS
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
