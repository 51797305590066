import {
  Backdrop,
  Box,
  Card,
  CircularProgress,
  Container,
  Divider,
  Grid,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import GoBack from "../../../components/ait-reusable/GoBack";
import PageTitle from "../../../components/common/PageTitle";
import { warningNotification } from "../../../components/Notifications";
import { useFiltroStock } from "../../../customHooks/useFiltroStock";
import useArticulosSucursal from "../../../customHooks/useGetArticulosSucursal";
import { useGetSucursal } from "../../../customHooks/useGetSucursal";
import { getConfiguracionEtiquetasService } from "../../Configuration/ConfiguracionEtiquetas/Services";
import { getFilters } from "../../Proveedores/Catalogo/utils";
import {
  AutocompleteArticulo,
  Options,
  Table,
} from "./Components/ImprimirEtiquetas";
import FiltroBuscador from "./FiltroBuscador";
import FiltroStock from "./FiltroStock";
import { getPDF } from "./Services/services";

const useStyles = makeStyles((theme) => ({
  "@global": {
    '.MuiAutocomplete-option[data-focus="true"]': {
      background: "#B2B2B2 ",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function ImprimirEtiquetas() {
  const classes = useStyles();
  const history = useHistory();
  const [tableData, setTableData] = useState([]);
  const [listfilters, setListfilters] = useState([]);
  const [etiquetaSize, setEtiquetaSize] = useState(null);
  const [formatoHoja, setFormatoHoja] = useState(null);
  const [openBackdrop, setOpenBackdrop] = useState(false);

  const { formato_etiqueta } = useSelector(
    (state) => state.configGeneral.configGeneral,
  );
  const configGeneral = useSelector(
    (state) => state.configGeneral.configGeneral,
  );
  const { sucursal } = useGetSucursal();

  const [configuracionEtiqueta, setConfiguracionEtiqueta] = useState(null);

  const handleGetConfiguracionEtiquetas = async () => {
    try {
      const response = await getConfiguracionEtiquetasService();

      setConfiguracionEtiqueta(response);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetConfiguracionEtiquetas();
  }, []);

  const { dataArticulos } = useArticulosSucursal({
    listfilters,
    proveedorSelect: "0",
  });

  const { loading, articulos, getArticulosSucursal, filters } = dataArticulos;

  const {
    filter,
    busquedaArticulo,
    debouncedSearchTerm,
    setCheckedArt,
    setSoloStock,
    setSinStock,
    setTieneMeli,
    setFilter,
    setBusqueda,
  } = filters;

  const {
    handleChangeArt,
    handleChangeTodosMisArt,
    handleChangeSoloStock,
    handleChangeSinStock,
  } = useFiltroStock(setCheckedArt, setSoloStock, setSinStock, setTieneMeli);

  useEffect(() => {
    getArticulosSucursal();
  }, [debouncedSearchTerm]);

  const getListFilters = () => {
    getFilters().then((res) => {
      setListfilters(res);
      res.length > 0 &&
        setFilter(localStorage.getItem("filtroArticulos") || res[0].id);
    });
  };

  const handleChangeMoreFilters = async (value) => {
    switch (value) {
      case 1:
        handleChangeArt(true);
        break;
      case 2:
        handleChangeTodosMisArt(true);
        break;
      case 3:
        handleChangeSoloStock(true);
        break;
      case 4:
        handleChangeSinStock(true);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    getListFilters();
    let value = localStorage.getItem("filtroMostrar");
    if (value) handleChangeMoreFilters(parseInt(value));
  }, []);

  const handleAddToTable = (value) => {
    if (!value) {
      return null;
    }

    if (tableData.some((item) => item.codProveedor === value.codProveedor)) {
      warningNotification("El artículo ya está en la lista.");
      return;
    }

    setTableData((prevData) => [
      ...prevData,
      {
        ...value,
        cantidadEtiquetas: 1,
      },
    ]);
  };

  const handleGetPDF = async () => {
    if (tableData.length === 0) {
      warningNotification("Por favor, seleccione al menos un artículos.");
      return;
    }

    if (etiquetaSize === null) {
      warningNotification("Por favor, seleccione un tamaño de etiqueta.");
      return;
    }

    if (!formatoHoja) {
      warningNotification("Por favor, seleccione un formato de hoja.");
      return;
    }

    const formato = formato_etiqueta[etiquetaSize];

    getPDF(
      tableData,
      formato,
      formatoHoja,
      configGeneral,
      configuracionEtiqueta,
      sucursal,
      setOpenBackdrop,
    );
  };

  return (
    <Container
      maxWidth
      className="main-content-container px-4"
      style={{ marginBottom: "2rem" }}
    >
      <Grid container className="py-4">
        <Grid item xs={10}>
          <PageTitle
            sm={4}
            title="Impresión de etiquetas"
            className="text-sm-left"
            subtitle="Catálogo"
          />
        </Grid>
        <Grid
          item
          xs={2}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <GoBack history={history} />
        </Grid>
      </Grid>

      <Card>
        <Box p={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <AutocompleteArticulo
                busquedaArticulo={busquedaArticulo}
                setBusqueda={setBusqueda}
                articulos={articulos}
                loading={loading}
                clienteDescuento={false}
                onSelectArticulo={handleAddToTable}
              />
            </Grid>

            <Grid item xs={12} sm={3} className="mb-3">
              <FiltroBuscador
                listfilters={listfilters}
                filter={filter}
                setFilter={setFilter}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <FiltroStock filter={filter} setFilter={setFilter} />
            </Grid>
          </Grid>

          <Divider sx={{ my: 2 }} />

          <Options
            etiquetaSize={etiquetaSize}
            setEtiquetaSize={setEtiquetaSize}
            formatoHoja={formatoHoja}
            setFormatoHoja={setFormatoHoja}
            formato_etiqueta={formato_etiqueta}
            handleGetPDF={handleGetPDF}
            disabled={openBackdrop}
          />

          <Box sx={{ maxHeight: "100%", overflow: "auto" }}>
            <Table data={tableData} setTableData={setTableData} />
          </Box>
        </Box>
      </Card>
      {openBackdrop && (
        <Backdrop className={classes.backdrop} open={openBackdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <canvas id="barcode" style={{ display: "none" }} />
    </Container>
  );
}
