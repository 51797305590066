import {
  errorNotification,
  successNotification,
} from "../../../components/Notifications";
import request from "../../../requests/request";
import {
  getListClienteSucursal,
  getClientesDeudasSucursal,
  excelAgendaClientesURL,
  deleteClient,
} from "../../../requests/urls";

export const fetchClients = async (
  filters,
  debouncedSearchTerm,
  pagination,
  signal,
) => {
  const { orderBy, ctaCte, tipoCliente, tipoDeCliente, activos, transporte } =
    filters;

  try {
    const response = await request({
      method: "GET",
      url: getListClienteSucursal,
      params: {
        page: pagination.page,
        blank: 1,
        q: debouncedSearchTerm,
        meli: 0,
        activo: activos,
        ordenFiltro: orderBy === "sin_orden" ? null : orderBy,
        cuenta_corriente: ctaCte === "todos" ? null : ctaCte,
        tipo_cliente: tipoCliente === "todos" ? null : tipoCliente,
        tipo_de_cliente: tipoDeCliente === "todos" ? null : tipoDeCliente,
        transporte: transporte ? transporte.id : null,
      },
      signal,
    });

    if (response.status === 204) {
      return [];
    }

    return response.data.data;
  } catch (err) {
    throw err;
  }
};

export const deleteCliente = async (
  selectedClientData,
  toggleModalBaja,
  resetSelectedClientData,
) => {
  try {
    const response = await request({
      method: "DELETE",
      url: deleteClient(selectedClientData.idClienteDeSucursal),
      params: {
        activo: Number(!selectedClientData.activo),
      },
    });

    if (response.status === 200) {
      successNotification(
        `Cliente dado de ${
          selectedClientData.activo ? "baja" : "alta"
        } exitosamente.`,
      );
      toggleModalBaja();
      resetSelectedClientData();
    }
  } catch (_err) {
    errorNotification("Ocurrio un error.");
  }
};

export const downloadExcelAgendaClientes = async (
  debouncedSearchTerm,
  filters,
  setLoadingExcelAgenda,
) => {
  setLoadingExcelAgenda(true);
  const fileName = `Agenda de clientes.xlsx`;

  const { orderBy, ctaCte, tipoCliente, tipoDeCliente, activos, transporte } =
    filters;

  await request({
    method: "GET",
    url: excelAgendaClientesURL,
    params: {
      blank: 1,
      q: debouncedSearchTerm,
      meli: 0,
      activo: activos,
      ordenFiltro: orderBy,
      cuenta_corriente: ctaCte === "todos" ? null : ctaCte,
      tipo_cliente: tipoCliente === "todos" ? null : tipoCliente,
      tipo_de_cliente: tipoDeCliente === "todos" ? null : tipoDeCliente,
      transporte: transporte ? transporte.id : null,
    },
    headers: {
      "Content-Disposition": `attachment; filename=${fileName}`,
      "Content-Type":
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    },
    responseType: "arraybuffer", // important
  })
    .then((response) => {
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: "application/octet-stream" }),
      );
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName;
      link.target = "_blank";
      link.click();
      setLoadingExcelAgenda(false);
    })
    .catch((_err) => {
      errorNotification(
        "No se ha podido descargar el Excel, intente nuevamente.",
      );
      setLoadingExcelAgenda(false);
    });
};

export const downloadExcelDeuda = async (setLoadingExcelDeuda) => {
  setLoadingExcelDeuda(true);
  let params = { desde: "", hasta: "", is_historico: 1 };
  let fileName = `DeudasClientes.xlsx`;
  let url = getClientesDeudasSucursal;

  await request({
    method: "GET",
    url: url,
    params: params,
    headers: {
      "Content-Disposition": `attachment; filename=${fileName}`,
      "Content-Type":
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    },
    responseType: "arraybuffer", // important
  })
    .then((response) => {
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: "application/octet-stream" }),
      );
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName;
      link.target = "_blank";
      link.click();
      setLoadingExcelDeuda(false);
    })
    .catch((_err) => {
      errorNotification(
        "No se ha podido descargar el Excel, intente nuevamente.",
      );
      setLoadingExcelDeuda(false);
    });
};
