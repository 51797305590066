import create from "zustand";

export const useClientSearchStore = create((set) => ({
  search: "",
  searchMethod: null,
  selectedClientData: null,

  setSearch: (value) =>
    set({
      search: value,
      searchMethod: value ? "search" : null,
    }),

  setSearchMethod: (value) => set({ searchMethod: value }),

  setSelectedClientData: (clientData) => {
    set({ selectedClientData: clientData });
  },

  resetSelectedClientData: () => set({ selectedClientData: null }),
}));
