import React from "react";
import { Button, CircularProgress } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";

export default function BotonDescargar({
  click,
  styles,
  children,
  disabled = false,
  loading = false,
  fullWidth = false,
}) {
  return (
    <Button
      size="small"
      disableElevation
      onClick={click}
      variant="contained"
      disabled={disabled || loading}
      fullWidth={fullWidth}
      startIcon={
        loading ? (
          <CircularProgress size={18} color="inherit" />
        ) : (
          <GetAppIcon />
        )
      }
      style={{ fontWeight: "bold", outline: "none", ...styles }}
    >
      {children}
    </Button>
  );
}
