import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { styles } from "../../assets/css/styleA4";

export const Header = ({ title, subtitle }) => {
  const stylesPdf = StyleSheet.create({
    fontBoldTitle: {
      fontFamily: "Times-Bold",
      fontWeight: "bold",
      fontSize: 18,
    },
    fontBold: {
      fontFamily: "Times-Bold",
      fontWeight: "bold",
      fontSize: 14,
    },
  });

  return (
    <>
      <View style={styles.containerColumnCenterFull}>
        <Text
          style={[
            stylesPdf.fontBoldTitle,
            {
              textDecoration: "underline",
            },
          ]}
        >
          {title}
        </Text>
      </View>
      <View style={styles.containerRow}>
        <Text
          style={[
            stylesPdf.fontBold,
            { textAlign: "right", paddingTop: "5em" },
          ]}
        >
          {subtitle}
        </Text>
      </View>
    </>
  );
};
