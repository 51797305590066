import React from "react";
import { View, Text } from "@react-pdf/renderer";
import { styles } from "../../assets/css/styleTicket";

export default function SubHeaderNCTicket({ data, pagos, observacion }) {
  const { cliente, cliente_terceros: clienteTerceros } = data;
  const obtenerNombre = () => {
    if (clienteTerceros) return clienteTerceros.razon_social;
    if (cliente) return cliente.razonSocial;
    if (!cliente) return "Consumidor final";
  };

  const obtenerCUIT = () => {
    if (clienteTerceros) return clienteTerceros.cuit;
    if (cliente) return cliente.CUIT;
    if (!cliente) return "---";
  };

  /**
   * La factura a siempre tiene un cliente
   * en caso de ser a un cliente de terceros,
   * El cliente seria el "mecanico o referente"
   * @returns string
   */
  const mecanicoCliente = () => {
    if (clienteTerceros) {
      return cliente ? cliente.razonSocial : "---";
    } else {
      return "---";
    }
  };

  return (
    <View>
      <View style={styles.containerRow}>
        <View style={styles.containerColumn}>
          <Text style={styles.text}>{`Cliente: ${obtenerNombre()}`}</Text>
          <Text style={styles.text}>{`CUIT: ${obtenerCUIT()}`}</Text>
          {clienteTerceros ? (
            <Text style={styles.text}>{`Referente: ${mecanicoCliente()}`}</Text>
          ) : null}
          <Text style={styles.text}>{`Observación: ${
            observacion || "---"
          }`}</Text>
        </View>
      </View>
      <View>
        <Text style={{ marginLeft: 10, marginRight: 10 }}>
          --------------------------------
        </Text>
      </View>
    </View>
  );
}
