import React from "react";
import { View, Text } from "@react-pdf/renderer";
import HeaderA4CI from "../../DeVentas/Components/HeaderA4CI";
import { LineA4Color, LineA4Cut } from "../../Components/raya";
import { styles } from "../../assets/css/styleA4";
import HeaderTable from "../../Components/TableGeneric/HeaderTable";
import {
  columnsPresupuesto,
  columnsNCMontos,
} from "../../Components/TableReport/utils";
import BodyTable from "../../Components/TableGeneric/BodyTable";
import ResumenNCCIA4 from "./ResumenNCCIA4";
import BodyTableNCMontos from "./BodyTableNCMontos";

export default function BodyNCCIA4({ data, tipo, config, remito }) {
  // const description = (config, data, index) => {
  //   let descripcionProducto = `${
  //     !config.ocultar_codigo_pdf
  //       ? data.nota_credito.descripcion_ticket[index].codigo + " - "
  //       : ""
  //   } ${data.nota_credito.descripcion_ticket[index].descripcion}`;

  //   return descripcionProducto;
  // };

  const description = (config, data, index) => {
    const articulos = data.detalles_venta;
    const detalleNotaCredito =
      data.nota_credito.detalle[index].descripcion_original;

    if (articulos.length) {
      const articulo = articulos.find(
        (articulo) => articulo.descripcion_original === detalleNotaCredito,
      );

      if (articulo && articulo.descripcion_personalizada) {
        return articulo.descripcion_personalizada;
      }
    }

    return `${
      !config.ocultar_codigo_pdf
        ? data.nota_credito.descripcion_ticket[index].codigo + " - "
        : ""
    } ${data.nota_credito.descripcion_ticket[index].descripcion}`;
  };

  return (
    <>
      <HeaderA4CI
        fixed={tipo !== "ncA4Ahorro" ? true : false}
        logoEmpresa={data.logo}
        sucursal={data.empresa}
        venta={data.nota_credito}
        config={config}
        nro={data.nota_credito.idNotaCredito}
        titleNro={remito ? "REMITO" : "DEVOLUCIÓN N°"}
      />

      <LineA4Color />
      <View style={styles.containerRow}>
        <View style={(styles.containerColumn, { width: 350 })}>
          <Text style={styles.text2Title}>{`Sr.(es):`}</Text>
          <Text style={styles.text2}>
            {data.cliente ? data.cliente.razonSocial : " Consumidor Final"}
          </Text>
        </View>
      </View>

      <View style={styles.containerRow}>
        <View style={(styles.containerColumn, { width: 500 })}>
          <Text style={styles.text2Title}>Observación:</Text>
          <Text style={styles.text2}>
            {data.nota_credito.observacion || "---"}
          </Text>
        </View>
      </View>

      <HeaderTable
        fixed={tipo !== "ncA4Ahorro" ? true : false}
        columns={
          !data.nota_credito.por_montos
            ? columnsPresupuesto(config)
            : columnsNCMontos
        }
      />
      {!data.nota_credito.por_montos ? (
        data.nota_credito.detalle.map((x, index) => (
          <View key={index}>
            <BodyTable
              config={config}
              listaPrecio={x.listaPrecios || "---"}
              descripcion={description(config, data, index)}
              cant={parseFloat(x.quantity)}
              precio={Number(Number(x.unit_price).toFixed(2)).toLocaleString(
                "es-AR",
              )}
              subtotal={Number(
                (Number(x.unit_price) * x.quantity).toFixed(2),
              ).toLocaleString("es-AR")}
              remito={remito}
            />
          </View>
        ))
      ) : (
        <BodyTableNCMontos
          precio={Number(Number(data.nota_credito.monto).toFixed(2))}
          iva={null}
          subtotal={Number(Number(data.nota_credito.monto).toFixed(2))}
          isNC={false}
          remito={remito}
        />
      )}
      <ResumenNCCIA4 tipo={tipo} data={data} config={config} remito={remito} />
      {tipo === "ncA4Ahorro" && (
        <View>
          <LineA4Cut />
        </View>
      )}
    </>
  );
}
