import React from "react";
import { View, Text } from "@react-pdf/renderer";
import { styles } from "../../assets/css/styleTicket";
import { parseCurrency } from "../../../../../utils/parsers";

export default function ArticulosTicketF({
  data,
  factura,
  remito,
  fullData,
  config,
}) {
  const description = (fullData, index) => {
    const articulos = fullData.detalles_venta;
    const detalleNotaCredito =
      fullData.nota_credito.detalle[index].descripcion_original;

    if (articulos.length) {
      const articulo = articulos.find(
        (articulo) => articulo.descripcion_original === detalleNotaCredito,
      );

      if (articulo && articulo.descripcion_personalizada) {
        return articulo.descripcion_personalizada.substr(0, 30).trim() + "...";
      }
    }

    let descripcionProducto;
    if (config.ocultar_codigo_pdf) {
      descripcionProducto = `${fullData.nota_credito.descripcion_ticket[index].descripcion}`;
    } else {
      descripcionProducto = `${fullData.nota_credito.descripcion_ticket[index].codigo} ${fullData.nota_credito.descripcion_ticket[index].descripcion}`;
    }

    return descripcionProducto.substr(0, 30).trim() + "...";
  };

  return (
    data.length > 0 &&
    data.map((x, index) => (
      <View
        key={index}
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          paddingHorizontal: 10,
        }}
      >
        <View style={{ flexDirection: "column" }}>
          <Text style={styles.textDescriptionItem}>
            {description(fullData, index)}
            {/* {`${ x.articulo_sucursal && x.articulo_sucursal.idRepuestoProveedor
              ? x.articulo_sucursal.idRepuestoProveedor.descripcionProveedor
                  .substr(0, 30)
                  .trim() +
                (x.articulo_sucursal.idRepuestoProveedor.descripcionProveedor
                  .length > 30
                  ? "..."
                  : "")
              : x.detalle_venta && x.detalle_venta.descripcion
              ? x.detalle_venta.descripcion.substr(0, 30).trim() +
                (x.detalle_venta.descripcion.length > 30 ? "..." : "")
              : x.description.substr(0, 30).trim() +
                (x.description.length > 30 ? "..." : "")
          }`} */}
          </Text>
          {!remito ? (
            <Text style={styles.text}>
              {factura.idTipoFactura.nombre === "A" ||
              factura.idTipoFactura.nombre === "M"
                ? `${x.quantity.toFixed(4)} x ${parseCurrency(
                    Number(x.unit_price * (1 / 1.21)),
                  )}`
                : `${x.quantity.toFixed(4)} x ${parseCurrency(
                    factura.nroFactura ? x.unit_price : x.unit_price,
                  )}`}
            </Text>
          ) : (
            <Text style={styles.text}>---</Text>
          )}

          {!remito ? (
            (factura.idTipoFactura.nombre === "A" ||
              factura.idTipoFactura.nombre === "M") && (
              <Text style={styles.textDescription}>
                {`IVA:${"21%"} - ${parseCurrency(
                  Number(x.unit_price) - Number(x.unit_price * (1 / 1.21)),
                )}`}
              </Text>
            )
          ) : (
            <Text style={styles.textDescription}>---</Text>
          )}
        </View>

        <View style={styles.containerColumn}>
          {!remito ? (
            <Text style={styles.text}>
              {factura.idTipoFactura.nombre === "A" ||
              factura.idTipoFactura.nombre === "M"
                ? `${parseCurrency(
                    Number(x.unit_price * (1 / 1.21)) * x.quantity,
                  )}`
                : `${
                    factura.nroFactura
                      ? parseCurrency(Number(x.unit_price) * x.quantity)
                      : parseCurrency(Number(x.unit_price) * x.quantity)
                  }`}
            </Text>
          ) : (
            <Text style={styles.text}>---</Text>
          )}
        </View>
      </View>
    ))
  );
}
