import React from "react";
import TooltipWithoutIcon from "../../../../components/ait-reusable/TooltipMoreInfo/TooltipWithoutIcon";

export default function TooltipDescuento() {
  return (
    <TooltipWithoutIcon
      body={
        <h6 style={{ fontSize: 15, marginBottom: 0, marginTop: 0 }}>
          Cliente con descuento por plazo
        </h6>
      }
      placement={"right"}
    >
      <i
        className={"material-icons mr-1"}
        style={{ fontSize: "18px", color: "#F37335", cursor: "pointer" }}
      >
        access_time_filled
      </i>
    </TooltipWithoutIcon>
  );
}
