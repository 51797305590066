export const formDefaultValues = {
  CUIT: "",
  alias: "",
  correoElectronico: "",
  domicilio: "",
  idEstadoTributario: 1,
  numeroDocumento: "",
  razonSocial: "",
  telefonoContacto: "",
  is_precio_lista: false,
  margen_ganancia: "0.0000",
  descuento: "0.0000",
  recargo_lista: "0.0000",
  recargo_contado: "0.0000",
  porcentaje_iva: "21.0000",
  already_iva: true,
  sitioWeb: "",
  ingresosBrutos: "",
  calcular_retencion: false,
  deshabilitar_calcular_retencion: false,
  provincia: null,
  identificador: "",
  parametros: [],
};

export const formDefaultListaDePrecios = [
  {
    id: -1,
    already_iva: true,
    descuento: "0.0000",
    es_default: true,
    is_active: true,
    is_precio_lista: false,
    margen_ganancia: "0.0000",
    nombre: "",
    porcentaje_iva: "21.0000",
    recargo_contado: "0.0000",
    recargo_lista: "0.0000",
  },
  {
    id: -2,
    already_iva: true,
    descuento: "0.0000",
    es_default: true,
    is_active: true,
    is_precio_lista: true,
    margen_ganancia: "0.0000",
    nombre: "Principal",
    porcentaje_iva: "21.0000",
    recargo_contado: "0.0000",
    recargo_lista: "0.0000",
  },
];
