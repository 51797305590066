import React from "react";
import { styles } from "../../assets/css/styleA4";
import { View, Text } from "@react-pdf/renderer";
import { LineA4ColorMinMargin } from "../../Components/raya";
import { getClienteComprobante } from "../Components/utils";
import HeaderDataSucursal from "./HeaderDataSucursal";

export default function DataClienteA4FacturaRotulo({ factura, venta, data }) {
  const nombreCliente = () => {
    let cliente = getClienteComprobante(factura, venta, null);
    return cliente ? cliente.razonSocial : "Consumidor Final";
  };

  const domicilioCliente = () => {
    let cliente = getClienteComprobante(factura, venta, null);
    return cliente ? cliente.domicilio : "---";
  };

  const documentoCliente = () => {
    let cliente = getClienteComprobante(factura, venta, null);
    if (cliente) {
      return cliente.cuit || cliente.dni || "---";
    } else {
      return "---";
    }
  };

  const estadoTributarioCliente = () => {
    let cliente = getClienteComprobante(factura, venta, null);
    if (cliente) {
      return cliente.estadoTributario;
    } else {
      return "Consumidor Final";
    }
  };

  const getHeightStyle = () => {
    if (factura && factura.cliente_terceros) {
      return { minHeight: 265, maxHeight: 265 };
    } else {
      return { minHeight: 240, maxHeight: 240 };
    }
  };

  return (
    <View style={getHeightStyle()}>
      <HeaderDataSucursal data={data} />
      <View style={styles.containerRow}>
        <View style={(styles.containerColumn, { width: 300 })}>
          <View style={{ flexDirection: "row", paddingTop: 5 }}>
            <View style={(styles.containerColumn, { width: 50 })}>
              <Text style={styles.text2Title}>{`Sr.(es):`}</Text>

              <Text style={styles.text2Title}>Domicilio:</Text>
            </View>
            <View style={(styles.containerColumn, { width: 250 })}>
              <Text style={styles.text2}>{nombreCliente()}</Text>

              <Text style={styles.text2}>{domicilioCliente()}</Text>
            </View>
          </View>
        </View>
        <View style={(styles.containerColumn, { width: 200 })}>
          <View
            style={{
              flexDirection: "row",
              paddingTop: 5,
            }}
          >
            <View style={(styles.containerColumn, { width: 80 })}>
              <Text style={styles.text2Title}>CUIT/DNI:</Text>
              <Text style={styles.text2Title}>Condición de IVA:</Text>
            </View>
            <View style={(styles.containerColumn, { width: 120 })}>
              <Text style={styles.text2}>{documentoCliente()}</Text>
              <Text style={styles.text2}>{estadoTributarioCliente()}</Text>
            </View>
          </View>
        </View>
      </View>

      {factura && factura.cliente_terceros && (
        <View style={styles.containerRow}>
          <View style={(styles.containerColumn, { width: 300 })}>
            <View style={{ flexDirection: "row", paddingTop: 5 }}>
              <View style={(styles.containerColumn, { width: 50 })}>
                <Text style={styles.text2Title}>Referente</Text>

                <Text style={styles.text2Title}>Domicilio:</Text>
              </View>
              <View style={(styles.containerColumn, { width: 250 })}>
                <Text style={styles.text2}>
                  {factura.cliente_real
                    ? factura.cliente_real.nombre
                    : "Consumidor Final"}
                </Text>

                <Text style={styles.text2}>
                  {factura.cliente_real
                    ? factura.cliente_real.domicilio
                    : "---"}
                </Text>
              </View>
            </View>
          </View>
          <View style={(styles.containerColumn, { width: 200 })}>
            <View
              style={{
                flexDirection: "row",
                paddingTop: 5,
              }}
            >
              <View style={(styles.containerColumn, { width: 80 })}>
                <Text style={styles.text2Title}>CUIT/DNI:</Text>
                <Text style={styles.text2Title}>Condición de IVA:</Text>
              </View>
              <View style={(styles.containerColumn, { width: 120 })}>
                <Text style={styles.text2}>
                  {factura.cliente_real
                    ? factura.cliente_real.cuit_o_dni
                    : "---"}
                </Text>
                <Text style={styles.text2}>
                  {factura.cliente_real
                    ? factura.cliente_real.estado_tributario
                    : "Consumidor Final"}
                </Text>
              </View>
            </View>
          </View>
        </View>
      )}

      <LineA4ColorMinMargin />
      <View style={styles.containerRow}>
        <View style={{ flexDirection: "column", width: 555 }}>
          <View style={{ flexDirection: "row" }}>
            <View style={(styles.containerColumn, { width: 185 })}>
              <Text style={styles.text2Title}>Moneda:</Text>
              <Text style={styles.text2}>{`${"Pesos Argentinos"}`}</Text>
            </View>

            <View style={(styles.containerColumn, { width: 185 })}>
              <Text style={styles.text2Title}>Prov. Destino:</Text>
              <Text style={styles.text2}>{`${
                factura
                  ? factura.idClienteDeSucursal
                    ? factura.idClienteDeSucursal.idCliente.provincia &&
                      factura.idClienteDeSucursal.idCliente.provincia.nombre
                    : ""
                  : ""
              }`}</Text>
            </View>

            <View style={(styles.containerColumn, { width: 185 })}>
              <Text style={styles.text2Title}>Tipo de venta:</Text>
              <Text style={styles.text2}>
                {venta.tipo_venta ? venta.tipo_venta.descripcion : "---"}
              </Text>
            </View>
          </View>

          {venta.responsable_venta && (
            <View style={{ flexDirection: "row" }}>
              <View style={(styles.containerColumn, { width: 185 })}>
                <Text style={styles.text2Title}>Respons.:</Text>
                <Text style={styles.text2}>
                  {venta.responsable_venta.nombre}
                </Text>
              </View>

              <View style={(styles.containerColumn, { width: 185 })}>
                <Text style={styles.text2Title}>Observación:</Text>
                <Text style={styles.text2}>
                  {venta.descripcion || "Ninguna"}
                </Text>
              </View>

              <View style={(styles.containerColumn, { width: 185 })}>
                <Text style={styles.text2Title}>Transporte:</Text>
                <Text style={styles.text2}>
                  {venta.transporte_venta
                    ? venta.transporte_venta.nombre
                    : "---"}
                </Text>
              </View>
            </View>
          )}
        </View>
      </View>
    </View>
  );
}
