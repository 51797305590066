import React from "react";
import { Text, View } from "@react-pdf/renderer";
import { styles } from "../../assets/css/styleA4";
import { LineA4Table } from "../raya";
import { parseDate } from "../../../../../utils/parsers";

export default function BodyTable({
  fecha,
  tipo,
  numeroComprobante,
  monto_l,
  monto_r,
}) {
  return (
    <View style={{ paddingTop: 10 }}>
      <View style={styles.containerRowTable}>
        <View style={(styles.containerColumn, { width: 90 })}>
          <Text style={styles.textCenterTable}>
            {parseDate(fecha, "DD/MM/YY", "---")}
          </Text>
        </View>
        <View style={(styles.containerColumn, { width: 30 })}>
          <Text style={styles.textCenterTable}>{tipo}</Text>
        </View>
        <View style={(styles.containerColumn, { width: 150 })}>
          <Text style={styles.textCenterTable}>{numeroComprobante}</Text>
        </View>
        <View style={(styles.containerColumn, { width: 80 })}>
          <Text style={styles.textCenterTable}>{monto_l}</Text>
        </View>
        <View style={(styles.containerColumn, { width: 80 })}>
          <Text style={styles.textCenterTable}>{monto_r}</Text>
        </View>
      </View>
      <LineA4Table />
    </View>
  );
}
