/* prettier-ignore */

export const DOCUMENT_TITLE = "Remito";

// Header
export const RETENCIONES_TITULO =
  "SI.CO.RE - Sistema de Control de Retenciones";
export const CERTIFICADO_NRO = "Certificado Nº: ";
export const FECHA_RETENCION = "Fecha: ";

// Datos del Agente de Retención y del Sujeto de Retenido
export const A_DATOS_AGENTE_RETENCION = "A.- Datos del Agente de Retención";
export const B_DATOS_SUJETO_RETENIDO = "B.- Datos del Sujeto de Retenido";
export const APELLIDO_NOMBRE_DENOMICACION_TITULO =
  "Apellido y Nombre o Denominación: ";
export const CUIT_TITULO = "C.U.I.T. Nº: ";
export const DOMICILIO_TITULO = "Domicilio: ";

// Datos de la Retención Practicada
export const C_DATOS_RETENCION_PRACTICADA =
  "C.- Datos de la Retención Practicada";
export const C_IMPUESTO_TITULO = "Impuesto: ";
export const C_IMPUESTO_GANACIAS = "Impto. a las Ganancias";
export const C_REGIMEN_TITULO = "Regimen: ";
export const C_REGIMEN_DESCRIPCION =
  "Enajenación de bienes muebles y bienes de cambio.";
export const C_COMPROBANTE_ORIGINA_RETENCION =
  "Comprobante que origina la Retención: ";
export const C_MONTO_COMPROBANTE_ORGINA_RETENCION =
  "Monto del Comprobante que origina la Retención: ";
export const C_MONTO_RETENCION_TITULO = "Monto de la Retención: ";
export const C_IMPOSIBILIDAD_RETENCION_TITULO =
  "Imposibilidad de la Retención  ";
export const C_IMPOSIBILIDAD_RETENCION_DESCRIPCION = "NO";

// Footer
export const FIRMA_AGENTE_RETENCION = "Firma del Agente de Retención: ";
export const ACLARACION = "Aclaración: ";
export const CARGO = "Cargo: ";

export const DECLARACION = `Declaro que los datos consignados en este Formulario son correctos y son completos y que he confeccionado la presente utilizando la aplicación (software) entregada y aprobada por la AFIP sin omitir ni falsear dato alguno que deba contener, siendo fiel expresión de la verdad.`;
