import React from "react";
import { Text } from "@react-pdf/renderer";

export function LineA4() {
  return <Text>_______________________________________________________</Text>;
}

export function LineA4Table() {
  return (
    <Text style={{ color: "#CCCCBD" }}>
      _______________________________________________________
    </Text>
  );
}

export function LineA4Cut() {
  return (
    <Text style={{ color: "#CCCCBD", marginBottom: 5, paddingTop: 10 }}>
      ---------------------------------------------------------------------------------------------
    </Text>
  );
}

export function LineA4Color() {
  return (
    <Text style={{ color: "#1B71BA", marginTop: -15 }}>
      _______________________________________________________
    </Text>
  );
}

export function LineA4TableWhite() {
  return (
    <Text style={{ color: "white" }}>
      _______________________________________________________
    </Text>
  );
}

export function LineA4ColorMinMargin() {
  return (
    <Text style={{ color: "#1B71BA", marginTop: -15 }}>
      _______________________________________________________
    </Text>
  );
}
