import React from "react";
import { Text, View } from "@react-pdf/renderer";
import { styles } from "../../assets/css/styleA4";
import { LineA4Table } from "../../Components/raya";
import { formatCurrency } from "../../../../../utils/parsers";

export default function BodyTableNCMontos({
  precio,
  iva,
  subtotal,
  isNC,
  remito,
}) {
  return (
    <View style={{ paddingTop: 10 }}>
      <View style={styles.containerRowTable}>
        <View style={(styles.containerColumn, { width: iva ? 270 : 310 })}>
          <Text style={styles.textLeftTable}>
            {`${isNC ? "Nota de crédito" : "Devolución"} por monto`}
          </Text>
        </View>
        <View style={(styles.containerColumn, { width: iva ? 80 : 100 })}>
          <Text style={styles.textCenterTable}>
            {!remito ? formatCurrency(precio) : "---"}
          </Text>
        </View>
        {iva && (
          <View style={(styles.containerColumn, { width: 80 })}>
            <Text style={styles.textCenterTable}>
              {!remito ? formatCurrency(iva) : "---"}
            </Text>
          </View>
        )}
        <View style={(styles.containerColumn2, { width: iva ? 80 : 100 })}>
          <Text style={styles.textRightTable}>
            {!remito ? formatCurrency(subtotal) : "---"}
          </Text>
        </View>
      </View>
      <LineA4Table />
    </View>
  );
}
