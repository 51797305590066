import React from "react";
import { styles } from "../../assets/css/styleA4";
import { View, Text, Image } from "@react-pdf/renderer";
import { LineA4ColorMinMargin } from "../../Components/raya";
import location from "./Iconos/location.png";
import phone from "./Iconos/phone.png";
import email from "./Iconos/email.png";

export default function HeaderDataSucursal({ data }) {
  const { sucursal } = data;

  const logoEmpresa = sucursal.boxer ? sucursal.boxer.url_imagen : null;

  const { razonSocial, domicilio, telefonoContacto, correoElectronico } =
    sucursal;

  return (
    <>
      <View style={styles.containerRow}>
        <View
          style={(styles.containerColumn, { width: 200, alignItems: "center" })}
        >
          <Image
            cache={false}
            style={{ width: 152, height: 115, paddingBottom: 3 }}
            allowDangerousPaths={true}
            src={logoEmpresa && `data:image/png/jpg;base64,${logoEmpresa}`}
          />
        </View>
        <View style={(styles.containerColumn, { width: 300 })}>
          <View style={{ flexDirection: "row", paddingTop: 5 }}>
            <View style={(styles.containerColumn, { width: 300 })}>
              <Text style={styles.textNegocioRotulo}>
                {razonSocial.toUpperCase()}
              </Text>
            </View>
          </View>
          <View style={{ flexDirection: "row", paddingTop: 5 }}>
            <View style={(styles.containerColumn, { width: 300 })}>
              <Text style={{ color: "#1B71BA", marginTop: -15 }}>
                _______________________________
              </Text>
            </View>
          </View>

          <View style={{ flexDirection: "row", paddingTop: 5 }}>
            <View style={(styles.containerColumn, { width: 300 })}>
              {domicilio && (
                <View style={{ flexDirection: "row", paddingTop: 8 }}>
                  <View style={(styles.containerColumn, { width: 25 })}>
                    <Image
                      cache={false}
                      style={{ width: 12, height: 12 }}
                      allowDangerousPaths={true}
                      src={location}
                    />
                  </View>
                  <View style={(styles.containerColumn, { width: 285 })}>
                    <Text style={styles.text}>{domicilio}</Text>
                  </View>
                </View>
              )}
              {telefonoContacto && (
                <View style={{ flexDirection: "row", paddingTop: 8 }}>
                  <View style={(styles.containerColumn, { width: 25 })}>
                    <Image
                      cache={false}
                      style={{ width: 12, height: 12 }}
                      allowDangerousPaths={true}
                      src={phone}
                    />
                  </View>
                  <View style={(styles.containerColumn, { width: 285 })}>
                    <Text style={styles.text}>{telefonoContacto}</Text>
                  </View>
                </View>
              )}
              {correoElectronico && (
                <View style={{ flexDirection: "row", paddingTop: 8 }}>
                  <View style={(styles.containerColumn, { width: 25 })}>
                    <Image
                      cache={false}
                      style={{ width: 12, height: 12 }}
                      allowDangerousPaths={true}
                      src={email}
                    />
                  </View>
                  <View style={(styles.containerColumn, { width: 285 })}>
                    <Text style={styles.text}>{correoElectronico}</Text>
                  </View>
                </View>
              )}
            </View>
          </View>
        </View>
      </View>
      <LineA4ColorMinMargin />
    </>
  );
}
