import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import React, { useEffect } from "react";

const FiltroBuscador = ({ listfilters, filter, setFilter, size, disabled }) => {
  const handleChange = (e) => {
    const value = e.target.value;
    localStorage.setItem("filtroArticulos", value);
    setFilter(value);
  };

  useEffect(() => {
    const enabledFilters = listfilters.filter((item) => item.is_enabled);
    const hasEnabledFilter = enabledFilters
      .map((item) => item.id)
      .includes(filter);
    if (listfilters.length > 0 && !hasEnabledFilter) {
      setFilter(enabledFilters[0].id);
      localStorage.setItem("filtroArticulos", enabledFilters[0].id);
    }
  }, [listfilters]);

  return (
    <TextField
      id="select-filtro"
      label="Filtrar por"
      variant="outlined"
      fullWidth
      size={size || "small"}
      select
      value={filter}
      onChange={(e) => handleChange(e)}
      disabled={disabled || false}
    >
      {listfilters.map(
        (item, index) =>
          item.is_enabled && (
            <MenuItem value={item.id} key={index}>
              {item.name}
            </MenuItem>
          ),
      )}
    </TextField>
  );
};

export default FiltroBuscador;
