import React from "react";
import { View, Text } from "@react-pdf/renderer";
import { styles } from "../../assets/css/styleTicket";
import { parseDate } from "../../../../../utils/parsers";

export default function DataNCTicket({ data, remito, allData }) {
  return (
    <View>
      <View style={styles.containerRow}>
        <View style={(styles.containerColumn, { width: 100 })}>
          <Text style={styles.textbold}>
            {!remito
              ? `${
                  data.datos_afip.receipt_type
                    ? "Nota de crédito " +
                      data.datos_afip.receipt_type.description
                    : "| Validacion pendiente"
                }`
              : "Remito"}
          </Text>
        </View>

        <View style={(styles.containerColumn2, { width: 100 })}>
          <Text
            style={{
              paddingBottom: 3,
              fontSize: 8,
              textAlign: "right",
              paddingRight: 11,
            }}
          >
            {data.datos_afip.validation &&
            data.datos_afip.validation.cae &&
            data.nroNotaCredito
              ? data.nroNotaCredito
              : "PENDIENTE DE CAE"}
          </Text>
          {data.datos_afip.fecha_hora_cae &&
            data.datos_afip.fecha_hora_cae.processed_date && (
              <Text style={styles.text}>{`Fecha: ${parseDate(
                data.datos_afip.fecha_hora_cae.processed_date,
                "DD/MM/YYYY HH:mm",
              )}`}</Text>
            )}
        </View>
      </View>
      {!remito && (
        <View style={styles.containerRow}>
          <View style={styles.containerColumn}>
            <Text style={styles.boldTextRight}>
              {"Asociada a: " +
                allData.factura.nroFactura +
                " - " +
                parseDate(allData.factura.fechaHoraFactura)}
            </Text>
          </View>
        </View>
      )}
      <View>
        <Text style={{ marginLeft: 10, marginRight: 10 }}>
          --------------------------------
        </Text>
      </View>
    </View>
  );
}
