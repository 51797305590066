import React from "react";
import { Document, Page, Text, View } from "@react-pdf/renderer";
import { LineA4Table } from "../Components/raya";
import { styles } from "../assets/css/styleA4";
import FirmaBoxer from "../Components/firmaBoxer";
import HeaderResumen from "../DeReporte/Components/HeaderResumen";
import HeaderDataCliente from "../DeReporte/Components/HeaderDataCliente";
import { managementRowsByType } from "./Components/managementRows";
import { parseCurrency, parseDate } from "../../../../utils/parsers";

// const marginForLines = (x) => {
//   if (!x.detalles) {
//     return -5;
//   } else if (x.detalles && !x.observacion) {
//     return -5;
//   } else {
//     return 5;
//   }
// };

export default function CuentaCorrienteCliente({ data }) {
  const columnsCtacte = [
    {
      width: 50,
      text: "FECHA",
    },
    {
      width: 80,
      text: "MOVIMIENTO",
    },
    {
      width: 120,
      text: "OBSERVACIONES",
    },
    {
      width: 80,
      text: "DEBE",
    },
    {
      width: 80,
      text: "HABER",
    },
    {
      width: 80,
      text: "SALDO ACTUAL",
    },
  ];

  const montoCliente =
    Number(data.cliente.monto_billetera) + Number(data.cliente.deuda);

  const getTitle = () => {
    let periodo = data.periodo
      ? ` - Desde ${parseDate(data.periodo.fromDate, "DD-MM-YY")}
        hasta ${parseDate(data.periodo.toDate, "DD-MM-YY")}`
      : "";
    return data.cliente.razonSocial + periodo;
  };

  return (
    <Document title={getTitle()}>
      <Page style={styles.page} size="A4">
        <View>
          <HeaderDataCliente
            razon_social={data.cliente.razonSocial}
            cuit={data.cliente ? data.cliente.cuit : "---"}
          />
          <HeaderResumen
            title={"MOVIMIENTOS"}
            subtitle={"CUENTA CORRIENTE CLIENTE"}
            tipoValue1={montoCliente >= 0 ? "A favor" : "Deuda"}
            value1={Math.abs(montoCliente)}
            logoempresa={data.sucursal.boxer.url_imagen}
            sucursal={data.sucursal.razonSocial}
          />
          <View style={{ marginBottom: 20 }} fixed></View>
          <View fixed>
            <View style={styles.rowHeaderTable2}>
              {columnsCtacte.map((column, index) => (
                <View
                  key={index}
                  style={
                    (styles.containerColumn,
                    { width: column.width, textAlign: column.textAlign })
                  }
                >
                  <Text style={styles.text3}>{column.text}</Text>
                </View>
              ))}
            </View>
          </View>
          <View>
            <View style={styles.containerRowBodyTable}>
              <View style={(styles.containerColumn, { width: 50 })}>
                <Text style={styles.text4}>---</Text>
              </View>
              <View style={(styles.containerColumn, { width: 80 })}>
                <Text style={styles.text4}>Saldo Inicial</Text>
              </View>
              <View style={(styles.containerColumn, { width: 120 })}>
                <Text style={styles.text4}>---</Text>
              </View>
              <View style={(styles.containerColumn, { width: 80 })} />
              <View style={(styles.containerColumn, { width: 80 })} />
              <View style={(styles.containerColumn2, { width: 80 })}>
                <Text style={styles.text4}>
                  {parseCurrency(data.saldo_inicial)}
                </Text>
              </View>
            </View>
            <View style={{ marginTop: -5 }}>
              <LineA4Table />
            </View>
          </View>
          {managementRowsByType(data)}
        </View>
        <View
          style={{
            alignItems: "flex-end",
            position: "absolute",
            fontSize: 10,
            bottom: 50,
            left: 0,
            right: 0,
            width: "100%",
          }}
          fixed
        >
          <FirmaBoxer />
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
    // Made by Crisuwu :D
  );
}
